import { createReducer, createActions } from "reduxsauce";
import Immutable from "seamless-immutable";
const { Types, Creators } = createActions({
  getColetores: ["id", "page", "filter"],
  setColetores: ["dados"],
  getTypes: ["showLoader"],
  setTypes: ["types"],
  createColetor: ["data"],
  getColetor: ["id", "coletor"],
  setColetor: ["data"],
  updateColetor: ["data"],
  deleteColetor: ["id"],
  deleteSuccess: ["dados"],
  hideLoader: null,
  showModal: null,
  hideModal: null
});
export const ColetoresTypes = Types;
export default Creators;
export const INITIAL_STATE = Immutable({
  data: [],
  loaded: true,
  coletoresInfo: {},
  tipos: [],
  currentColetor: {},
  currentLoaded: true,
  modal: false
});

/*Carrega o loader*/
export const showLoader = (state, { id, page, filter }) => {
  return state.merge({ ...state, loaded: false });
};

export const currentLoader = (state, { id, idCOletor }) => {
  return state.merge({ ...state, currentLoaded: false, currentColetor: {} });
};

export const setColetores = (state, { dados }) => {
  console.log(dados);
  const { data, ...coletoresInfo } = dados;
  return state.merge({
    ...state,
    data: data,
    coletoresInfo: coletoresInfo,
    loaded: true
  });
};
export const setTypes = (state, { types }) => {
  return state.merge({
    ...state,
    tipos: types,
    loaded: true
  });
};
export const setColetor = (state, { data }) => {
  return state.merge({
    ...state,
    currentColetor: data,
    currentLoaded: true
  });
};
export const deleteSuccess = (state, { dados }) => {
  const id = dados.IdColetor;
  return state.merge({
    ...state,
    data: state.data.filter(coletor => {
      return coletor.IdColetor != id;
    }),
    loaded: true,
    modal: false
  });
};
/* Esconde o loader */
export const hideLoader = (state, { page, filter }) => {
  return state.merge({ ...state, loaded: true });
};
export const showModal = (state, { data }) => {
  return state.merge({
    ...state,
    modal: true
  });
};
export const hideModal = (state, { data }) => {
  return state.merge({
    ...state,
    modal: false
  });
};
export const reducer = createReducer(INITIAL_STATE, {
  [Types.GET_COLETORES]: showLoader,
  [Types.SET_COLETORES]: setColetores,
  [Types.GET_TYPES]: showLoader,
  [Types.SET_TYPES]: setTypes,
  [Types.CREATE_COLETOR]: showLoader,
  [Types.GET_COLETOR]: currentLoader,
  [Types.SET_COLETOR]: setColetor,
  [Types.UPDATE_COLETOR]: showLoader,
  [Types.DELETE_COLETOR]: showLoader,
  [Types.DELETE_SUCCESS]: deleteSuccess,
  [Types.HIDE_LOADER]: hideLoader,
  [Types.SHOW_MODAL]: showModal,
  [Types.HIDE_MODAL]: hideModal
});
