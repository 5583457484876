import React from "react";
import { Container } from "./styles";

const Loader = () => (
  <Container>
    <i className="fa fa-circle-o-notch fa-spin fa-3x fa-fw" />
    <p>Atualizando...</p>
  </Container>
);
export default Loader;
