import { call, put } from "redux-saga/effects";
import { push } from "connected-react-router";
import { actions as toastrAction } from "react-redux-toastr";
import api from "../../services/api";
import ColetoresActions from "../ducks/coletores";
import ErrorsActions from "../ducks/errors";

export function* getColetores({ id, page, filter }) {
  try {
    const paginate = page === undefined ? 1 : page;
    const filtro = filter === undefined ? "" : filter;
    const response = yield call(
      api.get,
      `coletores/${id}?page=${paginate}&filter=${filtro}`
    );
    yield put(ColetoresActions.setColetores(response.data));
  } catch (err) {
    yield put(
      toastrAction.add({
        type: "error",
        title: "Ops...",
        message: "Não foi possível retornar os coletores, tente novamente",
        closeOnToastrClick: true,
        position: "top-center",
        options: {
          showCloseButton: true,
          closeOnToastrClick: true,
          progressBar: true,
          transitionOut: "fadeOut"
        }
      })
    );
  }
}

export function* getTypes() {
  try {
    const response = yield call(api.get, "coletores/tipos");
    yield put(ColetoresActions.setTypes(response.data));
  } catch (err) {
    console.log(err);
    yield put(
      toastrAction.add({
        type: "error",
        title: "Ops...",
        message: "Não foi possível carregar os tipos, tente novamente",
        closeOnToastrClick: true,
        position: "top-center",
        options: {
          showCloseButton: true,
          closeOnToastrClick: true,
          progressBar: true,
          transitionOut: "fadeOut"
        }
      })
    );
    yield put(push("/projetos"));
  }
}

export function* createColetor({ data }) {
  try {
    if (!data.IdCliente) {
      throw new Error("Cliente inválido");
    }
    yield call(api.post, "coletores", data);
    yield put(push(`/projeto/${data.IdCliente}/coletores`));
  } catch (err) {
    if (err.response.data.message !== undefined) {
      yield put(ErrorsActions.setError(err.response.data.message));
    }
    yield put(
      toastrAction.add({
        type: "error",
        title: "Ocorreu um erro",
        message: "Verifique os dados inseridos e tente novamente",
        closeOnToastrClick: true,
        position: "top-center",
        options: {
          showCloseButton: true,
          closeOnToastrClick: true,
          progressBar: true,
          transitionOut: "fadeOut"
        }
      })
    );
  }
}

export function* getColetor({ id, coletor }) {
  try {
    const response = yield call(api.get, `coletores/${id}/${coletor}`);
    if (!response.data) {
      throw new Error("Nenhum dado encontrado, tente novamente");
    }
    yield put(ColetoresActions.setColetor(response.data));
  } catch (err) {
    yield put(
      toastrAction.add({
        type: "error",
        title: "Ops...",
        message: "Não foi possível carregar os coletores, tente novamente",
        closeOnToastrClick: true,
        position: "top-center",
        options: {
          showCloseButton: true,
          closeOnToastrClick: true,
          progressBar: true,
          transitionOut: "fadeOut"
        }
      })
    );
    yield put(push("/projetos"));
  }
}

export function* updateColetor({ data }) {
  try {
    yield call(api.put, `coletores/${data.IdColetor}/${data.IdCliente}`, data);
    yield put(push(`/projeto/${data.IdCliente}/coletores`));
  } catch (err) {
    if (err.response.data.message !== undefined) {
      yield put(ErrorsActions.setError(err.response.data.message));
      yield put(ColetoresActions.hideLoader());
    }
    yield put(
      toastrAction.add({
        type: "error",
        title: "Ops...",
        message: "Não foi possível editar o coletor, tente novamente",
        closeOnToastrClick: true,
        position: "top-center",
        options: {
          showCloseButton: true,
          closeOnToastrClick: true,
          progressBar: true,
          transitionOut: "fadeOut"
        }
      })
    );
  }
}
export function* deleteColetor({ id }) {
  try {
    const response = yield call(api.delete, `coletores/${id}`);
    yield put(ColetoresActions.deleteSuccess(response.data.coletor));
    yield put(
      toastrAction.add({
        type: "success",
        title: "Sucesso!",
        message: "Coletor alterado com sucesso!",
        closeOnToastrClick: true,
        position: "top-center",
        options: {
          showCloseButton: true,
          closeOnToastrClick: true,
          progressBar: true,
          transitionOut: "fadeOut"
        }
      })
    );
  } catch (err) {
    yield put(
      toastrAction.add({
        type: "error",
        title: "Ops...",
        message: "Não foi possível desativar o coletor, tente novamente",
        closeOnToastrClick: true,
        position: "top-center",
        options: {
          showCloseButton: true,
          closeOnToastrClick: true,
          progressBar: true,
          transitionOut: "fadeOut"
        }
      })
    );
    yield put(push("/projetos"));
  }
}
