import api from "../../services/api";
import axios from "axios";
import store from "../../store";
import { call, put, select } from "redux-saga/effects";
import { push } from "connected-react-router";
import { actions as toastrAction } from "react-redux-toastr";
import AuthActions from "../ducks/auth";

export function* signIn({ email, password }) {
  try {
    const response = yield call(api.post, "sessions", { email, password });
    localStorage.setItem("@QED:token", response.data.token.token);
    localStorage.setItem("@QED:email", response.data.email);
    localStorage.setItem("@QED:userName", response.data.userName);
    localStorage.setItem("@QED:userId", response.data.userId);
    yield put(AuthActions.signInSuccess(response.data));
    yield call(getPermissions);
    yield put(AuthActions.hideLoader());  
    yield put(push("/"));
  } catch (err) {
    yield put(AuthActions.hideLoader());
    yield put(
      toastrAction.add({
        type: "error",
        title: "Falha no login",
        message: "Verifique seu e-mail/senha!",
        closeOnToastrClick: true,
        position: "top-center",
        options: {
          timeOut: 4000,
          showCloseButton: true,
          closeOnToastrClick: true,
          progressBar: true,
          transitionOut: "fadeOut"
        }
      })
    );
  }
}

export function* signUp({ name, email, password }) {
  debugger
  try {
    const response = yield call(api.post, "users", { name, email, password });
    localStorage.setItem("@QED:token", response.data.token);
    yield put(AuthActions.signInSuccess(response.data.token));
    yield put(push("/"));
  } catch (err) {
    //console.log(err);
    yield put(
      toastrAction.add({
        type: "error",
        title: "Falha no cadastro",
        message: "Não foi possível realizar o cadastro, tente novamente"
      })
    );
  }
}

export function* forgot({ email }) {
  try {
    //const url = "https://qedtelemetria.tk/reset_password";
    //const url = "http://localhost/reset_password";

    const resp = yield call(api.post, "passwords", {
      email,
      //redirect_url: url
      redirect_url: process.env.REACT_APP_RESET_PASS_URL
    });

    yield put(AuthActions.hideLoader());

    yield put(
      toastrAction.add({
        type: "success",
        title: "Sucesso!",
        message: "Verifique sua caixa de email!",
        closeOnToastrClick: true,
        position: "top-center",
        options: {
          showCloseButton: true,
          closeOnToastrClick: true,
	  preventDuplicates: true,
          progressBar: true,
          transitionOut: "fadeOut"
        }
      })
    );
  } catch (err) {
    yield put(AuthActions.hideLoader());
    yield put(
      toastrAction.add({
        type: "error",
        title: "Falha ao resetar a senha",
        message: "Verifique seu e-mail",
        closeOnToastrClick: true,
        position: "top-center",
        options: {
          showCloseButton: true,
          closeOnToastrClick: true,
	  preventDuplicates: true,
          progressBar: true,
          transitionOut: "fadeOut"
        }
      })
    );
  }
}

export function* changePassword({ token, password }) {
  if (!token) {
    yield put(
      toastrAction.add({
        type: "error",
        title: "Token inválido,",
        message: "Solicite um novo token para alterar sua senha",
        closeOnToastrClick: true,
        position: "top-center",
        options: {
          showCloseButton: true,
          closeOnToastrClick: true,
          progressBar: true,
          transitionOut: "fadeOut"
        }
      })
    );
  } else {
    try {
      yield call(api.put, "passwords", {
        password,
        token
      });

      yield put(
        toastrAction.add({
          type: "success",
          title: "Sucesso!",
          message: "Dados alterados com sucesso!",
          closeOnToastrClick: true,
          position: "top-center",
          options: {
            showCloseButton: true,
            closeOnToastrClick: true,
            progressBar: true,
            transitionOut: "fadeOut"
          }
        })
      );

      yield put(push("/"));
    } catch (err) {
      yield put(
        toastrAction.add({
          type: "error",
          title: "Ops...",
          message: "Não foi possível alterar a senha, tente novamente",
          closeOnToastrClick: true,
          position: "top-center",
          options: {
            showCloseButton: true,
            closeOnToastrClick: true,
            progressBar: true,
            transitionOut: "fadeOut"
          }
        })
      );
    }
  }
}

export function* signOut() {
  localStorage.removeItem("@QED:token");
  localStorage.removeItem("@QED:email");
  localStorage.removeItem("@QED:UserName");
  localStorage.removeItem("@QED:UserId");

  yield put(AuthActions.setSignedOut());

  yield put(push("/"));
}

export function* getPermissions() {
  const auth = yield select(state => state.auth);

  //const auth = select(state => state.auth);
  //const auth = store.getState().auth;
  
  console.log("GET PERMISSION 1", auth);

  if (!auth.signedIn || typeof auth.token === 'undefined') {
    return auth;
  }

  const apiPermission = axios.create({
    //baseURL: "https://api.qedtelemetria.tk"
    //baseURL: "https://qedtelemetria.com.br/pta3333"
    //baseURL: "http://localhost:3333/"

    baseURL: process.env.REACT_APP_API_URL
  });

  apiPermission.interceptors.request.use(config => {
    const { token } = store.getState().auth;

    if ( token )
    {
	    config.headers.Authorization = `Bearer ${token}`;
    }

    return config;
/*
    const headers = { ...config.headers };

    if (token) {
      headers.Authorization = `Bearer ${token}`;
    }
    return { ...config, headers };
*/

  });

  console.log("GET PERMISSION 2");
  try {
    const response = yield call(apiPermission.get, "permissions");

    const { userName, roles, permissions } = response.data;

    yield put(AuthActions.getPermissionsSuccess(userName, roles, permissions));
  } catch (err) {
    console.log('GET PERMISSION 2 err ', err);

    localStorage.removeItem("@QED:token");
    localStorage.removeItem("@QED:email");
    localStorage.removeItem("@QED:UserName");
    localStorage.removeItem("@QED:UserId");
  
    yield put(AuthActions.setSignedOut());
  
    yield put(push("/"));
  }
}
