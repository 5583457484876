import React, { Component } from "react";
import { HdrContainer, Logout } from "./styles";
import logo from "../../assets/images/logo.png";
/* REDUX */
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import MenuActions from "../../store/ducks/menu";
import AuthActions from "../../store/ducks/auth";
import { Link } from "react-router-dom";
import { NavLink } from "react-router-dom";

import Is from "../../components/Is";

/*
class HContainer extends Component {
  state = {};

  render() {

  }
}
*/

class Header extends Component {
  state = {};

  render() {
    const { menuVisible } = this.props.menu;
    const { signOut } = this.props;

    return (
     <HdrContainer hidden={(menuVisible && true) || false}>
        <aside className={(!menuVisible && "show") || ""}>
          <header>
            <img src={logo} alt="QED SISTEMAS ELETRÔNICOS LTDA" />
          </header>
          <section>
            <nav>
              <ul>
                <li>
                  <NavLink exact to="/">
                    <i className="fa fa-home" />
                    Dashboard
                  </NavLink>
                </li>
                <li>
                  <NavLink strict to="/faturas">
                    <i className="fa fa-bolt" />
                    Faturas
                  </NavLink>
                </li>
                <Is checkRole="client">
                  <li>
                    <NavLink strict to="/usuarios">
                      <i className="fa fa-user" />
                      Usuários
                    </NavLink>
                  </li>
                </Is>
                <Is checkRole="administrator">
                  <li className="divider">ADMIN</li>
                  <li>
                    <NavLink strict to="/usuarios">
                      <i className="fa fa-user" />
                      Usuários
                    </NavLink>
                  </li>
                  <li>
                    <NavLink strict to="/projetos">
                      <i className="fa fa-building-o" />
                      Projetos
                    </NavLink>
                  </li>
                  <li>
                    <NavLink strict to="/equipamentos">
                      <i className="fa fa-hdd-o" />
                      Equipamentos
                    </NavLink>
                  </li>
                </Is>
              </ul>
            </nav>
          </section>
        </aside>
      </HdrContainer>
    );
  }
}
const mapStateToProps = state => ({
  menu: state.menu
});

const mapDispatchToProps = dispatch =>
  bindActionCreators({ ...MenuActions, ...AuthActions }, dispatch);
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Header);
