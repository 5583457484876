import styled from "styled-components";

export const Container = styled.div`
  position: fixed;
  display: flex;
  top: 0;
  bottom: 0;
  width: 100%;
  z-index: 999999;
  background: rgba(234, 234, 234, 1);
  justify-content: center;
  flex-direction: column;
  align-content: center;
  text-align: center;

  i {
    margin: 0 auto;
    margin-bottom: 20px;
  }
  p {
    text-transform: uppercase;
    letter-spacing: 1.13px;
    font-weight: 700;
  }
`;
