import styled from "styled-components";

export const HdrContainer = styled.div`
  display: ${props => props.hidden ? "none" : "flex"};
  -webkit-box-align: stretch;
  align-items: stretch;
  min-height: 100vh;
  background: rgb(32, 30, 41);

  .hidden{
    display:none;
  }

  aside {
    width: 106px;
    color: rgb(255, 255, 255);
    z-index: 10;
    background: rgb(40, 36, 52);
    transition: all 0.2s ease 0s;
    left: -106px;
    position: absolute;

    header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      background: #f0f0f0;
      padding: 0px;
      height: 60px;
      img {
        max-width: 100%;
        margin: 5px;
        padding: 5px;
      }
    }
    section {
      height: calc(100% - 60px);
      display: flex;
      flex-direction: column;
      -webkit-box-pack: justify;
      justify-content: space-between;
      nav {
        ul {
          list-style: none;
        }

        ul li a {
          width: 100%;
          display: flex;
          flex-direction: column;
          -webkit-box-align: center;
          align-items: center;
          -webkit-box-pack: center;
          justify-content: center;
          color: #fff;
          font-size: 12px;
          padding: 15px 0px;
          transition: all 0.1s ease 0s;
          border-width: 0px;
          border-style: initial;
          border-color: initial;
          border-image: initial;
          text-transform: uppercase;

          position: relative;
          i {
            font-size: 24px;
            color: #fff;
            margin: 0px 0px 8px;
          }
          &.active,
          &:hover {
            text-decoration: none;
            background: #201e29;
          }
          &.active::after {
            content: " ";
            position: absolute;
            right: 0;
            top: 18px;
            display: block;
            width: 0;
            height: 0;
            border-style: solid;
            border-width: 22px 10px 22px 0;
            border-color: transparent #ed1c24 transparent transparent !important;
          }
        }

        ul li.matheusdivider {
          font-size: 12px;
          color: #484265;
          text-transform: uppercase;
          letter-spacing: 1.13px;
          text-align: center;
          margin-top: 20px;
        }

        ul li.divider {
          font-size: 12px;
          color: #cfcfcf;
          text-transform: uppercase;
          letter-spacing: 1.13px;
          text-align: center;
          margin-top: 10px;
        }
      }
    }
  }
  aside.show {
    left: 0px;
    position: relative;
  }
`;

export const Logout = styled.button`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 50px;
  color: #fff;
  font-weight: bold;
  transition: all 0.2s;
  background: transparent;
  border: 0px;
  font-weight: 300;
  i {
    font-size: 24px;
    color: #fff;
    margin: 0px 0px 3px;
  }
`;
