import { createGlobalStyle } from "styled-components";
import "react-redux-toastr/lib/css/react-redux-toastr.min.css";
import "font-awesome/css/font-awesome.css";
export default createGlobalStyle`
  @import url('https://fonts.googleapis.com/css?family=Roboto:300,400,500,700&display=swap');
  *{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    outline: 0;
  }
  button{
    cursor: pointer;
  }
  html,body, #root{
  }
  a {
    text-decoration: none;
  }
  body{
    text-rendering: optimizeLegibility !important;
    -webkit-font-smoothing: antialiase !important;
    background: #F0F0F0;
    font-family: 'Roboto', sans-serif !important;
    color: #333333;
  }

  input,button {
    font-family: 'Roboto', sans-serif !important;
  }
  .inputError{
    color: red;
    font-weight: 500;
    text-transform: uppercase;
    font-size: 12px;

  }

  .btn{
    font-size: 0.8rem !important;
    border-radius: 0px !important;
    text-transform:uppercase;
    letter-spacing:1.13px;
  }

  .alignCenter{
    display: flex;
    align-items: center;
    justify-content: center;
  }
  input{
    font-size: 0.85rem !important;
    font-weight: 400 !important;
    border-radius: 0 !important;
    
  }
  label{
    font-size: 12px;
    text-transform: uppercase;
    font-weight: bold;
    letter-spacing: 1px;
  }
  .textCenter{
    text-align:center;
  }
  .margin-r-10{
    margin-right:10px;
  }
  .filter-label {
    color: #464457 !important; 
  }
  .loader-dashboard{
    color:#801300;
  }
  .title-panel-dash{
    font-size: 1.2rem;
    font-weight: 700;
    margin: 0;
    text-transform: uppercase;
  }
  .container{
    width: 100%;
    max-width: inherit !important;
  }
  .max-h-400{
    max-height: 400px;
    overflow-y: scroll;
  }
  @media screen and (max-width: 768px) {
    .filterButton{
      margin-left: 0px;
      margin-top: 6px;
      float: right;
    }
    .filterButton.no-margin{
      margin-top: 0px;
    }
  }
  .fleft{
    float:left;
    margin-right:10px;
  }
  .recharts-legend-item-text{
    color : #000 !important;
  }
`;
