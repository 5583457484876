import { createReducer, createActions } from "reduxsauce";
import Immutable from "seamless-immutable";

const { Types, Creators } = createActions({
  selectProjectDash: ["id"],
  selectProjectDashSuccess: ["data"],
  getChartData: ["data"],
  setChartData: ["consumoData"],
  getMaxMin: ["data"],
  getTotal: ["data"],
  setTotal: ["data"],
  setMaxMin: ["data"],
  showModal: null,
  hideModal: null,
  createFatura: ["data"],
  setFatura: ["data"]
});

export const DashBoardTypes = Types;
export default Creators;

export const INITIAL_STATE = Immutable({
  initialDate: "",
  finalDate: "",
  selected_point: null,
  modalFaturaOpen: false,
  data: JSON.parse(localStorage.getItem("@QED:currentProjectDash")) || {},
  consumoData: false,
  consumoDataLoaded: false,
  maxMinLoaded: false,
  totalLoaded: false,
  total: [],
  maxMin: [],
  modal: false,
  createLoader: false
});

export const changeMenu = state => {
  if (state.menuVisible) {
    return state.merge({ menuVisible: false });
  } else {
    return state.merge({ menuVisible: true });
  }
};
export const selectProject = (state, { data }) => {
  localStorage.setItem("@QED:currentProjectDash", JSON.stringify(data));
  return state.merge({ ...state, data: data, consumoData: false });
};

export const setChart = (state, { consumoData }) => {
  return state.merge({
    ...state,
    consumoData: consumoData,
    consumoDataLoaded: true
  });
};
export const getChartData = (state, { data }) => {
  return state.merge({
    ...state,
    consumoDataLoaded: false
  });
};
export const getTotal = (state, { data }) => {
  return state.merge({
    ...state,
    totalLoaded: false
  });
};
export const getMaxMin = (state, { data }) => {
  return state.merge({
    ...state,
    maxMinLoaded: false
  });
};
export const setMaxMin = (state, { data }) => {
  return state.merge({
    ...state,
    maxMinLoaded: true,
    maxMin: data
  });
};
export const setTotal = (state, { data }) => {
  return state.merge({
    ...state,
    total: data,
    totalLoaded: true
  });
};
export const showModal = (state, { data }) => {
  return state.merge({
    ...state,
    modal: true
  });
};
export const hideModal = (state, { data }) => {
  return state.merge({
    ...state,
    modal: false,
    createLoader: false
  });
};
export const createFatura = (state, { data }) => {
  return state.merge({
    ...state,
    createLoader: true
  });
};
export const returnFatura = (state, { data }) => {
  return state.merge({
    ...state,
    createLoader: false,
    modal: false
  });
};
export const reducer = createReducer(INITIAL_STATE, {
  [Types.SELECT_PROJECT_DASH_SUCCESS]: selectProject,
  [Types.SET_CHART_DATA]: setChart,
  [Types.GET_CHART_DATA]: getChartData,
  [Types.GET_MAX_MIN]: getMaxMin,
  [Types.GET_TOTAL]: getTotal,
  [Types.SET_TOTAL]: setTotal,
  [Types.SET_MAX_MIN]: setMaxMin,
  [Types.SHOW_MODAL]: showModal,
  [Types.HIDE_MODAL]: hideModal,
  [Types.CREATE_FATURA]: createFatura,
  [Types.SET_FATURA]: returnFatura
});
