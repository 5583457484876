import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import PropTypes from "prop-types";
import AuthActions from "../../../store/ducks/auth";
// import {Button,AnyButton} from "../../../styles/components/Button";
import {AnyButton} from "../../../styles/components/Button";
import { Container, SignForm } from "../auth_styles";
// import queryString from "query-string";
import { Link } from "react-router-dom";

class Password extends Component {
  state = {
    email: "",
    token: "",
    password: ""
  };

  static propTypes = {
    changePasswordRequest: PropTypes.func.isRequired
  };

  handleSubmit = e => {
    e.preventDefault();
    const { token, password } = this.state;
    //const { token } = queryString.parse(this.props.location.search);
    const { changePasswordRequest } = this.props;
    changePasswordRequest(token || null, password);
  };

  handleInputChange = e => {
    this.setState({ [e.target.name]: e.target.value });
  };

  render() {
    const { email, token, password } = this.state;
    return (
      <Container>
        <SignForm onSubmit={this.handleSubmit}>
          <h1>
            Q<span>&</span>D<br />
            Alterar Senha
          </h1>
          {/*<span>Email</span>
          <input
            type="email"
            name="email"
            onChange={this.handleInputChange}
            value={email}
            required
          />*/}
          <span>Código de Acesso</span>
          <input
            type="token"
            name="token"
            onChange={this.handleInputChange}
            value={token}
            maxlength="64"
            required
          />
          <span>Nova Senha</span>
          <input
            type="password"
            name="password"
            onChange={this.handleInputChange}
            value={password}
            maxlength="64"
            required
          />
          <AnyButton size="big" color="danger"  type="submit">
            Alterar
          </AnyButton>
          <Link to="/password">Voltar para solicitar código</Link>
        </SignForm>
      </Container>
    );
  }
}

const mapDispatchToProps = dispatch =>
  bindActionCreators(AuthActions, dispatch);
export default connect(
  null,
  mapDispatchToProps
)(Password);
