import axios from "axios";
import store from "../store";

const api = axios.create({
  //baseURL: "https://api.qedtelemetria.tk"
  //baseURL: "https://qedtelemetria.com.br/pta3333/"
  //baseURL: "http://localhost:3333/"
  baseURL: process.env.REACT_APP_API_URL
});

api.interceptors.request.use(config => {
  const { token } = store.getState().auth;
  /*
  const headers = { ...config.headers };

  if (token) {
    headers.Authorization = `Bearer ${token}`;
  }
  return { ...config, headers };
  */
 if ( token )
  config.headers.Authorization = `Bearer ${token}`;

 return config;
});

export default api;
