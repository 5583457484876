import React, { Component } from "react";
import { Link } from "react-router-dom";
import ReactPaginate from "react-paginate";
/* REDUX */
import ProjectsActions from "../../store/ducks/project";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

/*Styles*/
import { Container, Content, Panel } from "./styles";

/*Layout*/
import { Table, Button, Form, Row, Col, InputGroup } from "react-bootstrap";

/*Components*/
import Modal from "../../components/Modal";
import Header from "../../components/Header";
import TopBar from "../../components/TopBar";
import Loader from "../../components/Loader";
import Is from "../../components/Is";

class Projetos extends Component {
  componentDidMount() {
    const { getProjectsRequest } = this.props;
    getProjectsRequest();
  }

  state = {
    projectEdit: "",
    filter: "",
    client_id: false
  };

  hidenModal = () => {
    this.setState({ client_id: false });
    const { hideModal } = this.props;
    hideModal();
  };

  mostraModal = id => {
    this.setState({ client_id: id });
    const { showModal } = this.props;
    showModal();
  };

  /* Pega o texto dos inputs */
  handleInputChange = e => {
    this.setState({ [e.target.name]: e.target.value });
  };

  /* Filta os dados */
  handleFilter = () => {
    const { filter } = this.state;
    const { getProjectsRequest } = this.props;
    getProjectsRequest(null, filter);
  };

  handleDelete = id => {
    const { deleteProject } = this.props;
    const { client_id } = this.state;
    deleteProject(client_id);
  };

  handlePageClick = data => {
    const { getProjectsRequest } = this.props;
    let selected = data.selected;
    selected = selected + 1;
    getProjectsRequest(selected);
  };

  render() {
    const { projects } = this.props;
    return (
      <Container>
        {!projects.loaded && <Loader />}
        <Header />
        <Content>
          <TopBar title="Projetos" />

          <Is checkRole={"administrator"}>
            <Panel>
              <div className="container_action">
                <h1>Lista de projetos</h1>
                <Link
                  variant="success"
                  to="/projetos/cadastro"
                  className="btn btn-outline-primary"
                >
                  <i className="fa fa-plus" /> &nbsp;Cadastrar
                </Link>
              </div>

              <div className="panel-container">
                <p className="filter-form-text">Filtros:</p>
                <Row>
                  <Col xs={12} md={4} lg={4}>
                    <InputGroup className="mb-3">
                      <Form.Control
                        type="text"
                        placeholder="Nome ou Sigla"
                        name="filter"
                        onChange={this.handleInputChange}
                      />
                      <Button
                        variant="outline-primary"
                        onClick={() => {
                          this.handleFilter();
                        }}
                        className="filterButton no-margin"
                      >
                        <i className="fa fa-search" />
                      </Button>
                    </InputGroup>
                  </Col>
                </Row>
                <br />

                <Table responsive hover>
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>Sigla</th>
                      <th>Nome</th>
                      <th>Status</th>
                      <th>Editar</th>
                      <th className="textCenter">Coletores</th>
                      <th className="textCenter">Medidores</th>
                      <th className="textCenter">Alterar Status</th>
                    </tr>
                  </thead>
                  <tbody>
                    {projects.loaded && !projects.data.length ? (
                      <tr>
                        <td colSpan="5">Nenhum projeto encontrado</td>
                      </tr>
                    ) : (
                      projects.data.map(project => (
                        <tr key={project.IdCliente}>
                          <td>{project.IdCliente}</td>
                          <td>{project.Sigla}</td>
                          <td>{project.Nome}</td>
                          <td>{(!project.status && "Inativo") || "Ativo"}</td>

                          <td>
                            <Link
                              className="btn btn-outline-primary"
                              to={`/projetos/${project.IdCliente}`}
                            >
                              <i className="fa fa-edit" />
                            </Link>
                          </td>
                          <td className="textCenter">
                            <Link
                              className="btn btn-outline-dark"
                              to={`/projeto/${project.IdCliente}/coletores`}
                            >
                              <i className="fa fa-cube" />
                            </Link>
                          </td>
                          <td className="textCenter">
                            <Link
                              className="btn btn-outline-dark"
                              to={`/projetos/${project.IdCliente}/pontos`}
                            >
                              <i className="fa  fa-bolt" />
                            </Link>
                          </td>
                          <td className="textCenter">
                            <Button
                              variant="outline-danger"
                              onClick={() => {
                                this.mostraModal(project.IdCliente);
                              }}
                            >
                              <i className="fa fa-warning" />
                            </Button>
                          </td>
                        </tr>
                      ))
                    )}
                  </tbody>
                </Table>

                <div className="alignCenter">
                  <ReactPaginate
                    previousLabel={"<"}
                    nextLabel={">"}
                    breakLabel={"..."}
                    breakClassName={"break-me"}
                    pageCount={projects.projectInfo.lastPage}
                    marginPagesDisplayed={2}
                    pageRangeDisplayed={2}
                    onPageChange={this.handlePageClick}
                    disableInitialCallback={true}
                    containerClassName={"pagination"}
                    subContainerClassName={"pages pagination"}
                    activeClassName={"active"}
                  />
                </div>
              </div>
            </Panel>
          </Is>
        </Content>
        {projects.modal && (
          <Modal>
            <Row>
              <Col>
                <h2 className="textCenter">
                  Essa ação não poderá ser desfeita
                </h2>
              </Col>
            </Row>
            <Row>
              <Col>
                <br />
                <p className="text-center">
                  Você deseja alterar o status do projeto? Essa ação pode
                  impossibilitar o usuário de acessar os dados.
                </p>
              </Col>
            </Row>
            <Row>
              <Col className="textCenter">
                <br />
                <Button
                  variant="outline-danger"
                  type="button"
                  onClick={() => {
                    this.hidenModal();
                  }}
                >
                  Cancelar
                </Button>
                &nbsp;&nbsp;{" "}
                <Button
                  variant="outline-primary"
                  type="button"
                  onClick={() => {
                    this.handleDelete();
                  }}
                >
                  <span>Sim, desejo alterar</span>
                </Button>
              </Col>
            </Row>
          </Modal>
        )}
      </Container>
    );
  }
}

const mapStateToProps = state => ({
  projects: state.project
});
const mapDispatchToProps = dispatch =>
  bindActionCreators({ ...ProjectsActions }, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(Projetos);
