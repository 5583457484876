import React, { Component } from "react";
import { Link } from "react-router-dom";
import ReactPaginate from "react-paginate";

/* REDUX */
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import UsersActions from "../../store/ducks/users";

/*Styles*/
import { Container, Content, Panel } from "./styles";

/*Layout*/
import { Table, Button, Form, Row, Col, InputGroup } from "react-bootstrap";

/*Components*/
import Modal from "../../components/Modal";
import Header from "../../components/Header";
import TopBar from "../../components/TopBar";
import Loader from "../../components/Loader";
import Is from "../../components/Is";

class Usuarios extends Component {
  state = {
    id: false,
    filter: ""
  };

  hidenModal = () => {
    this.setState({ id: false });
    const { hideModal } = this.props;
    hideModal();
  };

  mostraModal = id => {
    this.setState({ id: id });
    const { showModal } = this.props;
    showModal();
  };

  componentDidMount() {
    const { getUsersRequest } = this.props;
    getUsersRequest();
  }

  /* Pega o texto dos inputs */
  handleInputChange = e => {
    this.setState({ [e.target.name]: e.target.value });
  };

  /* Filta os dados */
  handleFilter = () => {
    const { filter } = this.state;
    const { getUsersRequest } = this.props;
    getUsersRequest(null, filter);
  };

  /* Paginação */
  handlePageClick = data => {
    const { getUsersRequest } = this.props;
    let selected = data.selected;
    selected = selected + 1;
    getUsersRequest(selected);
  };

  handleEdit = id => {
    const { getUser } = this.props;
    console.log(id);
    getUser(id);
  };

  handleDelete = () => {
    const { deleteUser } = this.props;
    const { id } = this.state;
    deleteUser(id);
    this.hidenModal();
  };

  isAdmin = roles => {
    const arrayFilter = roles.filter(role => role.id === 1);
    console.log(roles);
    return arrayFilter.length;
  };
  isUser = (authEmail, userEmail) => {
    return localStorage.getItem("@QED:email") === userEmail;
  };
  render() {
    const { users } = this.props;
    const { auth } = this.props;
    return (
      <Container>
        {!users.loaded && <Loader />}
        <Header />
        <Content>
          <TopBar title="Usuários" />
          <Is checkRole={["administrator", "client"]}>
            <Panel>
              <div className="container_action">
                <h1>Lista de usuários </h1>
                <Link
                  className="btn btn-outline-primary"
                  to="/usuarios/cadastro"
                >
                  <i className="fa fa-plus" /> &nbsp; Novo Usuário
                </Link>
              </div>

              <div className="panel-container">
                <p className="filter-form-text">Filtros:</p>
                <Row>
                  <Col xs={12} md={12} lg={12}>
                    <InputGroup className="mb-3">
                      <Form.Control
                        type="text"
                        placeholder="Nome"
                        name="filter"
                        onChange={this.handleInputChange}
                      />
                      <Button
                        variant="outline-primary"
                        onClick={() => {
                          this.handleFilter();
                        }}
                        className="filterButton no-margin"
                      >
                        <i className="fa fa-search" />
                      </Button>
                    </InputGroup>
                  </Col>
                </Row>
                <br />
                <Table responsive hover>
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>Nome</th>
                      <th>Email</th>
                      <th>Grupo</th>
                      <th>Status</th>
                      <th className="textCenter">Editar</th>
                      <th className="textCenter">Remover</th>
                    </tr>
                  </thead>
                  <tbody>
                    {!users.data.length ? (
                      <tr>
                        <td colSpan="5">Nenhum usuário encontrado</td>
                      </tr>
                    ) : (
                      users.data.map(user => (
                        <tr key={user.id}>
                          <td>{user.id}</td>
                          <td>{user.name}</td>
                          <td>{user.email}</td>
                          <td>
                            {user.roles.map(role => (
                              <span key={role.id}>{role.name}&nbsp;&nbsp;</span>
                            ))}
                          </td>
                          <td>{(!user.status && "Inativo") || "Ativo"}</td>
                          <td className="textCenter">
                            <Link
                              className="btn btn-outline-primary"
                              to={`/usuarios/${user.id}`}
                            >
                              <i className="fa fa-edit" />
                            </Link>
                          </td>
                          <td className="textCenter">
                            {!this.isAdmin(user.roles) &&
                              !this.isUser(auth.email, user.email) && (
                                <Button
                                  variant="outline-danger"
                                  onClick={() => {
                                    this.mostraModal(user.id);
                                  }}
                                >
                                  <i className="fa fa-warning" />
                                </Button>
                              )}
                          </td>
                        </tr>
                      ))
                    )}
                  </tbody>
                </Table>
                <div className="alignCenter">
                  <ReactPaginate
                    previousLabel={"<"}
                    nextLabel={">"}
                    breakLabel={"..."}
                    breakClassName={"break-me"}
                    pageCount={users.userInfo.lastPage}
                    marginPagesDisplayed={2}
                    pageRangeDisplayed={2}
                    onPageChange={this.handlePageClick}
                    disableInitialCallback={true}
                    containerClassName={"pagination"}
                    subContainerClassName={"pages pagination"}
                    activeClassName={"active"}
                  />
                </div>
              </div>
            </Panel>
          </Is>
        </Content>
        {users.modal && (
          <Modal>
            <Row>
              <Col>
                <h2 className="textCenter">
                  Essa ação não poderá ser desfeita
                </h2>
              </Col>
            </Row>
            <Row>
              <Col>
                <br />
                <p className="text-center">
                  Você realmente deseja remover o usuário? Dados vinculados ao
                  mesmo também serão deletados
                </p>
              </Col>
            </Row>
            <Row>
              <Col className="textCenter">
                <br />
                <Button
                  variant="outline-danger"
                  type="button"
                  onClick={() => {
                    this.hidenModal();
                  }}
                >
                  Cancelar
                </Button>
                &nbsp;&nbsp;{" "}
                <Button
                  variant="outline-primary"
                  type="button"
                  onClick={() => {
                    this.handleDelete();
                  }}
                >
                  <span>Sim, desejo remover</span>
                </Button>
              </Col>
            </Row>
          </Modal>
        )}
      </Container>
    );
  }
}

const mapStateToProps = state => ({
  users: state.users,
  auth: state.auth
});
const mapDispatchToProps = dispatch =>
  bindActionCreators({ ...UsersActions }, dispatch);
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Usuarios);
