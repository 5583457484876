import { createReducer, createActions } from "reduxsauce";
import Immutable from "seamless-immutable";

const { Types, Creators } = createActions({
  getUsersRequest: ["page", "filter"], //busca os usuários
  getUsersSuccess: ["dados"], //retorno de usuários
  createUser: ["data"], //criar usuário
  showLoader: null, // Mostra o loader
  hideLoader: null, // Remove o loader
  getUser: ["id"],
  setUser: ["data"],
  updateUser: ["data"],
  deleteUser: ["id"],
  deleteUserSuccess: ["userData"],
  showModal: null,
  hideModal: null
});
export const UsersTypes = Types;
export default Creators;
export const INITIAL_STATE = Immutable({
  data: [],
  roles: [],
  clients: [],
  userInfo: {},
  currentUser: {},
  loaded: true,
  modal: false
});
/* Carrega o loader */
export const showLoader = (state, { page, filter }) => {
  return state.merge({ ...state, loaded: false });
};
/* Esconde o loader */
export const hideLoader = (state, { page, filter }) => {
  return state.merge({ ...state, loaded: true });
};
/* Seta os usuários */
export const success = (state, { dados }) => {
  const { data, ...userInfo } = dados;
  return state.merge({
    ...state,
    data: data,
    userInfo: userInfo,
    loaded: true,
    userLoaded: false
  });
};

export const setUser = (state, { data }) => {
  const userData = {
    id: data.id,
    name: data.name,
    email: data.email
  };
  const rolesData = [];
  data.roles.forEach(element => {
    rolesData.push(element.id);
  });
  const clientsData = [];
  data.clients.forEach(element => {
    clientsData.push(element.id);
  });
  userData["roles"] = rolesData;
  userData["clients"] = clientsData;
  return state.merge({
    ...state,
    currentUser: userData,
    userLoaded: true,
    loaded: true
  });
};

export const deleteUserSuccess = (state, { userData }) => {
  const id = userData.id;
  return state.merge({
    data: state.data.filter(user => user.id !== id),
    modal: false
  });
};
export const showModal = (state, { data }) => {
  return state.merge({
    ...state,
    modal: true
  });
};
export const hideModal = (state, { data }) => {
  return state.merge({
    ...state,
    modal: false
  });
};

export const reducer = createReducer(INITIAL_STATE, {
  [Types.GET_USERS_REQUEST]: showLoader,
  [Types.GET_USERS_SUCCESS]: success,
  [Types.SET_USER]: setUser,
  [Types.SHOW_LOADER]: showLoader,
  [Types.HIDE_LOADER]: hideLoader,
  [Types.CREATE_USER]: showLoader,
  [Types.UPDATE_USER]: showLoader,
  [Types.DELETE_USER_SUCCESS]: deleteUserSuccess,
  [Types.SHOW_MODAL]: showModal,
  [Types.HIDE_MODAL]: hideModal
});
