import React, { Component } from "react";
import AuthActions from "../../store/ducks/auth";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";

class Is extends Component {
  render() {
    if (Array.isArray(this.props.checkRole)) {
      if (this.props.auth.roles.some(r => this.props.checkRole.includes(r))) {
        return this.props.children;
      } else {
        return false;
      }
    } else {
      if ( this.props.checkRole
      &&   this.props.auth
      &&   this.props.auth.roles
      &&   Array.isArray(this.props.auth.roles)
      &&  !this.props.auth.roles.includes(this.props.checkRole) ) {
        return false;
      } else {
        return this.props.children;
      }
    }
  }
}
const mapStateToProps = state => ({
  auth: state.auth
});
const mapDispatchToProps = dispatch =>
  bindActionCreators({ ...AuthActions }, dispatch);
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Is);
