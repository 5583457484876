import { createReducer, createActions } from "reduxsauce";
import Immutable from "seamless-immutable";

const { Types, Creators } = createActions({
  showLoader: null,
  hideLoader: null,
  getConsumidores: ["id", "page", "filter"],
  setConsumidores: ["dados"],
  getConsByCliWpt: ["id"], 
  chgConsByCliWpt: null, 
  setConsByCliWpt: ["dados"], 
  createConsumidor: ["data"],
  getConsumidor: ["id", "consumidor"],
  setConsumidor: ["data"],
  updateConsumidor: ["data"],
  deleteConsumidor: ["id", "client_id"],
  deleteSucc: ["data"]
});

export const ConsumidoresTypes = Types;
export default Creators;

export const INITIAL_STATE = Immutable({
  loaded: true,
  loadedConsOfCliWpt: false,
  verConsInfo: 0,
  verConsOfCliWpt: 0,
  data: [],
  consumidoresInfo: {},
  currentConsumidor: {},
  consOfCliWpt: []
});

/* Carrega o loader */
export const showLoader = (state, { id, page, filter }) => {
  return state.merge({ ...state, loaded: false });
};

/* Esconde o loader */
export const hideLoader = (state, { page, filter }) => {
  return state.merge({ ...state, loaded: true });
};

export const setConsumidores = (state, { dados }) => {
  const { data, ...consumidoresInfo } = dados;
  return state.merge({
    ...state,
    data: data,
    consumidoresInfo: consumidoresInfo,
    loaded: true,
    verConsInfo: state.verConsInfo + 1
  });
};

export const chgConsByCliWpt = state =>
  state.merge({
    ...state,
    loadedConsOfCliWpt: false
  });

export const setConsByCliWpt = (state, { dados }) => {

  return state.merge({
    ...state,
    loadedConsOfCliWpt: true,
    consOfCliWpt: dados,
    verConsOfCliWpt: state.verConsOfCliWpt + 1
  });
};

export const setConsumidor = (state, { data }) => {
  return state.merge({
    ...state,
    currentConsumidor: data,
    loaded: true
  });
};
export const deleteSuccess = (state, { data }) => {
  return state.merge({
    ...state,
    data: state.data.filter(consumidor => {
      return consumidor.IdConsumidor != data.id;
    }),
    loaded: true
  });
};

export const reducer = createReducer(INITIAL_STATE, {
  [Types.SHOW_LOADER]: showLoader,
  [Types.HIDE_LOADER]: hideLoader,
  [Types.GET_CONSUMIDORES]: showLoader,
  [Types.SET_CONSUMIDORES]: setConsumidores,
  [Types.GET_CONS_BY_CLI_WPT]: chgConsByCliWpt, 
  [Types.CHG_CONS_BY_CLI_WPT]: chgConsByCliWpt, 
  [Types.SET_CONS_BY_CLI_WPT]: setConsByCliWpt, 
  [Types.CREATE_CONSUMIDOR]: showLoader,
  [Types.GET_CONSUMIDOR]: showLoader,
  [Types.SET_CONSUMIDOR]: setConsumidor,
  [Types.UPDATE_CONSUMIDOR]: showLoader,
  [Types.DELETE_CONSUMIDOR]: showLoader,
  [Types.DELETE_SUCC]: deleteSuccess
});
