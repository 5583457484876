import React, { Component } from "react";
import { Link } from "react-router-dom";

/* REDUX */
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import ProjectsActions from "../../store/ducks/project";
import DashboardActions from "../../store/ducks/dashboard";

/*Styles*/
import { ContainerPrimary, Content, ProjectContainer } from "./styles";

/*Layout*/
import { Container, Row, Col } from "react-grid-system";
import Button from "../../styles/components/Button";
import dash from "../../assets/images/icons/dashboard.svg";

/*Components*/
import Header from "../../components/Header";
import TopBar from "../../components/TopBar";
import Loader from "../../components/Loader";

class Dashboard extends Component {

  componentDidMount() {
    const { getProjectsRequest } = this.props;
    getProjectsRequest();
  }

  handleDash = id => {
    const { selectProjectDash } = this.props;
    selectProjectDash(id)
  };

  render() {
    const { projects } = this.props;

    return (
      <ContainerPrimary>
        <Header />
        {!projects.loaded && <Loader />}
        <Content>
          <TopBar title="Dashboard" />
          <Container className="bootstrap-container">
            <Row>
              {projects.data.map(project => (
                <Col
                  sm={2}
                  md={3}
                  lg={4}
                  className="project_col"
                  key={project.IdCliente}
                >
                  <ProjectContainer>
                    <div className="container_action">
                      <h1>{project.Nome}</h1>
                    </div>
                    <img src={dash} alt="" />
                    <div className="container-actions">
                      <Link to={`/consumidores/${project.IdCliente}`}>
                        <i className="fa fa-id-card" />
                      </Link>
                      <Link to={`/show/${project.IdCliente}`}>
                        <i className="fa fa-flash" />
                      </Link>
                    </div>
                  </ProjectContainer>
                </Col>
              ))}
            </Row>
          </Container>
        </Content>
      </ContainerPrimary>
    );
  }
}
const mapStateToProps = state => ({
  projects: state.project
});
const mapDispatchToProps = dispatch =>
  bindActionCreators({ ...ProjectsActions, ...DashboardActions }, dispatch);
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Dashboard);
