import React, { Component } from "react";
import { Link } from "react-router-dom";

/* REDUX */
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import PontosActions from "../../store/ducks/pontos";
import EquipamentosActions from "../../store/ducks/equipamentos";
import ErrorsActions from "../../store/ducks/errors";

/*Styles*/
import { Container, Content, Panel } from "./styles";

/*Layout*/
import { Row, Col } from "react-grid-system";
import { Button, Form } from "react-bootstrap";

/*Components*/
import Header from "../../components/Header";
import TopBar from "../../components/TopBar";
import Loader from "../../components/Loader";

class PontosAdd extends Component {
  state = {
    GTag : "",
    IdCliente: "",
    IdColetor: "",
    IdEquip: "",
    IdSistema : "",
    IdTipoVal: "",
    IdUnidMed: "",
    IdConsumidor: "",
    Descricao: "",
    Referencia: "",
    Offset: "",
    FatorMult: "",
    ValMin : "",
    ValMax : ""
  };

  componentDidMount() {
    const { id } = this.props.match.params;
    const {
      clearError,
      getConsumidoresPoints,
      getColetoresPoints,
      getEquipamentos,getValores,getUnidades
    } = this.props;
    /* Limpa os Erros */
    clearError();
    this.setState({ IdCliente: id });
    //busca os valores
    getValores();
    //busca os unidades
    getUnidades();
    //busca os equipamentos
    getEquipamentos();
    //busca os consumidores
    getConsumidoresPoints(id);
    //busca os coletores
    getColetoresPoints(id);
  }

  handleInputChange = e => {
    this.setState({ [e.target.name]: e.target.value });
  };

  handleSubmit = e => {
    e.preventDefault();
    const { createPonto } = this.props;
    createPonto(this.state);
  };

  render() {
    const { errors, pontos } = this.props;
    const { equipamentos } = this.props;
    const { coletores, consumidores, unidades , valores } = this.props.pontos;
    const { id } = this.props.match.params;
    console.log(pontos);
    return (
      <Container>
        {(!pontos.consumidoresLoaded || !pontos.coletoresLoaded || !equipamentos.loaded || !pontos.unidadesLoaded || !pontos.valoresLoaded) && <Loader />}
        <Header />
        <Content>
          <TopBar title="Cadastro de pontos" />
          <Panel>
            <div className="container_action">
              <h1>Novo ponto</h1>
              <Link className="btn btn-success" to={`/projetos/${id}/pontos`}>
                <i className="fa fa fa-arrow-left" /> &nbsp; Voltar
              </Link>
            </div>
            <div className="panel-container">
              <Form>
                <Row>
                  <Form.Group as={Col}>
                    <Form.Label>Tag</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Informe a tag"
                      name="GTag"
                      onChange={this.handleInputChange}
                    />
                    {errors.data.GTag && (
                      <span className="inputError">{errors.data.GTag}</span>
                    )}
                  </Form.Group>
                </Row>
                <Row>
                  <Form.Group as={Col} xs={12} md={6} lg={6}>
                    <Form.Label>Coletor</Form.Label>
                    <Form.Control
                      name="IdColetor"
                      as="select"
                      onChange={this.handleInputChange}
                    >
                      <option key="0" value="0">
                        Selecione um coletor
                      </option>
                      {coletores.map(coletor => (
                        <option key={coletor.IdColetor} value={coletor.IdColetor}>
                          {coletor.IdColetor} - {coletor.Nome}
                        </option>
                      ))}
                    </Form.Control>
                    {errors.data.IdColetor && (
                      <span className="inputError">
                        {errors.data.IdColetor}
                      </span>
                    )}
                  </Form.Group>

                  <Form.Group as={Col} xs={12} md={6} lg={6}>
                    <Form.Label>Consumidor</Form.Label>
                    <Form.Control
                      name="IdConsumidor"
                      as="select"
                      onChange={this.handleInputChange}
                    >
                      <option key="0" value="0">
                        Selecione um consumidor
                      </option>
                      {consumidores.map(consumidor => (
                        <option key={consumidor.IdConsumidor} value={consumidor.IdConsumidor}>
                          {consumidor.SiglaNum} - {consumidor.NomeFantasia}
                        </option>
                      ))}
                    </Form.Control>
                    {errors.data.IdConsumidor && (
                      <span className="inputError">
                        {errors.data.IdConsumidor}
                      </span>
                    )}
                  </Form.Group>
                </Row>

                <Row>
                  <Form.Group as={Col} xs={12} md={6} lg={6}>
                    <Form.Label>Equipamento</Form.Label>
                    <Form.Control
                      name="IdEquip"
                      as="select"
                      onChange={this.handleInputChange}
                    >
                      <option key="0" value="0">
                        Selecione um equipamento
                      </option>
                      {equipamentos.data.map(equipamento => (
                        <option key={equipamento.IdEquip} value={equipamento.IdEquip}>
                          {equipamento.Sigla} - {equipamento.NumSerie}
                        </option>
                      ))}
                    </Form.Control>
                    {errors.data.IdEquip && (
                      <span className="inputError">
                        {errors.data.IdEquip}
                      </span>
                    )}
                  </Form.Group>

                  <Form.Group as={Col} xs={12} md={6} lg={6}>
                    <Form.Label>Sistema</Form.Label>
                    <Form.Control
                      name="IdSistema"
                      as="select"
                      onChange={this.handleInputChange}
                    >
                      <option key="0" value="0">
                        Selecione o sistema
                      </option>
                      <option key="1" value="1">
                        Energia
                      </option>
                      <option key="2" value="2">
                        Água
                      </option>
                      <option key="3" value="3">
                        Gás
                      </option>
                      
                    </Form.Control>
                    {errors.data.IdSistema && (
                      <span className="inputError">
                        {errors.data.IdSistema}
                      </span>
                    )}
                  </Form.Group>
                </Row>
                <Row>
                  <Form.Group as={Col} xs={12} md={6} lg={6}>
                    <Form.Label>Tipo de valor</Form.Label>
                    <Form.Control
                      name="IdTipoVal"
                      as="select"
                      onChange={this.handleInputChange}
                    >
                      <option key="0" value="0">
                        Selecione o tipo
                      </option>
                      {valores.map(valor => (
                        <option key={valor.IdTipoVal} value={valor.IdTipoVal}>
                          {valor.IdTipoVal} - {valor.Sigla}
                        </option>
                      ))}
                    </Form.Control>
                    {errors.data.IdTipoVal && (
                      <span className="inputError">
                        {errors.data.IdTipoVal}
                      </span>
                    )}
                  </Form.Group>

                  <Form.Group as={Col} xs={12} md={6} lg={6}>
                    <Form.Label>Unidade de medida</Form.Label>
                    <Form.Control
                      name="IdUnidMed"
                      as="select"
                      onChange={this.handleInputChange}
                    >
                      <option key="0" value="0">
                        Selecione a unidade de medida
                      </option>
                      {unidades.map(unidade => (
                        <option key={unidade.IdUnidMed} value={unidade.IdUnidMed}>
                          {unidade.IdUnidMed} - {unidade.Sigla}
                        </option>
                      ))}
                    </Form.Control>
                    {errors.data.IdUnidMed && (
                      <span className="inputError">
                        {errors.data.IdUnidMed}
                      </span>
                    )}
                  </Form.Group>
                </Row>
                <Row>
                  <Form.Group as={Col}>
                    <Form.Label>Descrição</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Informe a descrição"
                      name="Descricao"
                      onChange={this.handleInputChange}
                    />
                    {errors.data.Descricao && (
                      <span className="inputError">
                        {errors.data.Descricao}
                      </span>
                    )}
                  </Form.Group>
                </Row>
                <Row>
                  <Col>
                    <h2>Informações adicionais</h2>
                  </Col>
                </Row>
                <Row>
                  <Form.Group as={Col} xs={12} md={6} lg={6}>
                    <Form.Label>Referencia</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Informe a referencia"
                      name="Referencia"
                      onChange={this.handleInputChange}
                    />
                    {errors.data.Referencia && (
                      <span className="inputError">{errors.data.Referencia}</span>
                    )}
                  </Form.Group>

                  <Form.Group as={Col} xs={12} md={6} lg={6}>
                    <Form.Label>Offset</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Informe o offset"
                      name="Offset"
                      onChange={this.handleInputChange}
                    />
                    {errors.data.Offset && (
                      <span className="inputError">
                        {errors.data.Offset}
                      </span>
                    )}
                  </Form.Group>
                </Row>

                <Row>
                  <Form.Group as={Col} xs={12} md={6} lg={6}>
                    <Form.Label>Fator Mult.</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Informe o fator"
                      name="FatorMult"
                      onChange={this.handleInputChange}
                    />
                    {errors.data.FatorMult && (
                      <span className="inputError">{errors.data.FatorMult}</span>
                    )}
                  </Form.Group>

                  <Form.Group as={Col} xs={12} md={6} lg={6}>
                    <Form.Label>Valor Mínimo</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Valor Mínimo"
                      name="ValMin"
                      onChange={this.handleInputChange}
                    />
                    {errors.data.ValMin && (
                      <span className="inputError">
                        {errors.data.ValMin}
                      </span>
                    )}
                  </Form.Group>

                  <Form.Group as={Col} xs={12} md={6} lg={6}>
                    <Form.Label>Valor Máximo</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Valor Máximo"
                      name="ValMax"
                      onChange={this.handleInputChange}
                    />
                    {errors.data.ValMax && (
                      <span className="inputError">
                        {errors.data.ValMax}
                      </span>
                    )}
                  </Form.Group>
                </Row>
                <Button
                  variant="primary"
                  type="submit"
                  onClick={this.handleSubmit}
                >
                  Enviar
                </Button>
              </Form>
            </div>
          </Panel>
        </Content>
      </Container>
    );
  }
}

const mapStateToProps = state => ({
  errors: state.errors,
  pontos: state.pontos,
  equipamentos: state.equipamentos
});

const mapDispatchToProps = dispatch =>
  bindActionCreators({ ...PontosActions, ...ErrorsActions,...EquipamentosActions }, dispatch);
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PontosAdd);
