import { createReducer, createActions } from "reduxsauce";
import Immutable from "seamless-immutable";
const { Types, Creators } = createActions({
  getClients: null,
  setClients: ["data"],
  getInvoices: ["page", "filter",'archived'],
  setInvoices: ["dados"],
  getInvoiceData: ["client_id", "invoice_id"],
  setInvoiceData: ["data"],
  getInvoiceDataEdit: ["client_id", "invoice_id", "id"],
  setInvoiceDataEdit: ["data"],
  updateData: ["data"],
  hideLoader: null,
  getHistory: ["client_id", "invoice_id"],
  setInvoiceHistory: ["data"],
  deleteFatura: ["id"],
  deleteFaturaSucc: ["data"],
  getCostumersChart :['id'],
  setInvoiceConsumers : ['data'],
  showModal : null,
  hideModal : null,
  getInvoiceChartData: ["data"],
  getChartDataSuccess: ["data"]
});

export const InvoicesTypes = Types;
export default Creators;
export const INITIAL_STATE = Immutable({
  loadedHistory: true,
  loadedClients: true,
  loadedInvoices: true,
  loadedData: true,
  currentLoaded: true,
  clients: [],
  invoices: [],
  invoicesInfo: {},
  invoiceData: [],
  currentInvoiceData: {},
  history: [],
  costumersChart : [],
  modal : false,
  chartLoaded : false,
  chartData : []
});

export const getClients = state =>
  state.merge({
    ...state,
    loadedClients: false
  });

export const getInvoices = state =>
  state.merge({
    ...state,
    loadedInvoices: false
  });
export const getHistory = state =>
  state.merge({
    ...state,
    loadedHistory: false
  });
export const setInvoiceHistory = (state, { data }) => {
  return state.merge({
    ...state,
    loadedHistory: true,
    history: data
  });
};
export const setInvoiceConsumers = (state, { data }) => {
  console.log(data);
  return state.merge({
    ...state,
    currentLoaded: true,
    costumersChart: data
  });
};
export const setClients = (state, { data }) => {
  return state.merge({
    ...state,
    loadedClients: true,
    clients: data
  });
};
export const setInvoices = (state, { dados }) => {
  const { data, ...invoicesInfo } = dados;
  return state.merge({
    ...state,
    loadedInvoices: true,
    invoices: data,
    invoicesInfo: invoicesInfo
  });
};

export const getInvoiceData = state =>
  state.merge({
    ...state,
    loadedData: false,
    invoiceData: []
  });

export const setInvoiceData = (state, { data }) => {
  return state.merge({
    ...state,
    loadedData: true,
    invoiceData: data
  });
};
export const getInvoiceDataEdit = state =>
  state.merge({
    ...state,
    currentLoaded: false,
    currentInvoiceData: {}
  });

export const setInvoiceDataEdit = (state, { data }) => {
  return state.merge({
    ...state,
    currentLoaded: true,
    currentInvoiceData: data
  });
};
export const updateData = state =>
  state.merge({
    ...state,
    currentLoaded: false
  });
export const hideLoader = state =>
  state.merge({
    ...state,
    currentLoaded: true
  });
  export const showModal = state =>
  state.merge({
    ...state,
    modal: true
  });
  export const hideModal = state =>
  state.merge({
    ...state,
    modal: false
  });
  export const showLoader = state =>
  state.merge({
    ...state,
    currentLoaded: false
  });

  export const deleteFaturaSuccess = (state, { data }) => {
    return state.merge({
      ...state,
      invoices: state.invoices.filter(invoice => {
        if(invoice.status){
          return invoice.id !== data.id;
        }
        return false;
      }),
      currentLoaded: true
    });
  };
  
  export const getInvoiceChartData = (state, { data }) => {
    return state.merge({
      ...state,
      chartLoaded: false,
      chartData: []
    });
  };

  export const getChartDataSuccess = (state, { data }) => {
    return state.merge({
      ...state,
      chartLoaded: true,
      chartData: data
    });
  };
  
export const reducer = createReducer(INITIAL_STATE, {
  [Types.GET_CLIENTS]: getClients,
  [Types.SET_CLIENTS]: setClients,
  [Types.GET_INVOICES]: getInvoices,
  [Types.GET_HISTORY]: getHistory,
  [Types.SET_INVOICE_HISTORY]: setInvoiceHistory,
  [Types.SET_INVOICES]: setInvoices,
  [Types.GET_INVOICE_DATA]: getInvoiceData,
  [Types.SET_INVOICE_DATA]: setInvoiceData,
  [Types.GET_INVOICE_DATA_EDIT]: getInvoiceDataEdit,
  [Types.SET_INVOICE_DATA_EDIT]: setInvoiceDataEdit,
  [Types.UPDATE_DATA]: updateData,
  [Types.HIDE_LOADER]: hideLoader,
  [Types.DELETE_FATURA]: showLoader,
  [Types.DELETE_FATURA_SUCC]: deleteFaturaSuccess,
  [Types.GET_COSTUMERS_CHART]: showLoader,
  [Types.SET_INVOICE_CONSUMERS]: setInvoiceConsumers,
  [Types.SHOW_MODAL]: showModal,
  [Types.HIDE_MODAL]: hideModal,
  [Types.GET_INVOICE_CHART_DATA] : getInvoiceChartData,
  [Types.GET_CHART_DATA_SUCCESS] : getChartDataSuccess
});
