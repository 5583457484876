import React, { Component } from "react";
import { Link } from "react-router-dom";
import NumberFormat from "react-number-format";

/* REDUX */
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import ConsumidoresActions from "../../store/ducks/consumidores";
import ErrorsActions from "../../store/ducks/errors";

/*Styles*/
import { Container, Content, Panel } from "./tables-style";

/*Layout*/
import { Row, Col } from "react-grid-system";
import { Button, Form } from "react-bootstrap";

/*Components*/
import Is from "../../components/Is";
import Header from "../../components/Header";
import TopBar from "../../components/TopBar";
import Loader from "../../components/Loader";

class ConsumidorAdd extends Component {
  state = {
    IdCliente: "",
    SiglaNum: "",
    NomeFantasia: "",
    Area: ""
  };

  componentDidMount() {
    const { id } = this.props.match.params;
    const { clearError } = this.props;
    /* Limpa os Erros */
    clearError();
    this.setState({ IdCliente: id });
  }

  handleInputChange = e => {
    this.setState({ [e.target.name]: e.target.value });
  };

  handleSubmit = e => {
    e.preventDefault();
    const { createConsumidor } = this.props;
    createConsumidor(this.state);
  };

  render() {
    const { errors, consumidores } = this.props;
    const { id } = this.props.match.params;
    return (
      <Is checkRole="administrator">
        <Container>
          {!consumidores.loaded && <Loader />}
          <Header />
          <Content>
            <TopBar title="Cadastro de consumidor" />
            <Panel>
              <div className="container_action">
                <h1>Novo consumidor</h1>
                <Link className="btn btn-success" to={`/consumidores/${id}`}>
                  <i className="fa fa fa-arrow-left" /> &nbsp; Voltar
                </Link>
              </div>
              <div className="panel-container">
                <Form>
                  <Row>
                    <Form.Group
                      as={Col}
                      xs={12}
                      md={6}
                      controlId="formGridSigla"
                    >
                      <Form.Label>Sigla</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Informe a sigla"
                        name="SiglaNum"
                        onChange={this.handleInputChange}
                      />
                      {errors.data.SiglaNum && (
                        <span className="inputError">{errors.data.SiglaNum}</span>
                      )}
                    </Form.Group>
                    <Form.Group
                      as={Col}
                      xs={12}
                      md={6}
                      controlId="formGridName"
                    >
                      <Form.Label>Nome Fantasia</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Informe o nome do consumidor"
                        name="NomeFantasia"
                        onChange={this.handleInputChange}
                      />
                      {errors.data.NomeFantasia && (
                        <span className="inputError">
                          {errors.data.NomeFantasia}
                        </span>
                      )}
                    </Form.Group>
                    <Form.Group as={Col} xs={12} md={6}>
                      <Form.Label>Área (metro quadrado)</Form.Label>
                      <NumberFormat
                        className="form-control"
                        onChange={this.handleInputChange}
                        placeholder="Informe a área"
                        name="Area"
                        fixedDecimalScale={true}
                        decimalScale={2}
                        decimalSeparator={","}
                      />

                      {errors.data.Area && (
                        <span className="inputError">{errors.data.Area}</span>
                      )}
                    </Form.Group>
                  </Row>
                  <Button
                    variant="primary"
                    type="submit"
                    onClick={this.handleSubmit}
                  >
                    Enviar
                  </Button>
                </Form>
              </div>
            </Panel>
          </Content>
        </Container>
      </Is>
    );
  }
}

const mapStateToProps = state => ({
  errors: state.errors,
  consumidores: state.consumidores
});

const mapDispatchToProps = dispatch =>
  bindActionCreators({ ...ConsumidoresActions, ...ErrorsActions }, dispatch);
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ConsumidorAdd);
