import styled from "styled-components";

export const Container = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.5);

  display: flex;
  justify-content: center;
  align-items: center;
`;
export const Content = styled.div`
  background: #fff;
  border-radius: 1px;
  box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.2);
  padding: 40px;
  width: ${props => (props.size === "big" ? 600 : 400)}px;

  h1 {
    font-size: 26px;
    font-weight: 500;
    text-align: center;
    margin: 0 0 10px;
    color: #fff;
  }
  h2 {
    font-size: 1.2rem;
    font-weight: 700;
    margin: 0;
    text-transform: uppercase;
    color: #464457;
  }

  form {
    display: flex;
    flex-direction: column;
    align-items: stretch;

    > span {
      color: #b9bbbe;
      font-size: 14px;
      font-height: 600px;
      margin-top: 15px;
    }

    > input {
      height: 40px;
      padding: 10px;
      border-radius: 3px;
      border: 1px solid rgba(0, 0, 0, 0.3);
      background-color: rgba(0, 0, 0, 0.1);
      color: #f6f6f6;
      margin-top: 8px;
      transiction: border 0.15s ease;
      font-size: 16px;

      &:focus {
        border-color: #f6f6f6;
      }
    }
    > button {
      margin: 20px 0 0;
    }
  }
`;
