import { call, put } from "redux-saga/effects";
import { push } from "connected-react-router";
import { actions as toastrAction } from "react-redux-toastr";
import api from "../../services/api";
import ConsumidoresActions from "../ducks/consumidores";
import ErrorsActions from "../ducks/errors";

export function* getConsumidores({ id, page, filter }) {
  try {
    const paginate = page === undefined ? 1 : page;
    const filtro = filter === undefined ? "" : filter;
    const response = yield call(
      api.get,
      `consumidores/${id}?page=${paginate}&filter=${filtro}`
    );
    if (response.data.hasProject !== undefined && !response.data.hasProject) {
      throw new Error({ code: 400, message: "Projeto inválido" });
    } else {
      yield put(ConsumidoresActions.setConsumidores(response.data.data));
    }
  } catch (err) {
    yield put(
      toastrAction.add({
        type: "error",
        title: "Ops...",
        message: "Não foi possível retornar os consumidores, tente novamente",
        closeOnToastrClick: true,
        position: "top-center",
        options: {
          showCloseButton: true,
          closeOnToastrClick: true,
          progressBar: true,
          transitionOut: "fadeOut"
        }
      })
    );
    yield put(push("/"));
  }
}

export function* createConsumidor({ data }) {
  try {
    if (!data.IdCliente) {
      throw new Error("Cliente inválido");
    }
    const response = yield call(
      api.post,
      `consumidores/${data.IdCliente}`,
      data
    );

    if (response.data.hasProject !== undefined && !response.data.hasProject) {
      throw new Error({ code: 400, message: "Projeto inválido" });
    } else {
      yield put(push(`/consumidores/${data.IdCliente}`));
    }
  } catch (err) { 
    if (err.response.data.message !== undefined) {
      yield put(ErrorsActions.setError(err.response.data.message));
      yield put(ConsumidoresActions.hideLoader());
    }
    yield put(
      toastrAction.add({
        type: "error",
        title: "Ocorreu um erro",
        message: "Verifique os dados inseridos e tente novamente",
        closeOnToastrClick: true,
        position: "top-center",
        options: {
          showCloseButton: true,
          closeOnToastrClick: true,
          progressBar: true,
          transitionOut: "fadeOut"
        }
      })
    );
  }
}

export function* getConsumidor({ id, consumidor }) {
  try {
    const response = yield call(api.get, `consumidores/${id}/${consumidor}`);
    if (response.data.hasProject !== undefined && !response.data.hasProject) {
      throw new Error({ code: 400, message: "Projeto inválido" });
    } else {
      if (!response.data) {
        throw new Error("Nenhum dado encontrado, tente novamente");
      }
      yield put(ConsumidoresActions.setConsumidor(response.data));
    }
  } catch (err) {
    yield put(
      toastrAction.add({
        type: "error",
        title: "Ops...",
        message: "Não foi possível carregar o consumidor, tente novamente",
        closeOnToastrClick: true,
        position: "top-center",
        options: {
          showCloseButton: true,
          closeOnToastrClick: true,
          progressBar: true,
          transitionOut: "fadeOut"
        }
      })
    );
    yield put(push(`/consumidores/${id}`));
  }
}

export function* getConsByCliWpt({ id }) {
  try {
    // yield put(ConsumidoresActions.chgConsByCliWpt())
    const response = yield call(api.get, `consumidores/byCliWPt/${id}`)
    yield put(ConsumidoresActions.setConsByCliWpt(response.data))
  } catch (err) {
    console.log(err)
  }
}

export function* updateConsumidor({ data }) {
  try {
    yield call(api.put, `consumidores/${data.IdCliente}/${data.IdConsumidor}`, data);
    yield put(push(`/consumidores/${data.IdCliente}`));
  } catch (err) {
    if (err.response.data.message !== undefined) {
      yield put(ErrorsActions.setError(err.response.data.message));
      yield put(ConsumidoresActions.hideLoader());
    }
    yield put(
      toastrAction.add({
        type: "error",
        title: "Ops...",
        message: "Não foi possível editar o consumidor, tente novamente",
        closeOnToastrClick: true,
        position: "top-center",
        options: {
          showCloseButton: true,
          closeOnToastrClick: true,
          progressBar: true,
          transitionOut: "fadeOut"
        }
      })
    );
  }
}

export function* deleteConsumidor({ id, client_id }) {
  try {
    const response = yield call(api.delete, `consumidores/${client_id}/${id}`);
    yield put(ConsumidoresActions.deleteSucc(response.data));
    yield put(
      toastrAction.add({
        type: "success",
        title: "Tudo certo!",
        message: "Consumidor removido com sucesso!",
        closeOnToastrClick: true,
        position: "top-center",
        options: {
          showCloseButton: true,
          closeOnToastrClick: true,
          progressBar: true,
          transitionOut: "fadeOut"
        }
      })
    );
  } catch (err) {
    yield put(
      toastrAction.add({
        type: "error",
        title: "Ops...",
        message: "Não foi possível remover o consumidor, tente novamente",
        closeOnToastrClick: true,
        position: "top-center",
        options: {
          showCloseButton: true,
          closeOnToastrClick: true,
          progressBar: true,
          transitionOut: "fadeOut"
        }
      })
    );
  }
}
