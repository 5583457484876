import React, { Component, Fragment } from "react";
import { Link } from "react-router-dom";
import ReactPaginate from "react-paginate";
import moment from "moment";
/* REDUX */
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import InvoicesActions from "../../store/ducks/invoices";
/*Styles*/
import { Container, Content, Panel } from "./styles";
/*Layout*/
import { Table, Button, Form, Row, Col, InputGroup,FormGroup } from "react-bootstrap";
import MaskedFormControl from 'react-bootstrap-maskedinput'
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer
} from "recharts";
/*Components*/
import Header from "../../components/Header";
import TopBar from "../../components/TopBar";
import Loader from "../../components/Loader";
import Modal from "../../components/Modal";

class Faturas extends Component {
  state = {
    filter: "",
    exportData: "",
    exportHeader: "",
    exportFileName: "",
    archived : "",
    filterChart : "",
    filterChartCostumer: "",
    data_inicial : "",
    data_final : "",
    filteredChart: false
  };

  componentDidMount() {
    const { getClients, getInvoices ,hideModal} = this.props;
    hideModal();
    getClients();
    getInvoices();
    
  }

  /* Pega o texto dos inputs */
  handleInputChange = e => {
    console.log(e.target.checked);
    this.setState({ [e.target.name]: e.target.value });
  };
  handleInputCheckChange = e => {
    if(e.target.checked){
      this.setState({ [e.target.name]: e.target.value });
    }else{
      this.setState({ [e.target.name]: "" });
    }
  };
  hidenModal() {
    const { hideModal } = this.props;
    hideModal();
  }
  
  /* Paginação */
  handlePageClick = data => {
    const { filter } = this.state;
    const { getInvoices } = this.props;
    let selected = data.selected;
    selected = selected + 1;
    getInvoices(selected, filter);
  };

  /* Filta os dados */
  handleChartSubmit = () => {
    const { data_inicial,data_final } = this.state;
    const { hideModal,getInvoiceChartData } = this.props;
    if(!data_inicial || ! data_final){
      alert('Informe as datas');  
    }else{
      hideModal();
      this.setState({
        filteredChart: true
      });
      getInvoiceChartData(this.state);
    }
  };

  /* Filta os dados */
  handleChart = () => {
    const { filterChartCostumer } = this.state;
    const { showModal } = this.props;
    if(!filterChartCostumer){
      alert('Selecione um consumidor');  
    }else{
      showModal();
    }
  };

  /* Filta os dados */
  handleFilter = () => {
    const { filter,archived } = this.state;
    const { getInvoices } = this.props;
    getInvoices(1, filter, archived);
  };

  handleInputChartChange= e => {
    this.setState({ [e.target.name]: e.target.value });
    const { getCostumersChart } = this.props;
    getCostumersChart(e.target.value);
  };

  handleDownload = (client_id, invoice_id) => {
    console.log(client_id);
    console.log(invoice_id);
  };

  handleDelete = id => {
    const { deleteFatura } = this.props;
    deleteFatura(id);
  };
  
  render() {
    const styleCheck = {
      float: "left","margin-right":"10px"
    }
    const {filterChart,filteredChart} = this.state;
    const { invoices } = this.props;
    const data = [
      {name: 'Page A', uv: 4000, female: 2400, male: 2400},
      {name: 'Page B', uv: 3000, female: 1398, male: 2210},
      {name: 'Page C', uv: 2000, female: 9800, male: 2290},
      {name: 'Page D', uv: 2780, female: 3908, male: 2000},
      {name: 'Page E', uv: 1890, female: 4800, male: 2181},
      {name: 'Page F', uv: 2390, female: 3800, male: 2500},
      {name: 'Page G', uv: 3490, female: 4300, male: 2100},
];
    
    return (
      <Container>
        {(!invoices.loadedClients || !invoices.loadedInvoices) && <Loader />}
        <Header />
        <Content>
          <TopBar title="Faturas" />
          <Panel>
            <div className="container_action">
              <h1>Lista de faturas</h1>
            </div>
            <div className="panel-container">
              <p className="filter-form-text">Filtros:</p>
              <Row>
                <Col xs={12} md={6} lg={5}>
                  <Form.Control
                    name="filter"
                    as="select"
                    onChange={this.handleInputChange}
                  >
                    <option key="0" value="0">
                      Filtrar Fatura
                    </option>
                    {invoices.clients.map(client => (
                      <option key={client.IdCliente} value={client.IdCliente}>
                        {client.Sigla} - {client.Nome}
                      </option>
                    ))}
                  </Form.Control>
                </Col>
                <Col>
                <Form.Group controlId="formBasicCheckbox">
                  <Form.Check type="checkbox" value='1' onChange={this.handleInputCheckChange} label="Faturas removidas" className="fleft" name="archived" style={{styleCheck}}/>
                  <Button
                    variant="outline-primary"
                    onClick={() => {
                      this.handleFilter();
                    }}
                    className="filterButton"
                  >
                    <i className="fa fa-search" />
                  </Button>
                </Form.Group>
                
                </Col>
               
              </Row>
              <br />
              <Table responsive hover>
                <thead>
                  <tr>
                    <th className="textCenter">Descrição</th>
                    <th className="textCenter">Local</th>
                    <th className="textCenter">Valor da conta</th>
                    <th className="textCenter">Consumo da conta kWh</th>
                    <th className="textCenter">Data Inicial</th>
                    <th className="textCenter">Data Final</th>
                    <th className="textCenter">Alterado</th>
                    <th className="textCenter">Visualizar</th>
                    <th className="textCenter">Alterar status</th>
                  </tr>
                </thead>
                <tbody>
                  {!invoices.invoices.length ? (
                    <tr>
                      <td colSpan="7">Nenhuma fatura cadastrada</td>
                    </tr>
                  ) : (
                    invoices.invoices.map(invoice => (
                      <tr key={invoice.id}>
                        <td className="textCenter">{invoice.descricao}</td>
                        <td className="textCenter">{invoice.client.Nome}</td>
                        <td className="textCenter">R${invoice.valor}</td>
                        <td className="textCenter">{invoice.consumo_conta}</td>
                        <td className="textCenter">
                          {moment(invoice.data_inicial).format("DD/MM/YYYY")}
                        </td>
                        <td className="textCenter">
                          {moment(invoice.data_final).format("DD/MM/YYYY")}
                        </td>
                        <td className="textCenter">
                          {invoice.alterado ? "Sim" : "Não"}
                        </td>
                        <td className="textCenter">
                          <Link
                            className="btn btn-outline-primary"
                            to={`/faturas/${invoice.client.IdCliente}/dados/${
                              invoice.id
                            }`}
                          >
                            <i className="fa fa-edit" />
                          </Link>
                        </td>
                        <td className="textCenter">
                            <Button
                              variant="outline-danger"
                              onClick={() => {
                                this.handleDelete(
                                  invoice.id
                                );
                              }}
                            >
                              <i className="fa fa-warning" />
                            </Button>
                          </td>
                      </tr>
                    ))
                  )}
                </tbody>
              </Table>
              {invoices.invoices.length && (
                <div className="alignCenter">
                  <ReactPaginate
                    previousLabel={"<"}
                    nextLabel={">"}
                    breakLabel={"..."}
                    breakClassName={"break-me"}
                    pageCount={invoices.invoicesInfo.lastPage}
                    marginPagesDisplayed={2}
                    pageRangeDisplayed={2}
                    onPageChange={this.handlePageClick}
                    disableInitialCallback={true}
                    containerClassName={"pagination"}
                    subContainerClassName={"pages pagination"}
                    activeClassName={"active"}
                  />
                </div>
              )}
            </div>
          </Panel>
          {invoices.invoices.length > 1 && (
          <Panel>
            <div className="container_action">
                <h1>Fatura por Consumidor</h1>
            </div>
            <div className="panel-container">
              <p className="filter-form-text">Projeto:</p>
              <Row>
                <Col xs={12} md={6} lg={4}>
                  <Form.Control
                    name="filterChart"
                    as="select"
                    onChange={this.handleInputChartChange}
                  >
                    <option key="0" value="0">
                      Selecione um local
                    </option>
                    {invoices.clients.map(client => (
                      <option key={client.IdCliente} value={client.IdCliente}>
                        {client.Sigla} - {client.Nome}
                      </option>
                    ))}
                  </Form.Control>
                </Col>

                {filterChart && invoices.costumersChart.length && (
                  <Fragment>
                  <Col xs={12} md={6} lg={4}>
                    <Form.Control
                      name="filterChartCostumer"
                      as="select"
                      onChange={this.handleInputChange}
                    >
                      <option key="0" value="0">
                        Selecione um consumidor
                      </option>
                      {invoices.costumersChart.map(consumer => (
                        <option key={consumer.IdConsumidor} value={consumer.IdConsumidor}>
                          {consumer.SiglaNum} - {consumer.NomeFantasia}
                        </option>
                      ))}
                    </Form.Control>
                  </Col>
                  <Col>
                    <Button
                      variant="outline-primary"
                      onClick={() => {
                        this.handleChart();
                      }}
                      className="filterButton"
                    >
                      <i className="fa fa-search" />
                    </Button>
                  </Col>
                  </Fragment>
                )}
              </Row>
            </div>
          </Panel>
        )}
          {filteredChart && (
            <Row >
              <Col lg={12} sm={12} xs={12}>
                <Panel >
                  <div className="container_action">
                    <h1>Consumo - Consumidor / Fatura</h1>
                  </div>
                  {!invoices.chartLoaded && (
                    <div className="panel-container textCenter loader-dashboard">
                      <i className="fa fa-circle-o-notch fa-spin fa-3x fa-fw" />
                      <p>Carregando...</p>
                    </div>
                  )}
                  {invoices.chartLoaded && !invoices.chartData.length && (
                    <div className="panel-container textCenter loader-dashboard">
                      <h2 className="title-panel-dash">
                        Dados não cadastrados para o período
                      </h2>
                    </div>
                  )}
                  {invoices.chartLoaded && invoices.chartData.length && (
                  <ResponsiveContainer width="99%" aspect={4.0 / 2.0}>
                    <BarChart width={600} height={300} data={invoices.chartData}
                            margin={{top: 20, right: 30, left: 20, bottom: 5}}>
                      <CartesianGrid strokeDasharray="3 3"/>
                      <XAxis dataKey="name"/>
                      <YAxis/>
                      <Tooltip
                        formatter={value =>
                          new Intl.NumberFormat("pt-BR").format(value)
                        }
                      />
                      <Legend />
                      <Bar dataKey="FORA DE PONTA" stackId="a" fill="#c3c3c3" />
                      <Bar dataKey="PONTA" stackId="a" fill="#801300" />
                    </BarChart>
                  </ResponsiveContainer>
                )}
                </Panel>
              </Col>
            </Row>
          )}
          
          <div style={{"margin-bottom": "80px"}}></div>
        </Content>
        {invoices.modal && (
          <Modal>
            <Row>
              <Col>
                <h2 className="textCenter">Informações para Relatório</h2>
              </Col>
            </Row>
            <Row>
              <Col>
                <FormGroup>
                  <MaskedFormControl type='text' name='data_inicial' mask='11/11/1111' 
                    onChange={this.handleInputChange} 
                    placeholder="Data inicial"/>
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col>
                <FormGroup>
                  <MaskedFormControl type='text' name='data_final' mask='11/11/1111' 
                    onChange={this.handleInputChange} placeholder="Data final"/>
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col className="textCenter">
                <br />
                <Button
                  variant="outline-primary"
                  type="button"
                  onClick={() => {
                    this.handleChartSubmit();
                  }}
                >
                <span>Enviar</span>
                  
                </Button>
                &nbsp;&nbsp;
                <Button
                  variant="outline-danger"
                  type="button"
                  onClick={() => {
                    this.hidenModal();
                  }}
                >
                  Fechar
                </Button>
              </Col>
            </Row>
          </Modal>
        )}
      </Container>
    );
  }
}

const mapStateToProps = state => ({
  invoices: state.invoices
});
const mapDispatchToProps = dispatch =>
  bindActionCreators({ ...InvoicesActions }, dispatch);
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Faturas);
