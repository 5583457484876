import styled from "styled-components";

export const Container = styled.div`
  display: flex;

  height: 60px;

  -webkit-box-pack: justify;
  -webkit-box-align: center;

  align-items: center;

  background: rgb(40, 36, 52);

  padding: 0px;

  border-bottom: 1px solid rgb(59, 54, 73);

  h1 {
    font-size: 24px;
    margin-top: 10px;
  }

  h4 {
    font-size: 12px;
    margin-top: 6px;
    margin-right: 24px;
  }

  .jflex-start {
    display: flex;
    padding: 30px;
    height: inherit;
    width: 50%;
    background: transparent;
    justify-content: flex-start;
    align-items: center;
  }

  .jflex-end {
    display: flex;
    padding: 30px;
    height: inherit;
    width: 50%;
    background: transparent;
    justify-content: flex-end;
    align-items: center;
  }

  i {
    vertical-align: inherit;
  }
`;

export const IconMenu = styled.i`
  margin-right: 20px;
  cursor: pointer;
`;

export const IconExit = styled.i`
  cursor: pointer;
  padding: 0;
  font-size: 24px;
`;

export const Logout = styled.button`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  height: 50px;
  color: #fff;
  font-weight: bold;
  transition: all 0.2s;
  background: transparent;
  border: 0px;
  font-weight: 300;

  i {
    font-size: 24px;
    color: #fff;
    margin: 0px 0px 3px;
  }
`;