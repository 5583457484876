import React, { Component } from "react";
import { Link } from "react-router-dom";
import ReactPaginate from "react-paginate";

/* REDUX */
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import PontosActions from "../../store/ducks/pontos";

/*Styles*/
import { Container, Content, Panel } from "./styles";

/*Layout*/
import { Table, Button, Form, Row, Col, InputGroup } from "react-bootstrap";

/*Components*/
import Header from "../../components/Header";
import TopBar from "../../components/TopBar";
import Loader from "../../components/Loader";

class Pontos extends Component {
  state = {
    filter: ""
  };

  componentDidMount() {
    const { id } = this.props.match.params;
    const { getPontos } = this.props;
    getPontos(id);
  }

  /* Pega o texto dos inputs */
  handleInputChange = e => {
    this.setState({ [e.target.name]: e.target.value });
  };

  /* Filta os dados */
  handleFilter = () => {
    const { id } = this.props.match.params;
    const { filter } = this.state;
    const { getPontos } = this.props;
    getPontos(id, 1, filter);
  };

  /* Paginação */
  handlePageClick = data => {
    const { id } = this.props.match.params;
    const { getPontos } = this.props;
    const { filter } = this.state;
    let selected = data.selected;
    selected = selected + 1;
    getPontos(id, selected, filter);
  };

  handleDelete = id => {
    const { deletePonto } = this.props;
    deletePonto(id);
  };

  render() {
    const { pontos } = this.props;
    const { id } = this.props.match.params;
    return (
      <Container>
        {!pontos.loaded && <Loader />}
        <Header />
        <Content>
          <TopBar title="Projetos - Pontos" />
          <Panel>
            <div className="container_action">
              <h1>Lista de pontos</h1>
              <div>
                <Link
                  to={`/projetos/${id}/pontos/cadastro`}
                  className="btn btn-outline-primary mr-10"
                >
                  <i className="fa fa-plus" /> &nbsp; Novo Ponto
                </Link>
                <Link className="btn btn-success " to="/projetos">
                  <i className="fa fa fa-arrow-left" /> &nbsp; Voltar
                </Link>
              </div>
            </div>
            <div className="panel-container">
              <p className="filter-form-text">Filtros:</p>
              <Row>
                <Col xs={12} md={4} lg={4}>
                  <InputGroup className="mb-3">
                    <Form.Control
                      type="text"
                      placeholder="TAG"
                      name="filter"
                      onChange={this.handleInputChange}
                    />
                    <Button
                      variant="outline-primary"
                      onClick={() => {
                        this.handleFilter();
                      }}
                      className="filterButton no-margin"
                    >
                      <i className="fa fa-search" />
                    </Button>
                  </InputGroup>
                </Col>
              </Row>
              <br />
              <Table responsive hover>
                <thead>
                  <tr>
                    <th className="textCenter">#</th>
                    <th className="textCenter">Coletora</th>
                    <th className="textCenter">Tag</th>
                    <th className="textCenter">Consumidor</th>
                    <th className="textCenter">Editar</th>
                    <th className="textCenter">Remover</th>
                  </tr>
                </thead>
                <tbody>
                  {!pontos.data.length ? (
                    <tr>
                      <td colSpan="7">Nenhum ponto encontrado</td>
                    </tr>
                  ) : (
                    pontos.data.map(point => (
                      <tr key={point.IdPonto}>
                        <td className="textCenter">{point.IdPonto}</td>
                        <td className="textCenter">
                          #{point.coletor.IdColetor}
                        </td>
                        <td className="textCenter">{point.GTag}</td>
                        <td className="textCenter">
                          {point.consumer
                            ? point.consumer.SiglaNum
                            : "Não cadastrado"}
                        </td>
                        <td className="textCenter">
                          <Link
                            className="btn btn-outline-primary"
                            to={`/projetos/${point.client.IdCliente}/pontos/editar/${
                              point.IdPonto
                            }`}
                          >
                            <i className="fa fa-edit" />
                          </Link>
                        </td>
                        <td className="textCenter">
                          <Button
                            variant="outline-danger"
                            onClick={() => {
                              this.handleDelete(point.IdPonto);
                            }}
                          >
                            <i className="fa fa-trash-o" />
                          </Button>
                        </td>
                      </tr>
                    ))
                  )}
                </tbody>
              </Table>
              {pontos.data.length && (
                <div className="alignCenter">
                  <ReactPaginate
                    previousLabel={"<"}
                    nextLabel={">"}
                    breakLabel={"..."}
                    breakClassName={"break-me"}
                    pageCount={pontos.pontosInfo.lastPage}
                    marginPagesDisplayed={2}
                    pageRangeDisplayed={2}
                    onPageChange={this.handlePageClick}
                    disableInitialCallback={true}
                    containerClassName={"pagination"}
                    subContainerClassName={"pages pagination"}
                    activeClassName={"active"}
                  />
                </div>
              )}
            </div>
          </Panel>
        </Content>
      </Container>
    );
  }
}

const mapStateToProps = state => ({
  pontos: state.pontos
});
const mapDispatchToProps = dispatch =>
  bindActionCreators({ ...PontosActions }, dispatch);
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Pontos);
