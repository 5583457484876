import { call, put, select } from "redux-saga/effects";
import { push } from "connected-react-router";
import { actions as toastrAction } from "react-redux-toastr";
import api from "../../services/api";
import ProjectsActions from "../ducks/project";
import ErrorsActions from "../ducks/errors";

export function* getProjects({ page, filter }) {
  try {
    const paginate = page === undefined ? 1 : page;
    const filtro = filter === undefined ? "" : filter;
    const response = yield call(
      api.get,
      "clients?page=" + paginate + "&filter=" + filtro
    );
    yield put(ProjectsActions.getProjectsSuccess(response.data));
  } catch (err) {
    yield put(
      toastrAction.add({
        type: "error",
        title: "Ops...",
        message: "Não foi possível retornar os projetos, tente novamente",
        closeOnToastrClick: true,
        position: "top-center",
        options: {
          showCloseButton: true,
          closeOnToastrClick: true,
          progressBar: true,
          transitionOut: "fadeOut"
        }
      })
    );
  }
}

export function* createProject({ data }) {
  try {
    yield call(api.post, "clients", data);
    yield put(push("/projetos"));
  } catch (err) {
    if (err.response.data.message !== undefined) {
      yield put(ErrorsActions.setError(err.response.data.message));
    }
    yield put(
      toastrAction.add({
        type: "error",
        title: "Ocorreu um erro",
        message: "Verifique os dados inseridos e tente novamente",
        closeOnToastrClick: true,
        position: "top-center",
        options: {
          showCloseButton: true,
          closeOnToastrClick: true,
          progressBar: true,
          transitionOut: "fadeOut"
        }
      })
    );
  }
}
export function* getProject({ id }) {
  try {
    const response = yield call(api.get, `clients/${id}`);
    yield put(ProjectsActions.setProject(response.data));
  } catch (err) {
    yield put(
      toastrAction.add({
        type: "error",
        title: "Ops...",
        message: "Não foi possível carregar os dados, tente novamente",
        closeOnToastrClick: true,
        position: "top-center",
        options: {
          showCloseButton: true,
          closeOnToastrClick: true,
          progressBar: true,
          transitionOut: "fadeOut"
        }
      })
    );
    yield put(push("/projetos"));
  }
}

export function* getProjectPoints({ id }) {
  try {
    const response = yield call(api.get, `clients/${id}`);
    yield put(ProjectsActions.setProject(response.data));
    const response2 = yield call(api.get, `points/${id}`);
    yield put(ProjectsActions.getProjectsPointsSuccess(response2.data));
    yield put(push("/projetos/pontos"));
  } catch (err) {
    console.log(err);
    yield put(
      toastrAction.add({
        type: "error",
        title: "Ops...",
        message: "Não foi possível carregar os dados, tente novamente",
        closeOnToastrClick: true,
        position: "top-center",
        options: {
          showCloseButton: true,
          closeOnToastrClick: true,
          progressBar: true,
          transitionOut: "fadeOut"
        }
      })
    );
    yield put(push("/projetos"));
  }
}

export function* updateProject({ data }) {
  try {
    if (!data.IdCliente) {
      throw new Error("Código invalido, contate a equipe de suporte");
    }
    yield call(api.put, `clients/${data.IdCliente}`, data);
    yield put(push("/projetos"));
  } catch (err) {
    if (err.response.data.message !== undefined) {
      yield put(ErrorsActions.setError(err.response.data.message));
      yield put(ProjectsActions.hideLoader());
    }
    yield put(
      toastrAction.add({
        type: "error",
        title: "Ops...",
        message: "Não foi possível editar o projeto, tente novamente",
        closeOnToastrClick: true,
        position: "top-center",
        options: {
          showCloseButton: true,
          closeOnToastrClick: true,
          progressBar: true,
          transitionOut: "fadeOut"
        }
      })
    );
  }
}
export function* deleteProject({ id }) {
  try {
    const response = yield call(api.delete, `clients/${id}`);
    yield put(ProjectsActions.deleteProjectSuccess(response.data.client));
  } catch (err) {
    yield put(
      toastrAction.add({
        type: "error",
        title: "Ops...",
        message: "Não foi possível editar o projeto, tente novamente",
        closeOnToastrClick: true,
        position: "top-center",
        options: {
          showCloseButton: true,
          closeOnToastrClick: true,
          progressBar: true,
          transitionOut: "fadeOut"
        }
      })
    );
    yield put(push("/projetos"));
  }
}

export function* createPoint({ data }) {
  try {
    console.log("ENTROU SAGA");
    console.log(data);
    yield call(api.post, "points", data);
    yield put(push("/projetos/pontos"));
  } catch (err) {
    if (err.response.data.message !== undefined) {
      yield put(ErrorsActions.setError(err.response.data.message));
    }
    yield put(
      toastrAction.add({
        type: "error",
        title: "Ocorreu um erro",
        message: "Verifique os dados inseridos e tente novamente",
        closeOnToastrClick: true,
        position: "top-center",
        options: {
          showCloseButton: true,
          closeOnToastrClick: true,
          progressBar: true,
          transitionOut: "fadeOut"
        }
      })
    );
  }
}
export function* getPoint({ id }) {
  try {
    const currentProject = yield select(state => state.project.currentProject);
    const response = yield call(api.get, `points/${id}/${currentProject.id}`);
    yield put(ProjectsActions.setPoint(response.data));
    yield put(push("/projetos/pontos/editar"));
  } catch (err) {
    yield put(
      toastrAction.add({
        type: "error",
        title: "Ops...",
        message: "Não foi possível carregar os dados, tente novamente",
        closeOnToastrClick: true,
        position: "top-center",
        options: {
          showCloseButton: true,
          closeOnToastrClick: true,
          progressBar: true,
          transitionOut: "fadeOut"
        }
      })
    );
    yield put(push("/projetos/pontos"));
  }
}
export function* updatePoint({ data }) {
  try {
    console.log("UPDATE_DATA");
    console.log(data);
    const currentPoint = yield select(state => state.project.currentPoint);
    yield call(
      api.put,
      `points/${currentPoint.point_id}/${currentPoint.client_id}`,
      data
    );
    yield put(push("/projetos/pontos"));
  } catch (err) {
    if (err.response.data.message !== undefined) {
      yield put(ErrorsActions.setError(err.response.data.message));
    }
    yield put(
      toastrAction.add({
        type: "error",
        title: "Ops...",
        message: "Não foi possível editar o ponto, tente novamente",
        closeOnToastrClick: true,
        position: "top-center",
        options: {
          showCloseButton: true,
          closeOnToastrClick: true,
          progressBar: true,
          transitionOut: "fadeOut"
        }
      })
    );
  }
}
export function* deletePoint({ id }) {
  try {
    yield call(api.delete, `points/${id}`);
    //yield put(ProjectsActions.deleteProjectSuccess(response.data.client));
  } catch (err) {
    yield put(
      toastrAction.add({
        type: "error",
        title: "Ops...",
        message: "Não foi possível remover o ponto, tente novamente",
        closeOnToastrClick: true,
        position: "top-center",
        options: {
          showCloseButton: true,
          closeOnToastrClick: true,
          progressBar: true,
          transitionOut: "fadeOut"
        }
      })
    );
    yield put(push("/projetos"));
  }
}
