import React, { Component } from "react";
import { Link } from "react-router-dom";
import NumberFormat from "react-number-format";
/* REDUX */
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import ConsumidoresActions from "../../store/ducks/consumidores";
import ErrorsActions from "../../store/ducks/errors";
/*Styles*/
import { Container, Content, Panel } from "./tables-style";
/*Layout*/
import { Row, Col } from "react-grid-system";
import { Button, Form } from "react-bootstrap";

/*Components*/
import Header from "../../components/Header";
import TopBar from "../../components/TopBar";
import Loader from "../../components/Loader";

class ConsumidorEdit extends Component {
  state = {
    IdConsumidor: this.props.consumidores.currentConsumidor.IdConsumidor || "",
    IdCliente: this.props.consumidores.currentConsumidor.IdCliente || "",
    SiglaNum: this.props.consumidores.currentConsumidor.SiglaNum || "",
    NomeFantasia:
      this.props.consumidores.currentConsumidor.NomeFantasia || "",
    Area: this.props.consumidores.currentConsumidor.Area || ""
  };

  componentDidMount() {
    const { id } = this.props.match.params;
    const { client_id } = this.props.match.params;
    const { clearError, getConsumidor } = this.props;
    /* Limpa os Erros */
    clearError();
    this.setState({
      IdCliente: client_id,
      IdConsumidor: id
    });
    /* Busca o consumidor */
    getConsumidor(client_id, id);
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.consumidores.currentConsumidor.IdConsumidor !==
      this.props.consumidores.currentConsumidor.IdConsumidor
    ) {
      this.setState({
        IdConsumidor: this.props.consumidores.currentConsumidor.IdConsumidor || "",
        IdCliente: this.props.consumidores.currentConsumidor.IdCliente || "",
        SiglaNum: this.props.consumidores.currentConsumidor.SiglaNum || "",
        NomeFantasia:
          this.props.consumidores.currentConsumidor.NomeFantasia || "",
        Area: this.props.consumidores.currentConsumidor.Area || ""
      });
    }
  }

  handleInputChange = e => {
    console.log("AQi");
    if(e.target.value == "0"){
      this.setState({ [e.target.name]: "" });
      console.log(e.target.name);
    }else{
      this.setState({ [e.target.name]: e.target.value });
    }
  };

  handleSubmit = e => {
    e.preventDefault();
    const { updateConsumidor } = this.props;
    updateConsumidor(this.state);
  };

  render() {
    const { errors, consumidores } = this.props;
    const { client_id } = this.props.match.params;
    return (
      <Container>
        {!consumidores.loaded && <Loader />}
        <Header />
        <Content>
          <TopBar title="Edição de consumidor" />
          <Panel>
            <div className="container_action">
              <h1>Editar consumidor</h1>
              <Link
                className="btn btn-success"
                to={`/consumidores/${client_id}`}
              >
                <i className="fa fa fa-arrow-left" /> &nbsp; Voltar
              </Link>
            </div>
            <div className="panel-container">
              <Form>
                <Row>
                  <Form.Group as={Col} xs={12} controlId="formGridSigla">
                    <Form.Label>Sigla</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Informe a sigla"
                      name="SiglaNum"
                      onChange={this.handleInputChange}
                      value={this.state.SiglaNum}
                    />
                    {errors.data.SiglaNum && (
                      <span className="inputError">{errors.data.SiglaNum}</span>
                    )}
                  </Form.Group>
                  <Form.Group as={Col} xs={12} md={6} controlId="formGridName">
                    <Form.Label>Nome Fantasia</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Informe o nome do consumidor"
                      name="NomeFantasia"
                      onChange={this.handleInputChange}
                      value={this.state.NomeFantasia}
                    />
                    {errors.data.NomeFantasia && (
                      <span className="inputError">
                        {errors.data.NomeFantasia}
                      </span>
                    )}
                  </Form.Group>
                  <Form.Group as={Col} xs={12} md={6}>
                    <Form.Label>Área (metro quadrado)</Form.Label>
                    <NumberFormat
                      className="form-control"
                      onChange={this.handleInputChange}
                      placeholder="Informe a área"
                      name="Area"
                      fixedDecimalScale={true}
                      decimalScale={2}
                      decimalSeparator={","}
                      value={this.state.Area}
                    />

                    {errors.data.Area && (
                      <span className="inputError">{errors.data.Area}</span>
                    )}
                  </Form.Group>
                </Row>
                <Button
                  variant="primary"
                  type="submit"
                  onClick={this.handleSubmit}
                >
                  Enviar
                </Button>
              </Form>
            </div>
          </Panel>
        </Content>
      </Container>
    );
  }
}

const mapStateToProps = state => ({
  errors: state.errors,
  consumidores: state.consumidores
});

const mapDispatchToProps = dispatch =>
  bindActionCreators({ ...ConsumidoresActions, ...ErrorsActions }, dispatch);
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ConsumidorEdit);
