import React from "react";
import PropTypes from "prop-types";
import { Route, Redirect } from "react-router-dom";
import store from "../store";
import SignIn from "../pages/Auth/Login";
import Dashboard from "../pages/Dashboard";

/*
const GuestRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={props =>
      !store.getState().auth.signedIn ? (
        <Component {...props} />
      ) : (
        <Redirect to={{ pathname: "/", state: { from: props.location } }} />
      )
    }
  />
);

const GuestRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={props =>
      !store.getState().auth.signedIn ? (
        <Redirect to={{ pathname: "/login", state: { from: props.location } }} />
      ) : (
        <Redirect to={{ pathname: "/dashboard", state: { from: props.location } }} />
      )
    }
  />
);
*/

const GuestRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={props => !store.getState().auth.signedIn ? ( <SignIn {...props} />  ) : ( <Dashboard {...props} />  )}
  />
);

GuestRoute.propTypes = {
  component: PropTypes.shape().isRequired
};
export default GuestRoute;
