import React, { Component } from "react";
import { Link } from "react-router-dom";

/* REDUX */
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import ColetoresActions from "../../store/ducks/coletores";
import ErrorsActions from "../../store/ducks/errors";

/*Styles*/
import { Container, Content, Panel } from "./styles";

/*Layout*/
import { Row, Col } from "react-grid-system";

/*Components*/
import Header from "../../components/Header";
import TopBar from "../../components/TopBar";
import { Button, Form } from "react-bootstrap";

class ColetoreAdd extends Component {
  state = {
    GTag: "",
    IdTipoCol: "",
    Nome: "",
    NumSerie: "",
    Localizacao: ""
  };

  componentDidMount() {
    const { id } = this.props.match.params;
    const { clearError, getTypes } = this.props;
    /* Tipos de Coletores */
    getTypes();
    /* Limpa os Erros */
    clearError();
    this.setState({ IdCliente: id });
  }

  handleInputChange = e => {
    this.setState({ [e.target.name]: e.target.value });
  };

  handleSubmit = e => {
    e.preventDefault();
    const { createColetor } = this.props;
    console.log(this.state);
    createColetor(this.state);
  };

  render() {
    const { errors, coletores } = this.props;
    const { id } = this.props.match.params;
    return (
      <Container>
        <Header />
        <Content>
          <TopBar title="Cadastro de coletores" />
          <Panel>
            <div className="container_action">
              <h1>Novo coletor</h1>
              <Link className="btn btn-success" to={`/projeto/${id}/coletores`}>
                <i className="fa fa fa-arrow-left" /> &nbsp; Voltar
              </Link>
            </div>
            <div className="panel-container">
              <Form>
                <Row>
                  <Form.Group
                    as={Col}
                    xs={12}
                    md={6}
                    lg={6}
                    controlId="formGridSigla"
                  >
                    <Form.Label>GTag</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Informe a tag"
                      name="GTag"
                      onChange={this.handleInputChange}
                    />
                    {errors.data.GTag && (
                      <span className="inputError">{errors.data.GTag}</span>
                    )}
                  </Form.Group>
                  <Form.Group
                    as={Col}
                    xs={12}
                    md={6}
                    lg={6}
                    controlId="formGridName"
                  >
                    <Form.Label>Nome</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Informe o nome do coletor"
                      name="Nome"
                      onChange={this.handleInputChange}
                    />
                    {errors.data.Nome && (
                      <span className="inputError">{errors.data.Nome}</span>
                    )}
                  </Form.Group>
                </Row>
                <Row>
                  <Form.Group
                    as={Col}
                    xs={12}
                    md={12}
                    lg={12}
                    controlId="formGridLocalizacao"
                  >
                    <Form.Label>Localização</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Informe a Localização"
                      name="Localizacao"
                      onChange={this.handleInputChange}
                    />
                    {errors.data.Localizacao && (
                      <span className="inputError">
                        {errors.data.Localizacao}
                      </span>
                    )}
                  </Form.Group>
                </Row>
                <Row>
                  <Form.Group
                    as={Col}
                    xs={12}
                    md={6}
                    lg={6}
                    controlId="formGridCnpj"
                  >
                    <Form.Label>Número de série</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Informe o número de série"
                      name="NumSerie"
                      onChange={this.handleInputChange}
                    />
                    {errors.data.NumSerie && (
                      <span className="inputError">{errors.data.NumSerie}</span>
                    )}
                  </Form.Group>

                  <Form.Group
                    as={Col}
                    xs={12}
                    md={6}
                    lg={6}
                    controlId="formGridPassword"
                  >
                    <Form.Label>Tipo de coletor</Form.Label>
                    <Form.Control
                      name="IdTipoCol"
                      as="select"
                      onChange={this.handleInputChange}
                    >
                      <option key="0" value="0">
                        Selecione um tipo
                      </option>
                      {coletores.tipos.map(type => (
                        <option key={type.IdTipoCol} value={type.IdTipoCol}>
                          {type.Nome}
                        </option>
                      ))}
                    </Form.Control>
                    {errors.data.IdTipoCol && (
                      <span className="inputError">
                        {errors.data.IdTipoCol}
                      </span>
                    )}
                  </Form.Group>
                </Row>
                <Button
                  variant="primary"
                  type="submit"
                  onClick={this.handleSubmit}
                >
                  Enviar
                </Button>
              </Form>
            </div>
          </Panel>
        </Content>
      </Container>
    );
  }
}

const mapStateToProps = state => ({
  projects: state.project,
  errors: state.errors,
  coletores: state.coletores
});

const mapDispatchToProps = dispatch =>
  bindActionCreators({ ...ColetoresActions, ...ErrorsActions }, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(ColetoreAdd);
