import React, { Component } from "react";
import { Link } from "react-router-dom";
import ReactPaginate from "react-paginate";

/* REDUX */
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import EquipamentosActions from "../../store/ducks/equipamentos";

/*Styles*/
import { Container, Content, Panel } from "./tables-style.js";

/*Layout*/
import { Table, Button, Form, Row, Col, InputGroup } from "react-bootstrap";

/*Components*/
import Is from "../../components/Is";
import Header from "../../components/Header";
import TopBar from "../../components/TopBar";
import Loader from "../../components/Loader";

class Equipamentos extends Component {
  state = {
    filter: ""
  };

  componentDidMount() {
    const { getEquipamentos } = this.props;
    getEquipamentos();
  }

  /* Pega o texto dos inputs */
  handleInputChange = e => {
    this.setState({ [e.target.name]: e.target.value });
  };

  /* Filta os dados */
  handleFilter = () => {
    const { filter } = this.state;
    const { getEquipamentos } = this.props;
    getEquipamentos(1, filter);
  };

  /* Paginação */
  handlePageClick = data => {
    const { getEquipamentos } = this.props;
    const { filter } = this.state;
    let selected = data.selected;
    selected = selected + 1;
    getEquipamentos(selected, filter);
  };

  handleDelete = (id) => {
    const { deleteEquipamento } = this.props;
    deleteEquipamento(id);
  };

  render() {
    const { equipamentos } = this.props;
    return (
      <Container>
        {!equipamentos.loaded && <Loader />}
        <Header />
        <Content>
          <TopBar title="Equipamentos" />
          <Panel>
            <div className="container_action">
              <h1>Lista de equipamentos</h1>
              <div>
                <Is checkRole="administrator">
                  <Link
                    className="btn btn-outline-primary margin-r-10"
                    to={`/equipamentos/cadastro`}
                  >
                    <i className="fa fa-plus" /> &nbsp; Novo Equipamento
                  </Link>
                </Is>
                <Link className="btn btn-success" to="/">
                  <i className="fa fa fa-arrow-left" /> &nbsp; Voltar
                </Link>
              </div>
            </div>

            <div className="panel-container">
              <p className="filter-form-text">Filtros:</p>
              <Row>
                <Col xs={12} md={12} lg={4}>
                  <InputGroup className="mb-3">
                    <Form.Control
                      type="text"
                      placeholder="Sigla"
                      name="filter"
                      onChange={this.handleInputChange}
                    />
                    <Button
                      variant="outline-primary"
                      onClick={() => {
                        this.handleFilter();
                      }}
                      className="filterButton no-margin"
                    >
                      <i className="fa fa-search" />
                    </Button>
                  </InputGroup>
                </Col>
              </Row>
              <br />
              <Table responsive hover>
                <thead>
                  <tr>
                    <th className="textCenter">Sigla</th>
                    <th className="textCenter">Numero de Série</th>
                    <th className="textCenter">Editar</th>
                    <Is checkRole="administrator">
                      <th className="textCenter">Remover</th>
                    </Is>
                  </tr>
                </thead>
                <tbody>
                  {equipamentos.loaded && !equipamentos.data.length ? (
                    <tr>
                      <td colSpan="5">Nenhum equipamento encontrado</td>
                    </tr>
                  ) : (
                    equipamentos.data.map(equipamento => (
                      <tr key={equipamento.IdEquip}>
                        <td className="textCenter">{equipamento.Sigla}</td>
                        <td className="textCenter">
                          {equipamento.NumSerie}
                        </td>
                        <td className="textCenter">
                          <Link
                            className="btn btn-outline-primary"
                            to={`/equipamentos/${equipamento.IdEquip}/editar`}
                          >
                            <i className="fa fa-edit" />
                          </Link>
                        </td>
                        <Is checkRole="administrator">
                          <td className="textCenter">
                            <Button
                              variant="outline-danger"
                              onClick={() => {
                                this.handleDelete(
                                  equipamento.IdEquip
                                );
                              }}
                            >
                              <i className="fa fa-warning" />
                            </Button>
                          </td>
                        </Is>
                      </tr>
                    ))
                  )}
                </tbody>
              </Table>
              <div className="alignCenter">
                <ReactPaginate
                  previousLabel={"<"}
                  nextLabel={">"}
                  breakLabel={"..."}
                  breakClassName={"break-me"}
                  pageCount={equipamentos.equipamentosInfo.lastPage}
                  marginPagesDisplayed={2}
                  pageRangeDisplayed={2}
                  onPageChange={this.handlePageClick}
                  disableInitialCallback={true}
                  containerClassName={"pagination"}
                  subContainerClassName={"pages pagination"}
                  activeClassName={"active"}
                />
              </div>
            </div>
          </Panel>
        </Content>
      </Container>
    );
  }
}
const mapStateToProps = state => ({
  equipamentos: state.equipamentos
});
const mapDispatchToProps = dispatch =>
  bindActionCreators({ ...EquipamentosActions }, dispatch);
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Equipamentos);
