import { call, put } from "redux-saga/effects";
import { push } from "connected-react-router";
import { actions as toastrAction } from "react-redux-toastr";
import api from "../../services/api";
import InvoicesActions from "../ducks/invoices";
import ErrorsActions from "../ducks/errors";

export function* getInvoices({ page, filter, archived }) {
  try {
    const paginate = page === undefined ? 1 : page;
    const filtro = filter === undefined ? "" : filter;
    const arquivado = archived === undefined ? "" : archived;
    const response = yield call(
      api.get,
      `invoices?page=${paginate}&filter=${filtro}&archived=${arquivado}`
    );

    yield put(InvoicesActions.setInvoices(response.data));
  } catch (err) {
    yield put(
      toastrAction.add({
        type: "error",
        title: "Ops...",
        message: "Não foi possível retornar as faturas, tente novamente",
        closeOnToastrClick: true,
        position: "top-center",
        options: {
          showCloseButton: true,
          closeOnToastrClick: true,
          progressBar: true,
          transitionOut: "fadeOut"
        }
      })
    );
    yield put(push("/"));
  }
}

export function* getInvoiceData({ client_id, invoice_id }) {
  try {
    const response = yield call(
      api.get,
      `invoice-data/${client_id}/${invoice_id}`
    );
    yield put(InvoicesActions.setInvoiceData(response.data));
  } catch (err) {
    yield put(
      toastrAction.add({
        type: "error",
        title: "Ops...",
        message: "Não foi possível retornar os dados, tente novamente",
        closeOnToastrClick: true,
        position: "top-center",
        options: {
          showCloseButton: true,
          closeOnToastrClick: true,
          progressBar: true,
          transitionOut: "fadeOut"
        }
      })
    );
    yield put(push("/faturas"));
  }
}

export function* getHistory({ client_id, invoice_id }) {
  try {
    const response = yield call(
      api.get,
      `invoice-history/${client_id}/${invoice_id}`
    );
    yield put(InvoicesActions.setInvoiceHistory(response.data));
  } catch (err) {
    yield put(
      toastrAction.add({
        type: "error",
        title: "Ops...",
        message: "Não foi possível retornar o histórico, tente novamente",
        closeOnToastrClick: true,
        position: "top-center",
        options: {
          showCloseButton: true,
          closeOnToastrClick: true,
          progressBar: true,
          transitionOut: "fadeOut"
        }
      })
    );
    yield put(push("/faturas"));
  }
}

export function* getInvoiceDataEdit({ client_id, invoice_id, id }) {
  try {
    const response = yield call(
      api.get,
      `invoice-data/${client_id}/${invoice_id}/edit/${id}`
    );
    yield put(InvoicesActions.setInvoiceDataEdit(response.data));
  } catch (err) {
    yield put(
      toastrAction.add({
        type: "error",
        title: "Ops...",
        message: "Não foi possível carregar os projetos",
        closeOnToastrClick: true,
        position: "top-center",
        options: {
          showCloseButton: true,
          closeOnToastrClick: true,
          progressBar: true,
          transitionOut: "fadeOut"
        }
      })
    );
    yield put(push(`/faturas/${client_id}/dados/${invoice_id}`));
  }
}

export function* getClients() {
  try {
    const response = yield call(api.get, `invoice/list-projects`);

    yield put(InvoicesActions.setClients(response.data));
  } catch (err) {
    yield put(
      toastrAction.add({
        type: "error",
        title: "Ops...",
        message: "Não foi possível carregar os projetos",
        closeOnToastrClick: true,
        position: "top-center",
        options: {
          showCloseButton: true,
          closeOnToastrClick: true,
          progressBar: true,
          transitionOut: "fadeOut"
        }
      })
    );
    yield put(push(`/`));
  }
}
export function* updateData({ data }) {
  try {
    yield call(
      api.put,
      `invoice-data/${data.client_id}/${data.invoice_id}/edit/${data.id}`,
      data
    );
    yield put(push(`/faturas/${data.client_id}/dados/${data.invoice_id}`));
  } catch (err) {
    if (err.response.data.message !== undefined) {
      yield put(ErrorsActions.setError(err.response.data.message));
      yield put(InvoicesActions.hideLoader());
    }
    yield put(
      toastrAction.add({
        type: "error",
        title: "Ops...",
        message: "Não foi possível editar os dados, tente novamente",
        closeOnToastrClick: true,
        position: "top-center",
        options: {
          showCloseButton: true,
          closeOnToastrClick: true,
          progressBar: true,
          transitionOut: "fadeOut"
        }
      })
    );
  }
}
export function* deleteFatura({ id }) {
  try {
    const response = yield call(api.delete, `invoices/${id}`);
    yield put(InvoicesActions.deleteFaturaSucc(response.data));
    window.location.reload();
  } catch (err) {
    console.log(err)
    yield put(
      toastrAction.add({
        type: "error",
        title: "Ops...",
        message: "Não foi possível alterar a fatura, tente novamente",
        closeOnToastrClick: true,
        position: "top-center",
        options: {
          showCloseButton: true,
          closeOnToastrClick: true,
          progressBar: true,
          transitionOut: "fadeOut"
        }
      })
    );
  }
}
export function* getCostumersChart({ id }) {
  try {
    const response = yield call(api.get, `invoices/costumers/${id}`);
    yield put(InvoicesActions.setInvoiceConsumers(response.data));
  } catch (err) {
    console.log(err)
    yield put(
      toastrAction.add({
        type: "error",
        title: "Ops...",
        message: "Não foi possível alterar buscar os consumidores, tente novamente",
        closeOnToastrClick: true,
        position: "top-center",
        options: {
          showCloseButton: true,
          closeOnToastrClick: true,
          progressBar: true,
          transitionOut: "fadeOut"
        }
      })
    );
  }
}
export function* getInvoiceChartData({ data }) {
  try {
    const response = yield call(api.post, `invoices/total/${data.filterChartCostumer}`, data);
    yield put(InvoicesActions.getChartDataSuccess(response.data));
  } catch (err) {
    console.log(err);
  }
}