import { createReducer, createActions } from "reduxsauce";
import Immutable from "seamless-immutable";

const { Types, Creators } = createActions({
  showMenu: null
});
export const MenuTypes = Types;
export default Creators;

export const INITIAL_STATE = Immutable({
  menuVisible: false
});

export const changeMenu = state => {
  if (state.menuVisible) {
    return state.merge({ menuVisible: false });
  } else {
    return state.merge({ menuVisible: true });
  }
};

export const reducer = createReducer(INITIAL_STATE, {
  [Types.SHOW_MENU]: changeMenu
});
