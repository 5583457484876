import React, { Component } from "react";
import { Link } from "react-router-dom";

/* REDUX */
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import InvoicesActions from "../../store/ducks/invoices";
import ErrorsActions from "../../store/ducks/errors";

import NumberFormat from "react-number-format";
/*Styles*/
import { Container, Content, Panel } from "./styles";

/*Layout*/
import { Table, Button, Form, Row, Col, InputGroup } from "react-bootstrap";

/*Components*/
import Header from "../../components/Header";
import TopBar from "../../components/TopBar";
import Loader from "../../components/Loader";

class FaturaDadosEdit extends Component {
  state = {
    client_id: "",
    invoice_id: "",
    id: this.props.invoices.currentInvoiceData.id || "",
    consumo_ponta: this.props.invoices.currentInvoiceData.consumo_ponta || "",
    consumo_fora_de_ponta:
      this.props.invoices.currentInvoiceData.consumo_fora_de_ponta || ""
  };

  componentDidMount() {
    const { id } = this.props.match.params;
    const { client_id } = this.props.match.params;
    const { invoice_id } = this.props.match.params;
    const { clearError, getInvoiceDataEdit } = this.props;
    /* Limpa os Erros */
    clearError();
    this.setState({
      client_id: client_id,
      id: id,
      invoice_id: invoice_id
    });
    //busca os dados da fatura
    getInvoiceDataEdit(client_id, invoice_id, id);
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.invoices.currentInvoiceData.id !==
      this.props.invoices.currentInvoiceData.id
    ) {
      this.setState({
        id: this.props.invoices.currentInvoiceData.id || "",
        consumo_ponta:
          this.props.invoices.currentInvoiceData.consumo_ponta || "",
        consumo_fora_de_ponta:
          this.props.invoices.currentInvoiceData.consumo_fora_de_ponta || ""
      });
    }
  }

  handleInputChange = e => {
    this.setState({ [e.target.name]: e.target.value });
  };

  handleSubmit = e => {
    e.preventDefault();
    const { updateData } = this.props;
    updateData(this.state);
  };

  render() {
    const { errors, invoices } = this.props;
    const { client_id } = this.props.match.params;
    const { invoice_id } = this.props.match.params;
    return (
      <Container>
        <Header />
        {!invoices.currentLoaded && <Loader />}
        <Content>
          <TopBar title="Edição de fatura" />
          <Panel>
            <div className="container_action">
              <h1>Editar dados da fatura</h1>
              <Link
                className="btn btn-success"
                to={`/faturas/${client_id}/dados/${invoice_id}`}
              >
                <i className="fa fa fa-arrow-left" /> &nbsp; Voltar
              </Link>
            </div>
            <div className="panel-container">
              <Form>
                <Row>
                  <Form.Group as={Col}>
                    <Form.Label>Consumo de Ponta</Form.Label>
                    <NumberFormat
                        className="form-control"
                        onChange={this.handleInputChange}
                        placeholder="Consumo de Ponta"
                        name="consumo_ponta"
                        fixedDecimalScale={true}
                        decimalScale={2}
                        decimalSeparator={"."}
                        value={this.state.consumo_ponta}
                      />
                    {errors.data.consumo_ponta && (
                      <span className="inputError">
                        {errors.data.consumo_ponta}
                      </span>
                    )}
                  </Form.Group>
                  <Form.Group as={Col}>
                    <Form.Label>Consumo Fora de Ponta</Form.Label>
                    <NumberFormat
                        className="form-control"
                        onChange={this.handleInputChange}
                        placeholder="Fora de Ponta"
                        name="consumo_fora_de_ponta"
                        fixedDecimalScale={true}
                        decimalScale={2}
                        decimalSeparator={"."}
                        value={this.state.consumo_fora_de_ponta}
                      />
                    {errors.data.consumo_fora_de_ponta && (
                      <span className="inputError">
                        {errors.data.consumo_fora_de_ponta}
                      </span>
                    )}
                  </Form.Group>
                </Row>
                <Button
                  variant="primary"
                  type="submit"
                  onClick={this.handleSubmit}
                >
                  Enviar
                </Button>
              </Form>
            </div>
          </Panel>
        </Content>
      </Container>
    );
  }
}
const mapStateToProps = state => ({
  invoices: state.invoices,
  errors: state.errors
});
const mapDispatchToProps = dispatch =>
  bindActionCreators({ ...InvoicesActions, ...ErrorsActions }, dispatch);
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(FaturaDadosEdit);
