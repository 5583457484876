import React from "react";
import { ConnectedRouter } from "connected-react-router";
import { Route, Switch } from "react-router-dom";
// Areas de cliente
import SignIn from "../pages/Auth/Login";
import Forgot from "../pages/Auth/Forgot";
import Password from "../pages/Auth/Password";

//Dashboard
import Dashboard from "../pages/Dashboard";
import Show from "../pages/Dashboard/show";
//Consumidores
import Consumidores from "../pages/Consumidores";
import ConsumidoresAdd from "../pages/Consumidores/add";
import ConsumidoresEdit from "../pages/Consumidores/edit";
//Equipamentos
import Equipamentos from "../pages/Equipamentos";
import EquipamentosAdd from "../pages/Equipamentos/add";
import EquipamentosEdit from "../pages/Equipamentos/edit";
// Faturas
import Faturas from "../pages/Faturas";
import FaturaDados from "../pages/Faturas/view";
import FaturaDadosEdit from "../pages/Faturas/edit";
import FaturaPDF from "../pages/Faturas/imprimir";
//Projetos
import Projetos from "../pages/Projetos";
import ProjetoAdd from "../pages/Projetos/add";
import ProjetoEdit from "../pages/Projetos/edit";
//Remotas
import Coletores from "../pages/Coletores";
import ColetoresAdd from "../pages/Coletores/add";
import ColetoresEdit from "../pages/Coletores/edit";
//Pontos
import Pontos from "../pages/Pontos";
import PontosAdd from "../pages/Pontos/add";
import PontosEdit from "../pages/Pontos/edit";
//Usuarios
import Usuarios from "../pages/Usuarios";
import UsuarioAdd from "../pages/Usuarios/add";
import UsuarioEdit from "../pages/Usuarios/edit";

import history from "./history";
import Private from "./private";
import Guest from "./guest";

const Routes = () => (
  <ConnectedRouter history={history}>
    <Switch>
      <Route path="/login" component={SignIn} />
      <Route path="/password" component={Forgot} />
      <Route path="/reset_password" component={Password} />

      <Private exact path="/dashboard" component={Dashboard} />

      <Private exact path="/show/:id" component={Show} />
      <Private exact path="/consumidores/:id" component={Consumidores} />

      <Private
        exact
        path="/consumidores/:id/cadastro"
        component={ConsumidoresAdd}
      />
      <Private
        exact
        path="/consumidores/:client_id/editar/:id"
        component={ConsumidoresEdit}
      />
      <Private exact path="/equipamentos" component={Equipamentos} />
      <Private exact path="/equipamentos/cadastro" component={EquipamentosAdd} />
      <Private exact path="/equipamentos/:id/editar" component={EquipamentosEdit} />

      <Private exact path="/faturas" component={Faturas} />
      <Private exact path="/resumo" component={FaturaPDF} />
      <Private
        exact
        path="/faturas/:client_id/dados/:id"
        component={FaturaDados}
      />

      <Private
        exact
        path="/faturas/:client_id/dados/:invoice_id/editar/:id"
        component={FaturaDadosEdit}
      />

      <Private exact path="/projetos" component={Projetos} />
      <Private exact path="/projetos/cadastro" component={ProjetoAdd} />
      <Private exact path="/projetos/:id" component={ProjetoEdit} />
      <Private exact path="/projeto/:id/coletores" component={Coletores} />
      <Private
        exact
        path="/projeto/:id/coletores/cadastro"
        component={ColetoresAdd}
      />
      <Private
        exact
        path="/projeto/:id/coletores/editar/:idColetor"
        component={ColetoresEdit}
      />

      <Private exact path="/projetos/:id/pontos" component={Pontos} />
      <Private
        exact
        path="/projetos/:id/pontos/cadastro"
        component={PontosAdd}
      />
      <Private
        exact
        path="/projetos/:client_id/pontos/editar/:id"
        component={PontosEdit}
      />

      <Private exact path="/usuarios" component={Usuarios} />
      <Private exact path="/usuarios/cadastro" component={UsuarioAdd} />
      <Private exact path="/usuarios/:id" component={UsuarioEdit} />

      <Guest path="/" component={SignIn} />
    </Switch>
  </ConnectedRouter>
);

/*
const Routes = () => (
  <ConnectedRouter history={history}>
    <Switch>
      <Guest path="/login" component={SignIn} />
      <Guest path="/password" component={Forgot} />
      <Guest path="/reset_password" component={Password} />
      <Route path="/" component={SignIn} />
    </Switch>
  </ConnectedRouter>
);
*/

export default Routes;
