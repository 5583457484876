import styled from "styled-components";
import img from "../../assets/images/background_qed.png";
export const Container = styled.div`
  display: ${props => props.init == 1 ? "flex" : "none"};
  flex: 1;
  height: 100vh;
  background-image: url(${img});
  display: flex;
  justify-content: center;
  align-items: center;
  background-position: top right;
  background-repeat: no-repeat;
  background-color: #eaeaea;

  background-size: cover;
`;

export const SignForm = styled.form`
  background: #36393f;
  border-radius: 5px;
  box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.2);
  padding: 40px;
  width: 400px;
  max-width: 90%;
  display: flex;
  flex-direction: column;
  align-items: stretch;

  a {
    text-align: center;
    text-decoration: none;
    color: #fff;
    margin-top: 10px;
    text-transform: uppercase;
    font-size: 12px;
    font-weight: 300;
    letter-spacing: 1.13px;
  }
  a:hover {
    color: #fff;
  }
  h1 {
    font-size: 19px;
    font-weight: 500;
    text-align: center;
    margin: 0 0 10px;
    letter-spacing: 2px;
    text-transform: uppercase;
    color: #fff;

    span {
      font-size: 19px;
      font-weight: 700;
    }
  }

  span {
    color: #b9bbbe;
    font-size: 14px;
    font-height: 600px;
    margin-top: 15px;
  }

  input {
    height: 40px;
    padding: 10px;
    border-radius: 3px;
    border: 1px solid rgba(0, 0, 0, 0.3);
    background-color: rgba(0, 0, 0, 0.1);
    color: #f6f6f6;
    margin-top: 8px;
    transiction: border 0.15s ease;
    font-size: 16px;

    &:focus {
      border-color: #bdbdbd;
    }
  }
  button {
    margin: 20px 0 0;
  }
`;

export const SignForm2 = styled.form`
  background: #36393f;
  border-radius: 5px;
  box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.2);
  padding: 40px;
  width: 400px;
  max-width: 90%;
  display: flex;
  flex-direction: column;
  align-items: stretch;

  a {
    text-align: center;
    text-decoration: none;
    color: #fff;
    margin-top: 10px;
    text-transform: uppercase;
    font-size: 12px;
    font-weight: 300;
    letter-spacing: 1.13px;
  }
  h1 {
    font-size: 19px;
    font-weight: 500;
    text-align: center;
    margin: 0 0 10px;
    letter-spacing: 2px;
    text-transform: uppercase;
    color: #fff;

    span {
      font-size: 19px;
      font-weight: 700;
    }
  }

  span {
    color: #b9bbbe;
    font-size: 14px;
    font-height: 600px;
    margin-top: 15px;
  }

  input {
    height: 40px;
    padding: 10px;
    border-radius: 3px;
    border: 1px solid rgba(0, 0, 0, 0.3);
    background-color: rgba(0, 0, 0, 0.1);
    color: #f6f6f6;
    margin-top: 8px;
    transiction: border 0.15s ease;
    font-size: 16px;
  }

  button {
    margin: 20px 0 0;
  }
`;
