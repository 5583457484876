import React, { Component } from "react";
import { Link } from "react-router-dom";

import api from "../../services/api";
/* REDUX */
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import UsersActions from "../../store/ducks/users";
import ErrorsActions from "../../store/ducks/errors";

/*Styles*/
import { Container, Content, Panel } from "./styles";

/*Layout*/
import { Row, Col } from "react-grid-system";
import Select from "react-select";

/*Components*/
import Header from "../../components/Header";
import TopBar from "../../components/TopBar";
import { Button, Form } from "react-bootstrap";
import Loader from "../../components/Loader";
import Is from "../../components/Is";

class UserAdd extends Component {
  state = {
    name: "",
    email: "",
    password: "",
    roles: [],
    projects: [],
    selectRoles: [],
    selectClients: []
  };

  async componentDidMount() {
    const { clearError, showLoader, hideLoader } = this.props;
    clearError();
    showLoader();
    const response = await api.get("roles");
    this.setState({ selectRoles: response.data });
    const responseClients = await api.get("list-projects");
    this.setState({ selectClients: responseClients.data });
    hideLoader();
  }

  handleInputChange = e => {
    this.setState({ [e.target.name]: e.target.value });
  };

  handleRolesChange = e => {
    if (!e) {
      this.setState({ roles: null });
    } else {
      this.setState({ roles: e.map(element => element.id) });
    }
  };
  handleClientChange = e => {
    console.log(e);
    if (!e) {
      this.setState({ projects: null });
    } else {
      this.setState({ projects: e.map(element => element.IdCliente) });
    }
  };

  handleSubmit = e => {
    e.preventDefault();
    console.log(this.state);
    const data = {
      name: this.state.name,
      email: this.state.email,
      password: this.state.password,
      roles: this.state.roles,
      projects: this.state.projects
    };
    const { createUser } = this.props;
    createUser(data);
  };

  render() {
    const { users, errors } = this.props;
    const { selectRoles, selectClients } = this.state;
    const customStyles = {
      option: (provided, state) => ({
        ...provided,
        borderBottom: "1px dotted #c3c3c3",
        color: "#030303",
        padding: 5,
        textTransform: "uppercase",
        fontSize: "13px",
        fontWeight: "700"
      }),

      singleValue: (provided, state) => {
        const opacity = state.isDisabled ? 0.5 : 1;
        const transition = "opacity 300ms";
        return { ...provided, opacity, transition };
      }
    };
    return (
      <Container>
        {!users.loaded && <Loader />}
        <Header />
        <Content>
          <TopBar title="Cadastro de usuários" />

          <Is checkRole={["administrator", "client"]}>
            <Panel>
              <div className="container_action">
                <h1>Novo usuário</h1>
                <Link className="btn btn-success" to="/usuarios">
                  <i className="fa fa fa-arrow-left" /> &nbsp; Voltar
                </Link>
              </div>
              <div className="panel-container">
                <Form>
                  <Row>
                    <Form.Group as={Col} xs={12} md={4} lg={4} controlId="formGridEmail1">
                      <Form.Label>Nome</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Informe o nome do usuário"
                        name="name"
                        onChange={this.handleInputChange}
                      />
                      {errors.data.name && (
                        <span className="inputError">{errors.data.name}</span>
                      )}
                    </Form.Group>
                    <Form.Group as={Col} xs={12} md={4} lg={4} controlId="formGridEmail2">
                      <Form.Label>Email</Form.Label>
                      <Form.Control
                        type="email"
                        placeholder="Informe o email do usuário"
                        name="email"
                        onChange={this.handleInputChange}
                      />
                      {errors.data.email && (
                        <span className="inputError">{errors.data.email}</span>
                      )}
                    </Form.Group>

                    <Form.Group as={Col} xs={12} md={4} lg={4} controlId="formGridPassword1">
                      <Form.Label>Senha</Form.Label>
                      <Form.Control
                        type="password"
                        placeholder="Informe a senha do usuário"
                        name="password"
                        onChange={this.handleInputChange}
                      />
                      {errors.data.password && (
                        <span className="inputError">
                          {errors.data.password}
                        </span>
                      )}
                    </Form.Group>
                  </Row>
                  <Is checkRole={"administrator"}>
                    <Row>
                      <Form.Group as={Col} controlId="formGridPassword">
                        <Form.Label>Grupo de Usuário</Form.Label>
                        <Select
                          isMulti
                          styles={customStyles}
                          options={selectRoles}
                          getOptionValue={role => role.id}
                          getOptionLabel={role => role.name}
                          onChange={value => this.handleRolesChange(value)}
                          placeholder={"Selecione o grupo"}
                        />
                      </Form.Group>
                    </Row>
                  </Is>
                  <Is checkRole={"administrator"}>
                    <Row>
                      <Form.Group as={Col} controlId="formGridPassword">
                        <Form.Label>Projetos relacionados</Form.Label>
                        <Select
                          isMulti
                          styles={customStyles}
                          options={selectClients}
                          getOptionValue={client => client.IdCliente}
                          getOptionLabel={client => client.Nome}
                          onChange={value => this.handleClientChange(value)}
                          placeholder={"Selecione os clientes"}
                        />
                      </Form.Group>
                    </Row>
                  </Is>
                  <Button
                    variant="primary"
                    type="submit"
                    onClick={this.handleSubmit}
                  >
                    Enviar
                  </Button>
                </Form>
              </div>
            </Panel>
          </Is>
        </Content>
      </Container>
    );
  }
}

const mapStateToProps = state => ({
  users: state.users,
  errors: state.errors
});

const mapDispatchToProps = dispatch =>
  bindActionCreators({ ...UsersActions, ...ErrorsActions }, dispatch);
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UserAdd);
