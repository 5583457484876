import { combineReducers } from "redux";
import { reducer as toastr } from "react-redux-toastr";
import { connectRouter } from "connected-react-router";
import { reducer as auth } from "./auth";
import { reducer as menu } from "./menu";
import { reducer as project } from "./project";
import { reducer as dashboard } from "./dashboard";
import { reducer as users } from "./users";
import { reducer as errors } from "./errors";
import { reducer as coletores } from "./coletores";
import { reducer as consumidores } from "./consumidores";
import { reducer as pontos } from "./pontos";
import { reducer as invoices } from "./invoices";
import { reducer as equipamentos } from "./equipamentos";

export default history =>
  combineReducers({
    toastr,
    auth,
    menu,
    project,
    users,
    errors,
    dashboard,
    coletores,
    consumidores,
    pontos,
    invoices,
    equipamentos,
    router: connectRouter(history)
  });
