import React, { PureComponent } from "react";
import { Link } from "react-router-dom";
import NumberFormat from "react-number-format";

import moment from "moment";

/* REDUX */
import ProjectsActions from "../../store/ducks/project";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import DashboardActions from "../../store/ducks/dashboard";
import ConsumidoresActions from "../../store/ducks/consumidores";
import InvoicesActions from "../../store/ducks/invoices";

/*Styles*/
import "react-datepicker/dist/react-datepicker.css";
import {
  ContainerPrimary,
  Content,
  FilterDate,
  Filters,
  Panel,
  ProjectContainer
} from "./styles";

/*Layout*/
import { Table, Button, Form, Row, Col, Container, Card } from "react-bootstrap";
//import { StickyTable, Row as TabRow, Cell }from "react-sticky-table"

/*Components*/
import DayPickerInput from "react-day-picker/DayPickerInput";
import "react-day-picker/lib/style.css";
import MomentLocaleUtils, {
  formatDate,
  parseDate
} from "react-day-picker/moment";
import Modal from "../../components/Modal";
import Header from "../../components/Header";
import TopBar from "../../components/TopBar";
import { CSVLink, CSVDownload } from "react-csv";
import {
  LineChart,
  Line,
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer
} from "recharts";

class Show extends PureComponent {
  state = {
    WEEKDAYS_SHORT: ["Dom", "Seg", "Ter", "Qua", "Qui", "Sex", "Sab"],
    startDate: undefined,
    endDate: undefined,
    id: this.props.match.params.id,
    filtered: false,
    valor: "",
    descricao: "",
    consumer_id: "",
    fatura_tipo : "",
    valor_kwh : "",
    consumo_geral : "",
    tipo_grafico : "barras",
    verConsOfCliWpt: -1,
    csvGen: false,
    csvData: [],
    consSortByNum: []
  };

  

  componentDidMount() {
    const { id } = this.props.match.params;
//    const { getCostumersChart } = this.props;
//    getCostumersChart(id);
    const { getConsByCliWpt } = this.props;

    getConsByCliWpt(id);
  }

  /* Pega o texto dos inputs */
  handleInputChange = e => {
    this.setState({ [e.target.name]: e.target.value });
    this.setState({ filtered: false });
  };

  handleFromChange = from => {
    this.setState({ startDate: from });
    this.setState({ filtered: false });
  };

  handleToChange = to => {
    this.setState({ endDate: to });
    this.setState({ filtered: false });
  };

  handleSubmit = e => {
    const { descricao,fatura_tipo,valor_kwh,valor,consumo_geral } = this.state;
    var validate = true;
    var message = "";
    
    if(!fatura_tipo){
      message = "Informe o tipo de fatura";
      validate = false;
    }else{
      if(fatura_tipo == 1 || fatura_tipo == 3){
        if(!consumo_geral){
          message = "Informe o consumo";
          validate = false;
        }
        if(!valor){
          message = "Informe o valor da conta";
          validate = false;
        }

      }else if(fatura_tipo == 2){
        if(!valor_kwh){
          message = "Informe o valor por kWh";
          validate = false;
        }
      }
    }

    if(!descricao){
      message = "Informe a descrição";
      validate = false;
    }

    if (!validate) {
      alert(message);
    } else {
      const { createFatura } = this.props;
      createFatura(this.state);
    }
  };

  escondeModal() {
    const { hideModal } = this.props;
    hideModal();
  }

  mostraModal() {
    const { showModal } = this.props;
    showModal();
  }

  handleRefresh() {
    const { getChartData, getMaxMin, getTotal } = this.props;

    this.setState({
      filtered: true
    });

    getChartData(this.state);
    getTotal(this.state);
    getMaxMin(this.state);

    this.setState({csvGen: false});
  }

  hdlSetBarGraph() {
    this.setState({ tipo_grafico: "barras" });
  }

  hdlSetLineGraph() {
    this.setState({ tipo_grafico: "linhas" });
  }

  render() {
    const { dashboard } = this.props;
//    const { invoices } = this.props;
    const { consumidores } = this.props;
    const { filtered } = this.state;
    const { consumer_id } = this.state;
    const { startDate, endDate } = this.state;
    const { fatura_tipo } = this.state;
    const { tipo_grafico } = this.state;

    const stlBtnGrSel = {color: "tomato", backgroundColor: "blue"};
    const stlBtnGrUnsel = {color: "black", backgroundColor: "transparent"};

    const MONTHS = [
      "Janeiro",
      "Fevereiro",
      "Março",
      "Abril",
      "Maio",
      "Junho",
      "Julho",
      "Agosto",
      "Setembro",
      "Outubro",
      "Novembro",
      "Dezembro"
    ];

    const WEEKDAYS_LONG = [
      "Domingo",
      "Segunda",
      "Terça",
      "Quarta",
      "Quinta",
      "Sexta",
      "Sabado"
    ];

    //const WEEKDAYS_SHORT = ["Dom", "Seg", "Ter", "Qua", "Qui", "Sex", "Sab"];

    const CustomTooltip = ({ active, payload, label }) => {
      if (active && payload && payload.length) {
        const divStyle = {
          backgroundColor: "blue", 
          color: "white",
          border: "1px solid #cecece",
          borderRadius: "0.40rem",
          padding: "5px",
          margin: "10px"
        }
        const pStyle = {
          color: "white",
          fontSize: "12px",
          margin: "0",
          lineHeight: "20px"
        }
        return (
          <div style={divStyle}>
            <p style={pStyle}>{`${label}`}</p>
            <p style={pStyle}>{`${payload[0].name}: ${payload[0].value.toFixed(2).replace('.',',')} kWh`}</p>
            <p style={pStyle}>{`${payload[1].name}: ${payload[1].value.toFixed(2).replace('.',',')} kWh`}</p>
            <p style={pStyle}>{`TOTAL: ${(payload[0].value + payload[1].value).toFixed(2).replace('.',',')} kWh`}</p>
          </div>
        );
      }
    
      return null;
    };

    
    if ( consumidores.loadedConsOfCliWpt ) {

      if ( consumidores.verConsOfCliWpt !== this.state.verConsOfCliWpt ) {
        //var arrCopy = consumidores.consOfCliWpt.slice(0)  // Shallow Copy (so copia apontadores para estruturas)

        var arrCopy = [...consumidores.consOfCliWpt].sort( function (a,b) {
          if ( a.SiglaNum < b.SiglaNum ) {
            return -1
          }

          if ( a.SiglaNum > b.SiglaNum ) {
            return 1
          }

          return 0
        })

        this.setState({consSortByNum: [...arrCopy]})
        this.setState({verConsOfCliWpt: consumidores.verConsOfCliWpt})
      }
    }


    if ( !this.state.csvGen ) {
      if ( consumer_id !== "" ) {
        const csvDiarioHdr = [
          "Data",
          "Dia",
          "Consumo Ponta (kWh)",
          "Consumo Fora de Ponta (kWh)",
          "Consumo Total (kWh)"
        ];
  
        if ( dashboard.consumoDataLoaded && dashboard.consumoData.length )
        {
          const conSel = consumidores.consOfCliWpt.find(el => el.IdConsumidor == consumer_id);
          //        const conSel = invoices.costumersChart.find(el => el.IdConsumidor == this.state.consumer_id);
          if ( conSel !== undefined )
          {
            this.state.csvData.length = 0;
            this.state.csvData.push(["Periodo:",moment(startDate).format('DD/MM/YYYY'),moment(endDate).format('DD/MM/YYYY')]);
            this.state.csvData.push(["Ident:",conSel.SiglaNum, conSel.NomeFantasia]);
            this.state.csvData.push(csvDiarioHdr);
            
            dashboard.consumoData.map(conDia => {
              this.state.csvData.push([
                conDia.dateCon.toLocaleString("pt-BR"),
                this.state.WEEKDAYS_SHORT[moment(conDia.dateCon,'DD-MM-YYYY').day()],
                conDia.conPT.toFixed(2).replace(".",","),
                conDia.conFP.toFixed(2).replace(".",","),
                (conDia.conPT + conDia.conFP).toFixed(2).replace(".",",")
              ]);
            
              return conDia;
            });

            this.setState({csvGen: true});
          }
        }
      }
      else {
        if ( dashboard.totalLoaded && dashboard.total.length ) 
        {
          const csvTotalHdr = [
            "Ident",
            "Descricao",
            "Consumo Ponta (kWh)",
            "Consumo Fora de Ponta (kWh)",
            "Consumo Total (kWh)"
          ];

          this.state.csvData.length = 0;

          this.state.csvData.push(["Periodo:",moment(startDate).format('DD/MM/YYYY'),moment(endDate).format('DD/MM/YYYY')]);
          this.state.csvData.push(csvTotalHdr);
    
          dashboard.total.map(totals => {
            this.state.csvData.push([
              totals.num,
              totals.nome,
              totals.totPT.toFixed(2).replace(".",","),
              totals.totFP.toFixed(2).replace(".",","),
              (totals.totPT + totals.totFP).toFixed(2).replace(".",",")
            ]);
            return totals;
          });

          this.setState({csvGen: true});
        }
      }
    }

    return (
      <ContainerPrimary>
        <Header />
        <Content>
          <TopBar title="Relatórios" />
          <Container fluid>
            <Panel style={{marginTop:"10px"}}>
              <Filters>
                <FilterDate className="mr-10">
                  <Form.Label className="filter-label">Data Inicial</Form.Label>
                  <div className="InputFromTo">
                    <DayPickerInput
                      inputProps={{
                        readonly: 'readonly'
                      }}
                      value={startDate}
                      format="DD/MM/YYYY"
                      placeholder={"Data Inicial"}
                      dayPickerProps={{
                        locale: "pt-br",
                        months: MONTHS,
                        weekdaysLong: WEEKDAYS_LONG,
                        weekdaysShort: this.state.WEEKDAYS_SHORT,
                        localeUtils: MomentLocaleUtils
                      }}
                      formatDate={formatDate}
                      parseDate={parseDate}
                      onDayChange={this.handleFromChange}
                    />
                  </div>
                </FilterDate>

                <FilterDate className="mr-10">
                  <Form.Label className="filter-label">Data Final</Form.Label>
                  <div className="InputFromTo-to">
                    <DayPickerInput
                      inputProps={{
                        readonly: 'readonly'
                      }}
                      ref={el => (this.to = el)}
                      value={endDate}
                      placeholder="Data Final"
                      format="DD/MM/YYYY"
                      formatDate={formatDate}
                      parseDate={parseDate}
                      dayPickerProps={{
                        locale: "pt-br",
                        months: MONTHS,
                        weekdaysLong: WEEKDAYS_LONG,
                        weekdaysShort: this.state.WEEKDAYS_SHORT,
                        localeUtils: MomentLocaleUtils
                      }}
                      onDayChange={this.handleToChange}
                    />
                  </div>
                </FilterDate>
                {/*invoices.costumersChart.length && (*/
                 this.state.consSortByNum.length && (
                  <FilterDate className="mr-10">
                    <Form.Label className="filter-label">Consumidor</Form.Label>
                    <Form.Control
                      name="consumer_id"
                      as="select"
                      onChange={this.handleInputChange}
                    >
                      <option value="">
                        Todos
                      </option>
                      {/*invoices.costumersChart.map(consumidor => (
                        <option key={consumidor.SiglaNum} value={consumidor.IdConsumidor}>
                          {consumidor.SiglaNum} - {consumidor.NomeFantasia}
                        </option>
                      ))*/}

                      {this.state.consSortByNum.map(consumidor => ( 
                        <option value={consumidor.IdConsumidor}>
                          {consumidor.SiglaNum} - {consumidor.NomeFantasia}
                        </option>
                      ))}
                    </Form.Control>
                  </FilterDate>
                )}
                <Button
                  variant="outline-danger"
                  type="submit"
                  onClick={() => {
                    this.handleRefresh();
                  }}
                >
                  <i className="fa fa-search" />
                </Button>
                {filtered && !consumer_id && (
                  <Button
                    variant="outline-dark"
                    onClick={() => {
                      this.mostraModal();
                    }}
                  >
                    <i className="fa fa-download" />
                    &nbsp; Gerar Fatura
                  </Button>
                )}
              </Filters>
              <h3 className="instruction">
                Selecione a data para visualizar os dados
              </h3>
            </Panel>

            {filtered && (
              <Panel style={{color: "black"}} className="bg-transparent">
                <Row>
                <Col style={{flexGrow: "3"}}>
                  <ProjectContainer>
                    <div className="container_action">
                      <h1>Consumo Mínimo e Máximo</h1>
                    </div>
                    {!dashboard.maxMinLoaded && (
                      <div className="panel-container textCenter loader-dashboard">
                        <i className="fa fa-circle-o-notch fa-spin fa-3x fa-fw" />
                        <p>Carregando...</p>
                      </div>
                    )}
                    {dashboard.maxMinLoaded && !dashboard.maxMin.length && (
                      <div className="panel-container textCenter loader-dashboard">
                        <h3 className="title-panel-dash">
                          Não há dados para o período
                        </h3>
                      </div>
                    )}
                    {dashboard.maxMinLoaded && dashboard.maxMin.length && (
                      <div className="panel-container300">
                        <table className="scroll">
                          <thead className="col04">
                            <tr className="text-center tabHdr">
                              <th>Ident</th>
                              <th>Nome</th>
                              <th>Consumo Mínimo<br/>kWh</th>
                              <th>Consumo Máximo<br/>kWh</th>
                            </tr>
                          </thead>
                          <tbody className="col04">
                            {dashboard.maxMin.map(maxmin => (
                            <tr key={maxmin.num}>
                              <td className="text-left">{maxmin.num}</td>
                              <td className="text-left">{maxmin.nome}</td>
                              <td className="text-right">{maxmin.min.toFixed(2).replace('.',',')}</td>
                              <td className="text-right">{maxmin.max.toFixed(2).replace('.',',')}</td>
                            </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    )}
                  </ProjectContainer>
                </Col>
                <Col style={{flexGrow: "5"}}>
                  <ProjectContainer>
                    <div className="container_action">
                      <h1>Consumo Diário e Total</h1>
                       {dashboard.consumoData.length && (
                        <div>
                          &nbsp;&nbsp;
                          <span>
                            Exportar:
                            &nbsp;
                            <CSVLink
                              filename={consumer_id === "" ? "consumo_Total.csv" : "consumo_Diario.csv"}
                              classeName="btn btn-outline-dark"
                              data={this.state.csvData}
                              separator={";"}
                            >
                              <i class="fa fa-file-text-o" aria-hidden="true" /> CSV
                            </CSVLink>
                          </span>
                        </div>
                       )}
                    </div>
                    {!dashboard.totalLoaded && (
                    <div className="panel-container textCenter loader-dashboard">
                      <i className="fa fa-circle-o-notch fa-spin fa-3x fa-fw" />
                      <p>Carregando...</p>
                    </div>
                    )}
                    {dashboard.totalLoaded && !dashboard.total.length && (
                      <div className="panel-container textCenter loader-dashboard">
                        <h3 className="title-panel-dash">
                          Dados não cadastrados para o período
                        </h3>
                      </div>
                    )}
                    {dashboard.totalLoaded && !consumer_id && (
                      <div className="panel-container300">
                        <table className="scroll">
                          <thead className="col05">
                            <tr className="text-center tabHdr">
                              <th>Ident</th>
                              <th>Nome</th>
                              <th>Ponta<br/>kWh</th>
                              <th>Fora de ponta<br/>kWh</th>
                              <th>TOTAL<br/>kWh</th>
                            </tr>
                          </thead>
                          <tbody className="col05">
                            {dashboard.total.map(totals => (
                            <tr key={totals.num}>
                                <td className="text-left">{totals.num}</td>
                                <td className="text-left">{totals.nome}</td>
                                <td className="text-right">{totals.totPT.toFixed(2).replace('.',',')}</td>
                                <td className="text-right">{totals.totFP.toFixed(2).replace('.',',')}</td>
                                <td className="text-right">
                                  {(totals.totPT + totals.totFP).toFixed(2).replace('.',',')}
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    )}
                    {dashboard.totalLoaded && consumer_id && 
                     dashboard.consumoDataLoaded && dashboard.consumoData.length && (
                      <div className="panel-container300">
                        <table className="scroll">
                          <thead className="col05">
                            <tr className="text-center tabHdr">
                              <th>Data</th>
                              <th>Dia</th>
                              <th>Ponta<br/>kWh</th>
                              <th>Fora de Ponta<br/>kWh</th>
                              <th>TOTAL<br/>kWh</th>
                            </tr>
                          </thead>
                          <tbody className="col05">
                              {dashboard.consumoData.map(conDia => (
                                <tr key={conDia.dateCon}>
                                  <td className="text-left">{conDia.dateCon}</td>
                                  <td className="text-center">{this.state.WEEKDAYS_SHORT[moment(conDia.dateCon,'DD-MM-YYYY').day()]}</td>
                                  <td className="text-right">{conDia.conPT.toFixed(2).replace(".",",")}</td>
                                  <td className="text-right">
                                    {conDia.conFP.toFixed(2).replace(".",",")}
                                  </td>
                                  <td className="text-right">
                                    {(conDia.conPT + conDia.conFP).toFixed(2).replace(".",",")}
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                            <tbody className="col05">
                              {dashboard.consumoData.length > 8 ? ( 
                              <tr key="TOTAL" className="footer">
                                <td className="text-left">TOTAL</td>
                                <td className="text-center"> - </td>
                                <td className="text-right">{dashboard.total[0].totPT.toFixed(2).replace('.',',')}</td>
                                <td className="text-right">
                                  {dashboard.total[0].totFP.toFixed(2).replace('.',',')}
                                </td>
                                <td className="text-right">
                                  {(dashboard.total[0].totPT + dashboard.total[0].totFP).toFixed(2).replace(".",",")}
                                </td>
                              </tr>
                              ) : ( 
                                <tr key="TOTAL">
                                  <td className="text-left">TOTAL</td>
                                  <td className="text-center"> - </td>
                                  <td className="text-right">{dashboard.total[0].totPT.toFixed(2).replace('.',',')}</td>
                                  <td className="text-right">
                                    {dashboard.total[0].totFP.toFixed(2).replace('.',',')}
                                  </td>
                                  <td className="text-right">
                                    {(dashboard.total[0].totPT + dashboard.total[0].totFP).toFixed(2).replace(".",",")}
                                  </td>
                                </tr>
                              )}
                          </tbody>
                        </table>
                      </div>
                    )}
                  </ProjectContainer>
                </Col>
                </Row>
              </Panel>
            )}
            {filtered && (
              <Panel style={{color: "black"}} className="bg-transparent">
              <ProjectContainer>
                <div className="container_action">
                  <h1>Consumo Diário</h1>
                  {dashboard.consumoData.length && (
                     <div>
                       &nbsp;&nbsp;
                       <span>
                          Tipo:
                          &nbsp;
                          <Button
                            style={tipo_grafico === "barras" ? stlBtnGrSel : stlBtnGrUnsel}
                            onClick={() => {
                              this.hdlSetBarGraph();
                            }}
                          >
                            <i className="fa fa-bar-chart" />
                          </Button>
                          &nbsp;
                          <Button
                            style={tipo_grafico === "linhas" ? stlBtnGrSel : stlBtnGrUnsel}
                            onClick={() => {
                              this.hdlSetLineGraph();
                            }}
                          >
                            <i className="fa fa-line-chart" />
                          </Button>
                        </span>
                     </div>
                   )}
                </div>
                {!dashboard.consumoDataLoaded && (
                  <div className="panel-container textCenter loader-dashboard">
                    <i className="fa fa-circle-o-notch fa-spin fa-3x fa-fw" />
                    <p>Carregando...</p>
                  </div>
                )}
                {dashboard.consumoDataLoaded && !dashboard.consumoData.length && (
                  <div className="panel-container textCenter loader-dashboard">
                    <h3 className="title-panel-dash">
                      Dados não cadastrados para o período
                    </h3>
                  </div>
                )}
                {dashboard.consumoDataLoaded && dashboard.consumoData.length && this.state.tipo_grafico === "barras" && (
                  <ResponsiveContainer width="85%" aspect={3.0 / 1.0}>
                    <BarChart
                      width={100}
                      height={100}
                      data={dashboard.consumoData}
                      margin={{
                        top: 20,
                        right: 30,
                        left: 20,
                        bottom: 5
                      }}
                    >
                      <CartesianGrid strokeDasharray="3 3" />
                      <XAxis dataKey="dateCon" />
                      <YAxis />
                      <Tooltip content={<CustomTooltip />} />
                      <Legend />
                      <Bar dataKey="conFP" name="Cons FP" stackId="a" fill="#40f040" barSize={30} />
                      <Bar dataKey="conPT" name="Cons Pt" stackId="a" fill="#f04040" barSize={30} />
                    </BarChart>
                  </ResponsiveContainer>
                )}
                {dashboard.consumoDataLoaded && dashboard.consumoData.length && this.state.tipo_grafico === "linhas" && (
                  <ResponsiveContainer width="85%" aspect={3.0 / 1.0}>
                    <LineChart
                      width={100}
                      height={100}
                      data={dashboard.consumoData}
                      margin={{
                        top: 20,
                        right: 30,
                        left: 20,
                        bottom: 5
                      }}
                    >
                      <CartesianGrid strokeDasharray="3 3" />
                      <XAxis dataKey="dateCon" />
                      <YAxis />
                      <Tooltip content={<CustomTooltip />} />
                      <Legend />
                      <Line type="monotone" dataKey="conFP" name="Cons FP" stroke="#40f040" strokeWidth={2} />
                      <Line type="monotone" dataKey="conPT" name="Cons Pt" stroke="#f04040" strokeWidth={2} />
                    </LineChart>
                  </ResponsiveContainer>
                )}

              </ProjectContainer>
              </Panel>
            )}
          </Container>
        </Content>
        {dashboard.modal && (
          <Modal>
            <Row>
              <Col>
                <h2 className="textCenter">Informações para Fatura</h2>
              </Col>
            </Row>

            <Row>
              <Col>
                <br />
                <Form.Control
                    name="fatura_tipo"
                    as="select"
                    onChange={this.handleInputChange}
                  >
                    <option key="0" value="0">
                      Selecione o tipo de fatura
                    </option>
                   
                    <option key={1} value={1}>
                      Rateio por Área
                    </option>

                    <option key={2} value={2}>
                      Valor por kWh
                    </option>

                    <option key={3} value={3}>
                      Dividir Consumo Comum Igualmente
                    </option>
                   
                  </Form.Control>
              </Col>
            </Row>

            { (fatura_tipo == 1 || fatura_tipo == 3) && (
              <Row>
                <Col>
                  <br />
                  <NumberFormat
                    className="form-control"
                    onChange={this.handleInputChange}
                    placeholder="Valor da conta geral"
                    name="valor"
                    fixedDecimalScale={true}
                    decimalScale={2}
                    decimalSeparator={","}
                  />
                </Col>
              </Row>
            )}

            { (fatura_tipo == 1 || fatura_tipo == 3) && (
              <Row>
                <Col>
                  <br />
                  <NumberFormat
                    className="form-control"
                    onChange={this.handleInputChange}
                    placeholder="Consumo da conta geral"
                    name="consumo_geral"
                    fixedDecimalScale={true}
                    decimalScale={2}
                    decimalSeparator={","}
                  />
                </Col>
              </Row>
            )}

            { fatura_tipo == 2 && (
              <Row>
                <Col>
                  <br />
                  <NumberFormat
                    className="form-control"
                    onChange={this.handleInputChange}
                    placeholder="Valor por kwh"
                    name="valor_kwh"
                    fixedDecimalScale={true}
                    decimalScale={2}
                    decimalSeparator={","}
                  />
                </Col>
              </Row>
            )}

            <Row>
              <Col>
                <br />
                <Form.Control
                  type="text"
                  placeholder="Descricao"
                  name="descricao"
                  onChange={this.handleInputChange}
                />
              </Col>
            </Row>
            <Row>
              <Col className="textCenter">
                <br />
                <Button
                  variant="outline-primary"
                  type="button"
                  onClick={() => {
                    this.handleSubmit();
                  }}
                >
                  {!dashboard.createLoader && <span>Gerar Fatura</span>}
                  {dashboard.createLoader && (
                    <span>
                      <i className="fa fa-circle-o-notch fa-spin fa-1x fa-fw" />{" "}
                      Processando...
                    </span>
                  )}
                </Button>
                &nbsp;&nbsp;
                <Button
                  variant="outline-danger"
                  type="button"
                  onClick={() => {
                    this.escondeModal();
                  }}
                >
                  Fechar
                </Button>
              </Col>
            </Row>
          </Modal>
        )}
      </ContainerPrimary>
    );
  }
}

const mapStateToProps = state => ({
  projects: state.project,
  dashboard: state.dashboard,
  consumidores: state.consumidores,
  invoices: state.invoices
});
const mapDispatchToProps = dispatch =>
  bindActionCreators(
    { ...ProjectsActions, ...DashboardActions, ...ConsumidoresActions,...InvoicesActions },
    dispatch
  );
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Show);
