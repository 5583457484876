import styled, { css } from "styled-components";
const sizes = {
  small: css`
    height: 28px;
    font-size: 12px;
  `,
  default: css`
    height: 36px;
    font-size: 14px;
  `,
  big: css`
    height: 44px;
    font-size: 18px;
  `
};

const colors = {
  default: css`
    background: #1d416d;

    &:hover {
      background: #4575b9;
    }
  `,

  danger: css`
    background: #872a1e;

    &:hover {
      background: #b73d34;
    }
  `,

  gray: css`
    background: #b9bbbe;
    color: #666;
    &:hover {
      background: #999;
    }
  `
};

export const Button = styled.button.attrs({ type: "button" })`
  border-radius: 3px;
  transition: background-color 0.15s ease;
  background: #cc1f00;
  border: 0;
  color: #fff;
  font-size: 12px;
  padding: 0 10px;
  text-transform: uppercase;
  font-weight: 700;

  ${props => sizes[props.size || "default"]};
  ${props => colors[props.color || "default"]};

  ${props =>
    props.filled === false &&
    css`
      background: none;
      &:hover {
        background: none;
        opacity: 0.6;
      }
    `};
`;

export const AnyButton = styled.button`
  border-radius: 3px;
  transition: background-color 0.15s ease;
  background: #cc1f00;
  border: 0;
  color: #fff;
  font-size: 12px;
  padding: 0 10px;
  text-transform: uppercase;
  font-weight: 700;

  ${props => sizes[props.size || "default"]};
  ${props => colors[props.color || "default"]};

  ${props =>
    props.filled === false &&
    css`
      background: none;
      &:hover {
        background: none;
        opacity: 0.6;
      }
    `};
`;

//export default Button;
