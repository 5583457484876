import React, { Component } from "react";
import { Link } from "react-router-dom";
import ReactPaginate from "react-paginate";
/* REDUX */
import ColetoresActions from "../../store/ducks/coletores";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
/*Styles*/
import { Container, Content, Panel } from "./styles";
/*Layout*/
import { Table, Button, Form, Row, Col, InputGroup } from "react-bootstrap";
/*Components*/
import Modal from "../../components/Modal";
import Header from "../../components/Header";
import TopBar from "../../components/TopBar";
import Loader from "../../components/Loader";
class Coletores extends Component {
  componentDidMount() {
    const { id } = this.props.match.params;
    const { getColetores } = this.props;
    getColetores(id);
  }

  state = {
    filter: "",
    id: false
  };

  hidenModal = () => {
    this.setState({ id: false });
    const { hideModal } = this.props;
    hideModal();
  };

  mostraModal = id => {
    this.setState({ id: id });
    const { showModal } = this.props;
    showModal();
  };

  /* Pega o texto dos inputs */
  handleInputChange = e => {
    this.setState({ [e.target.name]: e.target.value });
  };
  /* Filta os dados */
  handleFilter = () => {
    const { id } = this.props.match.params;
    const { filter } = this.state;
    const { getColetores } = this.props;
    getColetores(id, 1, filter);
  };

  handlePageClick = data => {
    const { id } = this.props.match.params;
    const { getColetores } = this.props;
    const { filter } = this.state;
    let selected = data.selected;
    selected = selected + 1;
    getColetores(id, selected, filter);
  };
  handleDelete = () => {
    const { id } = this.state;
    const { deleteColetor } = this.props;
    deleteColetor(id);
  };

  render() {
    const { id } = this.props.match.params;
    const { coletores } = this.props;
    return (
      <Container>
        {!coletores.loaded && <Loader />}
        <Header />
        <Content>
          <TopBar title="Coletores" />
          <Panel>
            <div className="container_action">
              <h1>Lista coletores</h1>
              <div>
                <Link
                  variant="success"
                  to={`/projeto/${id}/coletores/cadastro`}
                  className="btn btn-outline-primary margin-r-10"
                >
                  <i className="fa fa-plus" /> &nbsp;Cadastrar
                </Link>
                <Link className="btn btn-success" to="/projetos">
                  <i className="fa fa fa-arrow-left" /> &nbsp; Voltar
                </Link>
              </div>
            </div>
            <div className="panel-container">
              <p className="filter-form-text">Filtros:</p>
              <Row>
                <Col xs={12} md={4} lg={4}>
                  <InputGroup className="mb-3">
                    <Form.Control
                      type="text"
                      placeholder="Tag"
                      name="filter"
                      onChange={this.handleInputChange}
                    />
                    <Button
                      variant="outline-primary"
                      onClick={() => {
                        this.handleFilter();
                      }}
                      className="filterButton no-margin"
                    >
                      <i className="fa fa-search" />
                    </Button>
                  </InputGroup>
                </Col>
              </Row>
              <br />

              <Table responsive hover>
                <thead>
                  <tr>
                    <th>#</th>
                    <th>Tag</th>
                    <th>Projeto</th>
                    <th>Status</th>
                    <th>Editar</th>
                    <th className="textCenter">Remover</th>
                  </tr>
                </thead>
                <tbody>
                  {coletores.loaded && !coletores.data.length ? (
                    <tr>
                      <td colSpan="5">Nenhum coletor encontrado</td>
                    </tr>
                  ) : (
                    coletores.data.map(coletor => (
                      <tr key={coletor.IdColetor}>
                        <td>{coletor.IdColetor}</td>
                        <td>{coletor.GTag}</td>
                        <td>{coletor.client.Sigla}</td>
                        <td>{(!coletor.status && "Inativo") || "Ativo"}</td>
                        <td>
                          <Link
                            className="btn btn-outline-primary"
                            to={`/projeto/${coletor.client.IdCliente}/coletores/editar/${coletor.IdColetor}`}
                          >
                            <i className="fa fa-edit" />
                          </Link>
                        </td>
                        <td className="textCenter">
                          <Button
                            variant="outline-danger"
                            onClick={() => {
                              this.mostraModal(coletor.IdColetor);
                            }}
                          >
                            <i className="fa fa-warning" />
                          </Button>
                        </td>
                      </tr>
                    ))
                  )}
                </tbody>
              </Table>
              <div className="alignCenter">
                <ReactPaginate
                  previousLabel={"<"}
                  nextLabel={">"}
                  breakLabel={"..."}
                  breakClassName={"break-me"}
                  pageCount={coletores.coletoresInfo.lastPage}
                  marginPagesDisplayed={2}
                  pageRangeDisplayed={2}
                  onPageChange={this.handlePageClick}
                  disableInitialCallback={true}
                  containerClassName={"pagination"}
                  subContainerClassName={"pages pagination"}
                  activeClassName={"active"}
                />
              </div>
            </div>
          </Panel>
        </Content>
        {coletores.modal && (
          <Modal>
            <Row>
              <Col>
                <h2 className="textCenter">
                  Essa ação não poderá ser desfeita
                </h2>
              </Col>
            </Row>
            <Row>
              <Col>
                <br />
                <p className="text-center">
                  Você realmente deseja remover a coletora? Dados vinculados a
                  ela também serão removidos
                </p>
              </Col>
            </Row>
            <Row>
              <Col className="textCenter">
                <br />
                <Button
                  variant="outline-danger"
                  type="button"
                  onClick={() => {
                    this.hidenModal();
                  }}
                >
                  Cancelar
                </Button>
                &nbsp;&nbsp;{" "}
                <Button
                  variant="outline-primary"
                  type="button"
                  onClick={() => {
                    this.handleDelete();
                  }}
                >
                  <span>Sim, desejo remover</span>
                </Button>
              </Col>
            </Row>
          </Modal>
        )}
      </Container>
    );
  }
}
const mapStateToProps = state => ({
  coletores: state.coletores
});
const mapDispatchToProps = dispatch =>
  bindActionCreators({ ...ColetoresActions }, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(Coletores);
