import React, { Component } from "react";
import { Container, IconMenu, IconExit, Logout } from "./styles";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import MenuActions from "../../store/ducks/menu";
import AuthActions from "../../store/ducks/auth";

class TopBar extends Component {
  componentDidMount() {
    const { menu } = this.props;
    //console.log("AQUI");
    //console.log(menu.menuVisible);
    const width = window.innerWidth;

    if (width < 768 && !menu.menuVisible) {
      this.handleShowMenu();
    }
  }

  handleShowMenu = () => {
    const { showMenu } = this.props;
    showMenu();
  };

  render() {
    const { signOut } = this.props;
    const { auth } = this.props;

    return (
      <Container>
        <div className="jflex-start">
          <IconMenu className="fa fa-bars" onClick={this.handleShowMenu} />
          <h1>{this.props.title}</h1>
        </div>
        <div className="jflex-end">
          <h4>{auth.userName}</h4>
          <IconExit className="fa fa-sign-out" onClick={signOut} />
        </div>
      </Container>
    );
  }
}

const mapStateToProps = state => ({
  auth: state.auth,
  menu: state.menu
});

const mapDispatchToProps = dispatch =>
  bindActionCreators({...MenuActions, ...AuthActions}, dispatch);
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TopBar);
