import { call, put } from "redux-saga/effects";
import { push } from "connected-react-router";
import { actions as toastrAction } from "react-redux-toastr";
import api from "../../services/api";
import EquipamentosActions from "../ducks/equipamentos";
import ErrorsActions from "../ducks/errors";

export function* getEquipamentos({  page, filter }) {
  try {
    const paginate = page === undefined ? 1 : page;
    const filtro = filter === undefined ? "" : filter;
    const response = yield call(
      api.get,
      `equipamentos?page=${paginate}&filter=${filtro}`
    );
    yield put(EquipamentosActions.setEquipamentos(response.data.data));
  } catch (err) {
    yield put(
      toastrAction.add({
        type: "error",
        title: "Ops...",
        message: "Não foi possível retornar os equipamentos, tente novamente",
        closeOnToastrClick: true,
        position: "top-center",
        options: {
          showCloseButton: true,
          closeOnToastrClick: true,
          progressBar: true,
          transitionOut: "fadeOut"
        }
      })
    );
    yield put(push("/"));
  }
}

export function* getEquipTypes() {
  try {
    const response = yield call(api.get, "equipamentos/tipos");
    yield put(EquipamentosActions.setEquipTypes(response.data));
  } catch (err) {
    console.log(err);
    yield put(
      toastrAction.add({
        type: "error",
        title: "Ops...",
        message: "Não foi possível carregar os tipos, tente novamente",
        closeOnToastrClick: true,
        position: "top-center",
        options: {
          showCloseButton: true,
          closeOnToastrClick: true,
          progressBar: true,
          transitionOut: "fadeOut"
        }
      })
    );
    yield put(push("/equipamentos"));
  }
}

export function* createEquipamento({ data }) {
  try {
    const response = yield call(
      api.post,
      `equipamentos`,
      data
    );
    yield put(push(`/equipamentos`));
  } catch (err) { 
    if (err.response.data.message !== undefined) {
      yield put(ErrorsActions.setError(err.response.data.message));
      yield put(EquipamentosActions.hideLoader());
    }
    yield put(
      toastrAction.add({
        type: "error",
        title: "Ocorreu um erro",
        message: "Verifique os dados inseridos e tente novamente",
        closeOnToastrClick: true,
        position: "top-center",
        options: {
          showCloseButton: true,
          closeOnToastrClick: true,
          progressBar: true,
          transitionOut: "fadeOut"
        }
      })
    );
  }
}

export function* getEquipamento({id}) {
  try {
    const response = yield call(api.get, `equipamentos/${id}`);
    if (!response.data) {
      throw new Error("Nenhum dado encontrado, tente novamente");
    }
    yield put(EquipamentosActions.setEquipamento(response.data));
    
  } catch (err) {
    yield put(
      toastrAction.add({
        type: "error",
        title: "Ops...",
        message: "Não foi possível carregar o equipamento, tente novamente",
        closeOnToastrClick: true,
        position: "top-center",
        options: {
          showCloseButton: true,
          closeOnToastrClick: true,
          progressBar: true,
          transitionOut: "fadeOut"
        }
      })
    );
    yield put(push(`/equipamentos`));
  }
}

export function* updateEquipamento({ data }) {
  try {
    yield call(api.put, `equipamentos/${data.IdEquip}`, data);
    yield put(push(`/equipamentos`));
  } catch (err) {
    if (err.response.data.message !== undefined) {
      yield put(ErrorsActions.setError(err.response.data.message));
      yield put(EquipamentosActions.hideLoader());
    }
    yield put(
      toastrAction.add({
        type: "error",
        title: "Ops...",
        message: "Não foi possível editar o equipamento, tente novamente",
        closeOnToastrClick: true,
        position: "top-center",
        options: {
          showCloseButton: true,
          closeOnToastrClick: true,
          progressBar: true,
          transitionOut: "fadeOut"
        }
      })
    );
  }
}

export function* deleteEquipamento({ id }) {
  try {
    const response = yield call(api.delete, `equipamentos/${id}`);
    yield put(EquipamentosActions.deleteSucc(response.data));
    yield put(
      toastrAction.add({
        type: "success",
        title: "Tudo certo!",
        message: "Equipamento removido com sucesso!",
        closeOnToastrClick: true,
        position: "top-center",
        options: {
          showCloseButton: true,
          closeOnToastrClick: true,
          progressBar: true,
          transitionOut: "fadeOut"
        }
      })
    );
  } catch (err) {
    yield put(
      toastrAction.add({
        type: "error",
        title: "Ops...",
        message: "Não foi possível remover o consumidor, tente novamente",
        closeOnToastrClick: true,
        position: "top-center",
        options: {
          showCloseButton: true,
          closeOnToastrClick: true,
          progressBar: true,
          transitionOut: "fadeOut"
        }
      })
    );
  }
}
