import React, { Fragment } from "react";
import { Provider } from "react-redux";
import ReduxToastr from "react-redux-toastr";
import Routes from "./routes";
import StyleGlobal from "./styles/global";
import store from "./store";

const App = () => (
  <Provider store={store}>
    <Fragment>
      <StyleGlobal />
      <Routes />
      <ReduxToastr />
    </Fragment>
  </Provider>
);
export default App;
