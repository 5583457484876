import styled from "styled-components";

const size = {
  tablet: "768px"
};
export const device = {
  modileBreak: `(max-width: ${size.tablet})`
};
export const ContainerPrimary = styled.div`
  display: flex;
  -webkit-box-align: stretch;
  align-items: stretch;
  height: 100%;
  min-height: 100vh;
  background: #f0f0f0;
  color: #000;
  max-width: 100%;
  input {
    color: #000;
  }
  .labelDate {
    margin-right: 10px;
  }
  .dashboard-content {
  }
  .mr-10 {
    margin-right: 10px;
  }
  .instruction {
    color: #464457;
    margin: 0px;
    font-size: 12px;
    text-transform: uppercase;
    letter-spacing: 1.13px;
    font-weight: 700;
    padding: 0px 10px 10px 10px;
  }
`;
export const Content = styled.div`
  max-width: 100%;
  display: flex;
  flex-direction: column;
  -webkit-box-align: stretch;
  align-items: stretch;
  position: relative;
  color: rgb(255, 255, 255);
  flex: 1 1 0%;
  background: #f2f3f8;
  padding-bottom: 10px;

  .bootstrap-container {
    width: 100% !important;
    margin-top: 20px;
  }
  .titlechart {
    font-size: 16px;
    text-transform: uppercase;
    color: #464457;
    letter-spacing: 1.13px;
    margin-left: 40px;
  }
  .recharts-legend-item-text,
  .recharts-tooltip-label {
    color: #464457;
  }
  span,
  .not_data {
    color: #464457;
  }
  .not_data {
    margin-left: 40px;
  }
`;

export const ProjectContainer = styled.div`
  box-shadow: 0px 0px 10px 0px rgba(82, 63, 105, 0.05);
  background-color: #ffffff;
  width: 100%;
  min-height: 100px;
  border-radius: 1px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  position: relative;
  transition: all 0.2s;
  margin-top: 10px;
  transition: all 0.5s ease;

  div.container_action {
    border-bottom: 1px solid #ebedf2;
    padding: 0 10px;
    min-height: 40px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    h1 {
      font-size: 0.8rem;
      font-weight: 700;
      color: #464457;
      margin: 0;
    }
  }
  &:hover {
    box-shadow: 0px 0px 10px 0px rgba(82, 63, 105, 0.4);
  }
  img {
    width: 90%;
    margin-left: 5%;
    padding-top: 20px;
  }
  .container-actions {
    border-top: 1px solid #ebedf2;
    margin-top: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: row;

    a {
      flex: 1;
      background: transparent;
      color: #464457;
      box-shadow: 0px 0px 10px 0px rgba(82, 63, 105, 0.05);
      border-radius: 0px;
      transition: all 0.2s ease;
      text-align: center;
      padding: 10px;
      &:hover {
        background: #e2e2e2;
        color: #464457;
      }
      i {
        font-size: 24px;
      }
    }
  }
`;
export const Filters = styled.div`
  display: flex;
  width: 100%;
  height: auto;
  flex-direction: row;
  padding: 10px;

  button {
    margin-top: 24px;
    margin-left: 25px;
  }

  @media ${device.modileBreak} {
    flex-direction: column !important;

    > div {
      margin-bottom: 10px;
    }
    button {
      margin-top: 0px;
      margin-left: 0px;
    }
  }
`;
export const FilterDate = styled.div`
  display: flex;
  flex-direction: column;
  color: #000;

  input {
    display: block;
    width: 100%;
    height: calc(1.5em + 0.75rem + 2px);
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    position: relative;
    flex: 1 1 auto;
    margin-bottom: 0;
  }
`;

export const Panel = styled.div`
  margin: 0px;
  box-shadow: 0px 0px 10px 0px rgba(82, 63, 105, 0.05);
  background-color: #ffffff;

  div.container_action {
    border-bottom: 1px solid #ebedf2;
    padding: 0 10px;
    min-height: 40px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    h1 {
      font-size: 1.2rem;
      font-weight: 700;
      color: #464457;
      margin: 0;
    }
  }

  div.panel-container {
    width: 100%;
    padding: 5px;
    
    .table th {
      border-top: 0px !important;
      font-size: 13px;
      background-color: #ebedf2;
      border-color: #ebedf2;
      font-weight: 500;
    }
    .table td {
      padding: 3px;
      font-size: 12px;
      font-weight: 300;
    }

    .tabHdr {
      padding: 3px;
      font-size: 13px;
      font-weight: 700;
    }

    .tabRow {
      padding: 3px;
      font-size: 12px;
      font-weight: 300;
    }
  }

  div.panel-container300 {
    height: 300px;
    padding: 10px;
    
    .table th {
      border-top: 0px !important;
      font-size: 13px;
      background-color: #ebedf2;
      border-color: #ebedf2;
      font-weight: 500;
    }
    .table td {
      padding: 3px;
      font-size: 12px;
      font-weight: 300;
    }

    /*
    *
    * ==========================================
    * CUSTOM UTIL CLASSES
    * ==========================================
    *
    */
    .table-fixed tbody {
        height: 300px;
        overflow-y: auto;
        width: 100%;
    }
    
    .table-fixed thead,
    .table-fixed tbody,
    .table-fixed tr,
    .table-fixed td,
    .table-fixed th {
        display: block;
    }
    
    .table-fixed tbody td,
    .table-fixed tbody th,
    .table-fixed thead > tr > th {
        float: left;
        position: relative;
    
        &::after {
            content: '';
            clear: both;
            display: block;
        }
    }

    table.scroll {
      width: 100%;
      height: 100%;
      border-spacing: 0;
    }
  
    table.scroll thead, 
    table.scroll tbody, 
    table.scroll tr, 
    table.scroll th, 
    table.scroll td { 
      display: block; 
    }
  
    table.scroll thead tr {
      /* fallback 97% */
      width: 100%;
      /* minus scroll bar width 
      width: -webkit-calc(100% - 16px);
      width:    -moz-calc(100% - 16px);
      width:         calc(100% - 16px);
      */
    }
  
    table.scroll tr:after {  /* clearing float */
      content: ' ';
      display: block;
      visibility: hidden;
      clear: both;
    }
  
    table.scroll tbody {
      height: 75%;
      overflow-y: auto;
      overflow-x: auto;
    }
  
    table.scroll tbody.col03 td, 
    table.scroll thead.col03 th {
      width: 33%;  /* 33% is less than (100% / 3 cols) = 33.3% */
      float: left;
    }

    table.scroll tbody.col04 td, 
    table.scroll thead.col04 th {
      width: 24%;  /* 24% is less than (100% / 4 cols) = 25% */
      float: left;
    }

    table.scroll tbody.col05 td, 
    table.scroll thead.col05 th {
      width: 19%;  /* 19% is less than (100% / 5 cols) = 20% */
      float: left;
    }

    table.scroll tbody tr.footer {
      /* fallback */
      width: 97%;
      /* minus scroll bar width */
      width: -webkit-calc(100% - 16px);
      width:    -moz-calc(100% - 16px);
      width:         calc(100% - 16px);
    }

    .wid100pct {
      width: 100%;
    }

    .tabHdr {
      padding: 3px;
      font-size: 13px;
      background-color: #ebedf2;
      border-color: #ebedf2;
      font-weight: 500;
    }

    .tabRow {
      padding: 3px;
      font-size: 12px;
      font-weight: 300;
    }
  }

  .pagination li {
    margin-left: 0.4rem;
  }
  .pagination li a {
    border-radius: 3px;
    cursor: pointer;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    height: 1.75rem;
    min-width: 1.75rem;
    vertical-align: middle;
    padding: 0.5rem;
    text-align: center;
    position: relative;
    font-size: 0.8rem;
    line-height: 1rem;
    font-weight: 400;
    text-decoration: none !important;
    color: #464457;
  }

  .pagination li.disabled {
    background: #ebe9f2;
    color: #464457;
    cursor: no-drop;
  }
  .pagination li.disabled a {
    cursor: no-drop;
  }

  .pagination li.active {
    background: #b73d34;
    color: #fff;
  }

  .pagination li.active a {
    color: #fff;
  }
  .filter-form-text {
    font-size: 14px;
    font-weight: bold;
    color: #464457;
  }
  .filterButton {
    margin-left: 10px;
  }
`;
