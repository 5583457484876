import React, { Component } from "react";
import { Link } from "react-router-dom";
import moment from "moment";
import { CSVLink, CSVDownload } from "react-csv";
/* REDUX */
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import InvoicesActions from "../../store/ducks/invoices";

/*Styles*/
import { Container, Content, Panel } from "./styles";

/*Layout*/
//import { Table, Button, Form, Row, Col, InputGroup } from "react-bootstrap";
import { StickyTable, Row, Cell } from "react-sticky-table";

/*Components*/
import Header from "../../components/Header";
import TopBar from "../../components/TopBar";
import Loader from "../../components/Loader";

class FaturaDados extends Component {
  state = {
    filter: ""
  };

  componentDidMount() {
    const { getInvoiceData, getHistory } = this.props;
    const { client_id } = this.props.match.params;
    const { id } = this.props.match.params;
    getInvoiceData(client_id, id);
    getHistory(client_id, id);
  }

  render() {
    const { invoices } = this.props;
    const { client_id } = this.props.match.params;
    const { id } = this.props.match.params;
    var csvData = [
      [
        "Ident",
        "Nome",
        "Consumo Ponta(kWh)",
        "Consumo Fora de Ponta(kWh)",
        "Consumo Total (kWh)",
        "Valor de Rateio"
      ]
    ];
    if (invoices.invoiceData.length) {
      invoices.invoiceData.map(invoice => {
        csvData.push([
          invoice.consumer.SiglaNum,invoice.consumer.NomeFantasia,invoice.consumo_ponta.toLocaleString("pt-BR"),invoice.consumo_fora_de_ponta.toLocaleString("pt-BR"),invoice.consumo_total.toLocaleString("pt-BR"),invoice.valor.toLocaleString("pt-BR")
        ]);
        return invoice;
      });
    }
    return (
      <Container>
        {(!invoices.loadedData || !invoices.loadedHistory) && <Loader />}
        <Header />
        <Content style={{color: "black"}}>
          <TopBar title="Faturas - Dados" />
          <Panel>
            <div className="container_action">
              <h1>Histórico de alterações</h1>
            </div>
            {!invoices.history.length ? (
              <div className="textCenter">
                  <h3>Nenhum histórico de alterações encontrado</h3>
              </div>
                  ) : (
              <div className="panel-container" style={{width:"100%", height: "400px"}}>
                  <StickyTable responsive hover>
                    <Row className="textLeft tabHdr">
                      <Cell>Unidade</Cell>
                      <Cell>Nome</Cell>
                      <Cell>Consumo Ponta<br/>(kWh)</Cell>
                      <Cell>Consumo Fora de Ponta<br/>(kWh)</Cell>
                      <Cell>Consumo Ponta Atual<br/>(kWh)</Cell>
                      <Cell>Consumo Fora de Ponta Atual<br/>(kWh)</Cell>
                      <Cell>Data de atualização</Cell>
                    </Row>
                    {invoices.history.map(history => (
                      <Row key={history.id}>
                        <Cell className="textLeft tabRow">
                          {history.consumer.SiglaNum}
                        </Cell>
                        <Cell className="textLeft tabRow">
                            {history.consumer.NomeFantasia}
                        </Cell>
                        <Cell className="textRight tabRow">{history.consumo_ponta}</Cell>
                        <Cell className="textRight tabRow">
                          {history.consumo_fora_de_ponta}
                        </Cell>
                        <Cell className="textRight tabRow">
                          {history.consumo_ponta_novo}
                        </Cell>
                        <Cell className="textRight tabRow">
                          {history.consumo_fora_de_ponta_novo}
                        </Cell>
                        <Cell className="textLeft tabRow">
                          {moment(history.created_at).format("DD/MM/YYYY")}
                        </Cell>
                      </Row>
                    ))}
                  </StickyTable>
              </div>
            )}
          </Panel>
          <Panel>
            <div className="container_action">
              <h1>Lista de dados</h1>
              <div>
                 
                  &nbsp;&nbsp;
                {invoices.invoiceData.length && (
                 
                  <CSVLink
                    filename={"fatura.csv"}
                    className="btn btn-outline-dark"
                    data={csvData}
                    separator={";"}
                  >
                    <i class="fa fa-file-excel-o" aria-hidden="true" /> Exportar
                  </CSVLink>
                )}
                &nbsp;&nbsp;
                <Link className="btn btn-success" to={`/faturas`}>
                  <i className="fa fa fa-arrow-left" /> &nbsp; Voltar
                </Link>
              </div>
            </div>
            {!invoices.invoiceData.length ? (
              <div className="textCenter">
                  <h3>Nenhum dado encontrado</h3>
              </div>
              ) : (
              <div className="panel-container" style={{width: "100%",height:"400px"}}>
                <StickyTable responsive hover>
                 <Row className="textLeft tabHdr">
                    <Cell>Unidade</Cell>
                    <Cell>Nome</Cell>
                    <Cell>Valor do rateio</Cell>
                    <Cell>Consumo Ponta<br/>(kWh)</Cell>
                    <Cell>Consumo Fora de Ponta<br/>(kWh)</Cell>
                    <Cell>Consumo Total<br/>(kWh)</Cell>
                    <Cell>Editar</Cell>
                  </Row>
                  {invoices.invoiceData.map(invoice => (
                    <Row key={invoice.id}>
                      <Cell className="textLeft tabRow">
                        {invoice.consumer.SiglaNum}
                      </Cell>
                      <Cell className="textLeft tabRow">
                        {invoice.consumer.NomeFantasia}
                      </Cell>
                      <Cell className="textRight tabRow">
                        R${invoice.valor.toLocaleString("pt-BR")}
                      </Cell>
                      <Cell className="textRight tabRow">
                        {invoice.consumo_ponta.toLocaleString("pt-BR")}
                      </Cell>
                      <Cell className="textRight tabRow">
                        {invoice.consumo_fora_de_ponta.toLocaleString("pt-BR")}
                      </Cell>
                      <Cell className="textRight tabRow">
                        {invoice.consumo_total.toLocaleString("pt-BR")}
                      </Cell>
                      <Cell className="textCenter tabRow">
                        <Link
                          to={`/faturas/${client_id}/dados/${id}/editar/${invoice.id}`}
                        >
                          <i className="fa fa-edit" />
                        </Link>
                      </Cell>
                    </Row>
                    ))}
                </StickyTable>
              </div>
            )}
          </Panel>
      </Content>
    </Container>
    );
  }
}

const mapStateToProps = state => ({
  invoices: state.invoices
});
const mapDispatchToProps = dispatch =>
  bindActionCreators({ ...InvoicesActions }, dispatch);
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(FaturaDados);
