import { createReducer, createActions } from "reduxsauce";
import Immutable from "seamless-immutable";

const { Types, Creators } = createActions({
  signInRequest: ["email", "password"], // nossa action e os parametros que ela recebe
  signInSuccess: ["data"],
  signOut: null,
  setSignedOut: null,
  signUpRequest: ["name", "email", "password"],
  getPermissions: null,
  getPermissionsSuccess: ["userName", "roles", "permissions"],
  forgotRequest: ["email"],
  changePasswordRequest: ["token", "password"],
  hideLoader: null
});
export const AuthTypes = Types;
export default Creators;
export const INITIAL_STATE = Immutable({
  signedIn: !!localStorage.getItem("@QED:token"),
  token: localStorage.getItem("@QED:token") || null,
  userName: localStorage.getItem("@QED:userName") || null,
  roles: [],
  permissions: [],
  loader: false,
  email: localStorage.getItem("@QED:email") || null
});
export const loading = state => state.merge({ loader: true });
export const hideLoader = state => state.merge({ loader: false });
export const success = (state, { data }) =>
  state.merge({ signedIn: true, token: data.token.token });

export const logout = state =>
  state.merge({
    signedIn: false,
    token: null,
    userName: "",
    roles: [],
    permissions: []
  });

export const permissionsSuccess = (state, { userName, roles, permissions }) =>
  state.merge({ userName, roles, permissions });

export const reducer = createReducer(INITIAL_STATE, {
  [Types.SIGN_IN_REQUEST]: loading,
  [Types.FORGOT_REQUEST]: loading,
  [Types.SIGN_IN_SUCCESS]: success,
  [Types.GET_PERMISSIONS_SUCCESS]: permissionsSuccess,
  [Types.SET_SIGNED_OUT]: logout,
  [Types.HIDE_LOADER]: hideLoader
});
