import { all, fork, takeLatest } from "redux-saga/effects";

/* AUTENTICACAO */
import { AuthTypes } from "../ducks/auth";
import {
  signIn,
  forgot,
  changePassword,
  signOut,
  getPermissions
} from "./auth";
/* PROJETOS */
import { ProjectTypes } from "../ducks/project";
import {
  getProjects,
  createProject,
  getProject,
  updateProject,
  deleteProject,
  getProjectPoints,
  createPoint,
  getPoint,
  updatePoint,
  deletePoint
} from "./project";
/* COLETORES */
import { ColetoresTypes } from "../ducks/coletores";
import {
  getColetores,
  getTypes,
  createColetor,
  getColetor,
  updateColetor,
  deleteColetor
} from "./coletores";
/*USERS */
import { UsersTypes } from "../ducks/users";
import { getUsers, createUser, getUser, updateUser, deleteUser } from "./users";
/* DASHBOARD */
import { DashBoardTypes } from "../ducks/dashboard";
import { getChatData, getMaxMin, getTotal, createFatura } from "./dashboard";

import { ConsumidoresTypes } from "../ducks/consumidores";
import {
  getConsumidores,
  getConsByCliWpt,
  createConsumidor,
  getConsumidor,
  updateConsumidor,
  deleteConsumidor
} from "./consumidores";

import { EquipamentosTypes } from "../ducks/equipamentos";
import {
  getEquipamentos,
  createEquipamento,
  getEquipamento,
  updateEquipamento,
  deleteEquipamento,
  getEquipTypes
} from "./equipamentos";

import { PontosTypes } from "../ducks/pontos";
import {
  getPontos,
  createPonto,
  getPonto,
  updatePonto,
  deletePonto,
  getColetoresPontos,
  getConsumidoresPontos,
  getValores,
  getUnidades
} from "./pontos";

import { InvoicesTypes } from "../ducks/invoices";
import {
  getClients,
  getInvoices,
  getInvoiceData,
  getInvoiceDataEdit,
  updateData,
  getHistory,
  deleteFatura,
  getCostumersChart,
  getInvoiceChartData
} from "./invoices";
export default function* rootSaga() {
  return yield all([
    fork(getPermissions),
    takeLatest(AuthTypes.SIGN_IN_REQUEST, signIn),
    takeLatest(AuthTypes.SIGN_OUT, signOut),
    takeLatest(AuthTypes.FORGOT_REQUEST, forgot),
    takeLatest(AuthTypes.CHANGE_PASSWORD_REQUEST, changePassword),
    /* PROJETOS */
    takeLatest(ProjectTypes.GET_PROJECTS_REQUEST, getProjects),
    takeLatest(ProjectTypes.CREATE_PROJECT, createProject),
    takeLatest(ProjectTypes.GET_PROJECT, getProject),
    takeLatest(ProjectTypes.UPDATE_PROJECT, updateProject),
    takeLatest(ProjectTypes.DELETE_PROJECT, deleteProject),

    /*COLETORES*/

    takeLatest(ColetoresTypes.GET_COLETORES, getColetores),
    takeLatest(ColetoresTypes.GET_TYPES, getTypes),
    takeLatest(ColetoresTypes.CREATE_COLETOR, createColetor),
    takeLatest(ColetoresTypes.GET_COLETOR, getColetor),
    takeLatest(ColetoresTypes.UPDATE_COLETOR, updateColetor),
    takeLatest(ColetoresTypes.DELETE_COLETOR, deleteColetor),

    /*Consumidores*/
    takeLatest(ConsumidoresTypes.GET_CONSUMIDORES, getConsumidores),
    takeLatest(ConsumidoresTypes.GET_CONS_BY_CLI_WPT, getConsByCliWpt),
    takeLatest(ConsumidoresTypes.CREATE_CONSUMIDOR, createConsumidor),
    takeLatest(ConsumidoresTypes.GET_CONSUMIDOR, getConsumidor),
    takeLatest(ConsumidoresTypes.UPDATE_CONSUMIDOR, updateConsumidor),
    takeLatest(ConsumidoresTypes.DELETE_CONSUMIDOR, deleteConsumidor),

    /*Equipamentos*/
    takeLatest(EquipamentosTypes.GET_EQUIPAMENTOS, getEquipamentos),
    takeLatest(EquipamentosTypes.CREATE_EQUIPAMENTO, createEquipamento),
    takeLatest(EquipamentosTypes.GET_EQUIPAMENTO, getEquipamento),
    takeLatest(EquipamentosTypes.UPDATE_EQUIPAMENTO, updateEquipamento),
    takeLatest(EquipamentosTypes.DELETE_EQUIPAMENTO, deleteEquipamento),
    takeLatest(EquipamentosTypes.GET_EQUIP_TYPES, getEquipTypes),

    /* DASHBOARD */
    takeLatest(DashBoardTypes.GET_CHART_DATA, getChatData),
    takeLatest(DashBoardTypes.GET_MAX_MIN, getMaxMin),
    takeLatest(DashBoardTypes.GET_TOTAL, getTotal),
    takeLatest(DashBoardTypes.CREATE_FATURA, createFatura),

    /* USUARIOS */
    takeLatest(UsersTypes.GET_USERS_REQUEST, getUsers),
    takeLatest(UsersTypes.CREATE_USER, createUser),
    takeLatest(UsersTypes.GET_USER, getUser),
    takeLatest(UsersTypes.UPDATE_USER, updateUser),
    takeLatest(UsersTypes.DELETE_USER, deleteUser),

    /* PONTOS */
    takeLatest(PontosTypes.GET_PONTOS, getPontos),
    takeLatest(PontosTypes.CREATE_PONTO, createPonto),
    takeLatest(PontosTypes.GET_PONTO, getPonto),
    takeLatest(PontosTypes.UPDATE_PONTO, updatePonto),
    takeLatest(PontosTypes.DELETE_PONTO, deletePonto),
    takeLatest(PontosTypes.GET_COLETORES_POINTS, getColetoresPontos),
    takeLatest(PontosTypes.GET_CONSUMIDORES_POINTS, getConsumidoresPontos),

    takeLatest(PontosTypes.GET_UNIDADES, getUnidades),
    takeLatest(PontosTypes.GET_VALORES, getValores),

    /* FATURAS */
    takeLatest(InvoicesTypes.GET_CLIENTS, getClients),
    takeLatest(InvoicesTypes.GET_INVOICES, getInvoices),
    takeLatest(InvoicesTypes.GET_INVOICE_DATA, getInvoiceData),
    takeLatest(InvoicesTypes.GET_INVOICE_DATA_EDIT, getInvoiceDataEdit),
    takeLatest(InvoicesTypes.UPDATE_DATA, updateData),
    takeLatest(InvoicesTypes.GET_HISTORY, getHistory),
    takeLatest(InvoicesTypes.DELETE_FATURA, deleteFatura),
    takeLatest(InvoicesTypes.GET_COSTUMERS_CHART, getCostumersChart),
    takeLatest(InvoicesTypes.GET_INVOICE_CHART_DATA, getInvoiceChartData),

  ]);
}
