import React, { Component } from "react";
import { Link } from "react-router-dom";

import InputMask from "react-input-mask";
/* REDUX */
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import ProjectsActions from "../../store/ducks/project";
import ErrorsActions from "../../store/ducks/errors";

/*Styles*/
import { Container, Content, Panel } from "./styles";

/*Layout*/
import { Row, Col } from "react-grid-system";

/*Components*/
import Header from "../../components/Header";
import TopBar from "../../components/TopBar";
import Is from "../../components/Is";
import { Button, Form } from "react-bootstrap";

class ProjectAdd extends Component {
  state = {
    Sigla: "",
    Nome: "",
    CNPJ: "",
    Endereco: "",
    Complemento: "",
    CEP: "",
    Bairro: "",
    Cidade: "",
    Estado: "",
    NomeResp: "",
    Fone1: "",
    Fone2: "",
    Fone3: ""
  };

  componentDidMount() {
    const { clearError } = this.props;
    clearError();
  }

  handleInputChange = e => {
    this.setState({ [e.target.name]: e.target.value });
  };

  handleSubmit = e => {
    e.preventDefault();
    const { createProject } = this.props;
    createProject(this.state);
  };

  render() {
    const { errors } = this.props;
    return (
      <Container>
        <Header />
        <Content>
          <TopBar title="Cadastro de projeto" />
          <Is checkRole={"administrator"}>
            <Panel>
              <div className="container_action">
                <h1>Novo Projeto</h1>
                <Link className="btn btn-success" to="/projetos">
                  <i className="fa fa fa-arrow-left" /> &nbsp; Voltar
                </Link>
              </div>
              <div className="panel-container">
                <Form>
                  <Row>
                    <Form.Group
                      as={Col}
                      xs={12}
                      md={4}
                      lg={4}
                      controlId="formGridSigla"
                    >
                      <Form.Label>Sigla</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Informe a sigla"
                        name="Sigla"
                        onChange={this.handleInputChange}
                      />
                      {errors.data.Sigla && (
                        <span className="inputError">{errors.data.Sigla}</span>
                      )}
                    </Form.Group>
                    <Form.Group
                      as={Col}
                      xs={12}
                      md={4}
                      lg={4}
                      controlId="formGridName"
                    >
                      <Form.Label>Nome</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Informe o nome do projeto"
                        name="Nome"
                        onChange={this.handleInputChange}
                      />
                      {errors.data.Nome && (
                        <span className="inputError">{errors.data.Nome}</span>
                      )}
                    </Form.Group>

                    <Form.Group
                      as={Col}
                      xs={12}
                      md={4}
                      lg={4}
                      controlId="formGridCnpj"
                    >
                      <Form.Label>Cnpj</Form.Label>
                      <InputMask
                        mask="99.999.999/9999-99"
                        onChange={this.handleInputChange}
                      >
                        {inputProps => (
                          <Form.Control
                            type="text"
                            placeholder="Informe o cnpj"
                            name="CNPJ"
                          />
                        )}
                      </InputMask>
                      {errors.data.CNPJ && (
                        <span className="inputError">{errors.data.CNPJ}</span>
                      )}
                    </Form.Group>
                  </Row>
                  <Row>
                    <Col>
                      <h2>Endereço</h2>
                    </Col>
                  </Row>
                  <Row>
                    <Form.Group as={Col} controlId="formGridEndereco">
                      <Form.Label>Cep</Form.Label>
                      <InputMask
                        mask="99999-999"
                        onChange={this.handleInputChange}
                      >
                        {inputProps => (
                          <Form.Control
                            type="text"
                            placeholder="Informe o cep"
                            name="CEP"
                          />
                        )}
                      </InputMask>
                      {errors.data.CEP && (
                        <span className="inputError">{errors.data.CEP}</span>
                      )}
                    </Form.Group>

                    <Form.Group as={Col} controlId="formGridEnderecoLogradouro">
                      <Form.Label>Logradouro</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Informe o logradouro"
                        name="Endereco"
                        onChange={this.handleInputChange}
                      />
                      {errors.data.Endereco && (
                        <span className="inputError">
                          {errors.data.Endereco}
                        </span>
                      )}
                    </Form.Group>
                  </Row>

                  <Row>
                    <Form.Group as={Col} controlId="formGridEndereco2">
                      <Form.Label>Complemento</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Informe o complemento"
                        name="Complemento"
                        onChange={this.handleInputChange}
                      />
                      {errors.data.Complemento && (
                        <span className="inputError">
                          {errors.data.Complemento}
                        </span>
                      )}
                    </Form.Group>

                    <Form.Group
                      as={Col}
                      controlId="formGridEnderecoLogradouro3"
                    >
                      <Form.Label>Bairro</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Informe o bairro"
                        name="Bairro"
                        onChange={this.handleInputChange}
                      />
                      {errors.data.Bairro && (
                        <span className="inputError">{errors.data.Bairro}</span>
                      )}
                    </Form.Group>
                  </Row>

                  <Row>
                    <Form.Group as={Col} controlId="formGridEndereco4">
                      <Form.Label>Cidade</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Informe o cidade"
                        name="Cidade"
                        onChange={this.handleInputChange}
                      />
                      {errors.data.Cidade && (
                        <span className="inputError">{errors.data.Cidade}</span>
                      )}
                    </Form.Group>

                    <Form.Group
                      as={Col}
                      controlId="formGridEnderecoLogradouro5"
                    >
                      <Form.Label>Estado</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Informe o estado"
                        name="Estado"
                        onChange={this.handleInputChange}
                      />
                      {errors.data.Estado && (
                        <span className="inputError">{errors.data.Estado}</span>
                      )}
                    </Form.Group>
                  </Row>
                  <Row>
                    <Col>
                      <h2>Contatos</h2>
                    </Col>
                  </Row>
                  <Row>
                    <Form.Group
                      as={Col}
                      xs={12}
                      md={4}
                      lg={4}
                      controlId="formGridEndereco6"
                    >
                      <Form.Label>Responsável</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Informe o responsável"
                        name="NomeResp"
                        onChange={this.handleInputChange}
                      />
                      {errors.data.NomeResp && (
                        <span className="inputError">
                          {errors.data.NomeResp}
                        </span>
                      )}
                    </Form.Group>

                    <Form.Group
                      as={Col}
                      xs={12}
                      md={4}
                      lg={4}
                      controlId="formGridEnderecoLogradouro8"
                    >
                      <Form.Label>Telefone 1</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Informe o telefone"
                        name="Fone1"
                        onChange={this.handleInputChange}
                      />
                      {errors.data.telefone_1 && (
                        <span className="inputError">{errors.data.Fone1}</span>
                      )}
                    </Form.Group>
                  </Row>

                  <Row>
                    <Form.Group
                      as={Col}
                      xs={6}
                      md={4}
                      lg={4}
                      controlId="formGridEnderecoLogradouro10"
                    >
                      <Form.Label>Telefone 2</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Informe o telefone"
                        name="Fone2"
                        onChange={this.handleInputChange}
                      />
                      {errors.data.Fone2 && (
                        <span className="inputError">{errors.data.Fone2}</span>
                      )}
                    </Form.Group>
                    <Form.Group
                      as={Col}
                      xs={6}
                      md={4}
                      lg={4}
                      controlId="formGridEnderecoLogradouro12"
                    >
                      <Form.Label>Celular</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Informe o celular"
                        name="Fone3"
                        onChange={this.handleInputChange}
                      />
                      {errors.data.Fone3 && (
                        <span className="inputError">{errors.data.Fone3}</span>
                      )}
                    </Form.Group>
                  </Row>

                  <Button
                    variant="primary"
                    type="submit"
                    onClick={this.handleSubmit}
                  >
                    Enviar
                  </Button>
                </Form>
              </div>
            </Panel>
          </Is>
        </Content>
      </Container>
    );
  }
}

const mapStateToProps = state => ({
  projects: state.project,
  errors: state.errors
});

const mapDispatchToProps = dispatch =>
  bindActionCreators({ ...ProjectsActions, ...ErrorsActions }, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(ProjectAdd);
