import React, { Component } from "react";
import { Link } from "react-router-dom";
import NumberFormat from "react-number-format";

/* REDUX */
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import EquipamentosActions from "../../store/ducks/equipamentos";
import ErrorsActions from "../../store/ducks/errors";

/*Styles*/
import { Container, Content, Panel } from "./tables-style";

/*Layout*/
import { Row, Col } from "react-grid-system";
import { Button, Form } from "react-bootstrap";

/*Components*/
import Is from "../../components/Is";
import Header from "../../components/Header";
import TopBar from "../../components/TopBar";
import Loader from "../../components/Loader";

class EquipamentoAdd extends Component {
  state = {
    IdEquip: "",
    Sigla: "",
    Descricao: "",
    NumSerie: "",
    Comunicacao: "",
    IdnaRede : "",
    Localizacao: "",
    IdTipoEquip : ""
  };

  componentDidMount() {
    const { clearError,getEquipTypes } = this.props;
    /* Tipos de Coletores */
    getEquipTypes();
    /* Limpa os Erros */
    clearError();
  }

  handleInputChange = e => {
    this.setState({ [e.target.name]: e.target.value });
  };

  handleSubmit = e => {
    e.preventDefault();
    const { createEquipamento } = this.props;
    createEquipamento(this.state);
  };

  render() {
    const { errors, equipamentos } = this.props;
    return (
      <Is checkRole="administrator">
        <Container>
          {!equipamentos.loaded && <Loader />}
          <Header />
          <Content>
            <TopBar title="Cadastro de equipamento" />
            <Panel>
              <div className="container_action">
                <h1>Novo equipamento</h1>
                <Link className="btn btn-success" to={`/equipamentos`}>
                  <i className="fa fa fa-arrow-left" /> &nbsp; Voltar
                </Link>
              </div>
              <div className="panel-container">
                <Form>
                  <Row>

                    <Form.Group
                      as={Col}
                      xs={12}
                      md={6}
                      controlId="formGridSigla"
                    >
                      <Form.Label>Sigla</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Informe a sigla"
                        name="Sigla"
                        onChange={this.handleInputChange}
                      />
                      {errors.data.Sigla && (
                        <span className="inputError">{errors.data.Sigla}</span>
                      )}
                    </Form.Group>
                    
                    <Form.Group
                      as={Col}
                      xs={12}
                      md={6}
                      controlId="formGridNumSerie"
                    >
                      <Form.Label>Número de Série</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Informe o número de série"
                        name="NumSerie"
                        onChange={this.handleInputChange}
                      />
                      {errors.data.NumSerie && (
                        <span className="inputError">{errors.data.NumSerie}</span>
                      )}
                    </Form.Group>
                  </Row>
                  <Row>
                    <Form.Group
                        as={Col}
                        xs={12}
                        md={12}
                        controlId="formGridDescricao"
                      >
                        <Form.Label>Descrição</Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="Informe a descrição"
                          name="Descricao"
                          onChange={this.handleInputChange}
                        />
                        {errors.data.Descricao && (
                          <span className="inputError">{errors.data.Descricao}</span>
                        )}
                      </Form.Group>
                  </Row>

                  <Row>
                    <Form.Group
                      as={Col}
                      xs={12}
                      md={4}
                      lg={4}
                      controlId="formGridComunicacao"
                    >
                      <Form.Label>Comunicacao</Form.Label>
                      <Form.Control
                        name="Comunicacao"
                        as="select"
                        onChange={this.handleInputChange}
                      >
                        <option key="0" value="0">
                          Nenhuma
                        </option>
                        <option key="1" value="1">RS485</option>
                        <option key="2" value="2">ETHERNET</option>
                        <option key="3" value="3">Wifi</option>
                        <option key="4" value="4">LoRa Etc</option>
                      </Form.Control>
                      {errors.data.IdTipoCol && (
                        <span className="inputError">
                          {errors.data.IdTipoCol}
                        </span>
                      )}
                    </Form.Group>

                    <Form.Group
                    as={Col}
                    xs={12}
                    md={4}
                    lg={4}
                    controlId="formGridPassword"
                  >
                    <Form.Label>Tipo de equipamento</Form.Label>
                    <Form.Control
                      name="IdTipoEquip"
                      as="select"
                      onChange={this.handleInputChange}
                    >
                      <option key="0" value="0">
                        Selecione um tipo
                      </option>
                      {equipamentos.tipos.map(type => (
                        <option key={type.IdTipoEquip} value={type.IdTipoEquip}>
                          {type.Sigla}
                        </option>
                      ))}
                    </Form.Control>
                    {errors.data.IdTipoEquip && (
                      <span className="inputError">
                        {errors.data.IdTipoEquip}
                      </span>
                    )}
                  </Form.Group>

                  <Form.Group
                      as={Col}
                      xs={12}
                      md={4}
                      controlId="formIdNaRede"
                    >
                      <Form.Label>Id na Rede</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Informe o Id na Rede"
                        name="IdnaRede"
                        onChange={this.handleInputChange}
                      />
                      {errors.data.IdnaRede && (
                        <span className="inputError">{errors.data.IdnaRede}</span>
                      )}
                    </Form.Group>
                  </Row>

                  <Row>
                  <Form.Group
                      as={Col}
                      xs={12}
                      md={12}
                      controlId="formGridLocalizacao"
                    >
                      <Form.Label>Localização</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Localização"
                        name="Localizacao"
                        onChange={this.handleInputChange}
                      />
                      {errors.data.Localizacao && (
                        <span className="inputError">{errors.data.Localizacao}</span>
                      )}
                    </Form.Group>
                  </Row>

                  <Button
                    variant="primary"
                    type="submit"
                    onClick={this.handleSubmit}
                  >
                    Enviar
                  </Button>
                </Form>
              </div>
            </Panel>
          </Content>
        </Container>
      </Is>
    );
  }
}

const mapStateToProps = state => ({
  errors: state.errors,
  equipamentos: state.equipamentos
});

const mapDispatchToProps = dispatch =>
  bindActionCreators({ ...EquipamentosActions, ...ErrorsActions }, dispatch);
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EquipamentoAdd);
