import styled from "styled-components";
const size = {
  tablet: "768px"
};
export const device = {
  modileBreak: `(max-width: ${size.tablet})`
};
export const Container = styled.div`
  display: flex;
  -webkit-box-align: stretch;
  align-items: stretch;
  height: 100%;
  min-height: 100vh;
  background: #f0f0f0;
  max-width: 100%;
  h2 {
    font-size: 1.2rem;
    font-weight: 700;
    color: #464457;
    margin: 0;
    padding: 20px 0px;
    border-bottom: 1px dashed #cdcfd2;
    margin-bottom: 20px;
  }
  label {
    color: #000;
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  -webkit-box-align: stretch;
  align-items: stretch;
  position: relative;
  color: rgb(255, 255, 255);
  flex: 1 1 0%;
  background: #f2f3f8;
  max-width: 100%;
`;

export const Panel = styled.div`
  margin: 20px;
  box-shadow: 0px 0px 13px 0px rgba(82, 63, 105, 0.05);
  background-color: #ffffff;
  max-width: 100%;

  div.container_action {
    border-bottom: 1px solid #ebedf2;
    padding: 0 25px;
    min-height: 60px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    h1 {
      font-size: 1.2rem;
      font-weight: 700;
      color: #464457;
      margin: 0;
    }
    @media ${device.modileBreak} {
      flex-direction: column !important;
      padding: 30px 0px;
      text-align: center;

      a {
        margin-top: 20px;
      }
    }
  }
  .mr-10 {
    margin-right: 10px;
  }

  div.panel-container {
    padding: 25px;
    .table th {
      border-top: 0px !important;
      font-size: 13px;
      background-color: #ebedf2;
      border-color: #ebedf2;
      font-weight: 500;
    }
    .table td {
      font-weight: 300;
    }
  }
  .pagination li {
    margin-left: 0.4rem;
  }
  .pagination li a {
    border-radius: 3px;
    cursor: pointer;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    height: 1.75rem;
    min-width: 1.75rem;
    vertical-align: middle;
    padding: 0.5rem;
    text-align: center;
    position: relative;
    font-size: 0.8rem;
    line-height: 1rem;
    font-weight: 400;
    text-decoration: none !important;
    color: #000;
  }

  .pagination li.disabled {
    background: #ebe9f2;
    color: #000;
    cursor: no-drop;
  }
  .pagination li.disabled a {
    cursor: no-drop;
  }

  .pagination li.active {
    background: #b73d34;
    color: #fff;
  }

  .pagination li.active a {
    color: #fff;
  }
  .filter-form-text {
    font-size: 14px;
    font-weight: bold;
    color: #020202;
  }
  .filterButton {
    margin-left: 10px;
  }
`;
