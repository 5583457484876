import { call, put } from "redux-saga/effects";
import { push } from "connected-react-router";
import { actions as toastrAction } from "react-redux-toastr";
import api from "../../services/api";
import UsersActions from "../ducks/users";
import ErrorsActions from "../ducks/errors";

export function* getUsers({ page, filter }) {
  try {
    const paginate = page === undefined ? 1 : page;
    const filtro = filter === undefined ? "" : filter;
    const response = yield call(
      api.get,
      "users?page=" + paginate + "&filter=" + filtro
    );
    yield put(UsersActions.getUsersSuccess(response.data));
  } catch (err) {
    console.log(err);
    yield put(
      toastrAction.add({
        type: "error",
        title: "Ops...",
        message: "Não foi possível retornar os usuários, tente novamente",
        closeOnToastrClick: true,
        position: "top-center",
        options: {
          showCloseButton: true,
          closeOnToastrClick: true,
          progressBar: true,
          transitionOut: "fadeOut"
        }
      })
    );
  }
}
export function* createUser({ data }) {
  try {
    yield call(api.post, "users", data);
    yield put(push("/usuarios"));
  } catch (err) {
    if (err.response.data.message !== undefined) {
      yield put(UsersActions.hideLoader());
      yield put(ErrorsActions.setError(err.response.data.message));
      yield put(
        toastrAction.add({
          type: "error",
          title: "Ocorreu um erro",
          message: "Verifique os dados inseridos e tente novamente",
          closeOnToastrClick: true,
          position: "top-center",
          options: {
            showCloseButton: true,
            closeOnToastrClick: true,
            progressBar: true,
            transitionOut: "fadeOut"
          }
        })
      );
    }
  }
}

export function* getUser({ id }) {
  try {
    const response = yield call(api.get, `users/${id}`);
    yield put(UsersActions.setUser(response.data));
  } catch (err) {
    yield put(
      toastrAction.add({
        type: "error",
        title: "Ops...",
        message: "Não foi possível carregar os dados, tente novamente",
        closeOnToastrClick: true,
        position: "top-center",
        options: {
          showCloseButton: true,
          closeOnToastrClick: true,
          progressBar: true,
          transitionOut: "fadeOut"
        }
      })
    );
    yield put(push("/usuarios"));
  }
}

export function* updateUser({ data }) {
  try {
    if (!data.id) {
      throw new Error("Código invalido, contate a equipe de suporte");
    }
    yield call(api.put, `users/${data.id}`, data);
    yield put(push("/usuarios"));
  } catch (err) {
    if (err.response.data.message !== undefined) {
      yield put(ErrorsActions.setError(err.response.data.message));
      yield put(UsersActions.hideLoader());
    }
    yield put(
      toastrAction.add({
        type: "error",
        title: "Ops...",
        message: "Não foi possível editar o usuário, tente novamente",
        closeOnToastrClick: true,
        position: "top-center",
        options: {
          showCloseButton: true,
          closeOnToastrClick: true,
          progressBar: true,
          transitionOut: "fadeOut"
        }
      })
    );
  }
}

export function* deleteUser({ id }) {
  try {
    const response = yield call(api.delete, `users/${id}`);
    yield put(UsersActions.deleteUserSuccess(response.data.user));
  } catch (err) {
    yield put(
      toastrAction.add({
        type: "error",
        title: "Ops...",
        message: "Não foi possível editar o usuário, tente novamente",
        closeOnToastrClick: true,
        position: "top-center",
        options: {
          showCloseButton: true,
          closeOnToastrClick: true,
          progressBar: true,
          transitionOut: "fadeOut"
        }
      })
    );
    yield put(push("/usuarios"));
  }
}
