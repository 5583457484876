import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import PropTypes from "prop-types";
import AuthActions from "../../../store/ducks/auth";
import {Button,AnyButton} from "../../../styles/components/Button";
import { Container, SignForm } from "../auth_styles";

import { Link } from "react-router-dom";
class Forgot extends Component {
  state = {
    email: ""
  };

  static propTypes = {
    forgotRequest: PropTypes.func.isRequired
  };

  handleSubmit = e => {
    e.preventDefault();
    const { email } = this.state;
    const { forgotRequest } = this.props;
    forgotRequest(email);
  };

  handleInputChange = e => {
    this.setState({ [e.target.name]: e.target.value });
  };

  render() {
    const { auth } = this.props;
    const { email } = this.state;
    return (

      <Container>
        <SignForm onSubmit={this.handleSubmit}>
          <h1>
            Q<span>&</span>D<br />
            Recuperar Senha
          </h1>
          <span>Email</span>
          <input
            type="email"
            name="email"
            onChange={this.handleInputChange}
            value={email}
            maxlength="64"
            required
          />
          {auth.loader ? (
            <AnyButton size="big" color="danger" type="submit">
              <i className="fa fa-circle-o-notch fa-spin fa-1x fa-fw" />
            </AnyButton>
          ) : (
            <AnyButton size="big" color="danger" type="submit">
              Solicitar Codigo de Acesso
            </AnyButton>
          )}
          <Link to="/reset_password">Alterar Senha</Link>
          <Link to="/login">Voltar para login</Link>
        </SignForm>
      </Container>
    );
  }
}
const mapStateToProps = state => ({
  auth: state.auth
});
const mapDispatchToProps = dispatch =>
  bindActionCreators(AuthActions, dispatch);
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Forgot);
