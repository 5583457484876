import React, { Component } from "react";
import { Link } from "react-router-dom";

/* REDUX */
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import EquipamentosActions from "../../store/ducks/equipamentos";
import ErrorsActions from "../../store/ducks/errors";

/*Styles*/
import { Container, Content, Panel } from "./tables-style";


/*Layout*/
import { Row, Col } from "react-grid-system";

/*Components*/
import Header from "../../components/Header";
import TopBar from "../../components/TopBar";
import { Button, Form } from "react-bootstrap";
import Loader from "../../components/Loader";

class EquipamentoEdit extends Component {
  
  state = {
    IdEquip: this.props.equipamentos.currentEquipamento.IdEquip || "",
    Sigla: this.props.equipamentos.currentEquipamento.Sigla || "",
    Descricao: this.props.equipamentos.currentEquipamento.Descricao || "",
    NumSerie: this.props.equipamentos.currentEquipamento.NumSerie || "",
    Comunicacao: this.props.equipamentos.currentEquipamento.Comunicacao || "",
    IdnaRede: this.props.equipamentos.currentEquipamento.IdnaRede || "",
    Localizacao: this.props.equipamentos.currentEquipamento.Localizacao || "",
    IdTipoEquip: this.props.equipamentos.currentEquipamento.IdTipoEquip || ""
  };

  componentDidMount() {
    const { id } = this.props.match.params;
    const { clearError, getEquipTypes, getEquipamento } = this.props;
    /* Tipos de Coletores */
    getEquipTypes();
    /* Busca o coletor */
    getEquipamento(id);
    /* Limpa os Erros */
    clearError();
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.equipamentos.currentEquipamento.IdEquip !==
      this.props.equipamentos.currentEquipamento.IdEquip
    ) {
      this.setState({
        IdEquip: this.props.equipamentos.currentEquipamento.IdEquip || "",
        Sigla: this.props.equipamentos.currentEquipamento.Sigla || "",
        Descricao: this.props.equipamentos.currentEquipamento.Descricao || "",
        NumSerie: this.props.equipamentos.currentEquipamento.NumSerie || "",
        Comunicacao: this.props.equipamentos.currentEquipamento.Comunicacao || "",
        IdnaRede: this.props.equipamentos.currentEquipamento.IdnaRede || "",
        Localizacao: this.props.equipamentos.currentEquipamento.Localizacao || "",
        IdTipoEquip: this.props.equipamentos.currentEquipamento.IdTipoEquip || ""
      });
    }
  }

  handleInputChange = e => {
    console.log(e.target.value);
    console.log(e.target.name);
    this.setState({ [e.target.name]: e.target.value });
  };

  handleSubmit = e => {
    e.preventDefault();
    const { updateEquipamento } = this.props;
    updateEquipamento(this.state);
  };

  render() {
    const { errors, equipamentos } = this.props;
    const { id } = this.props.match.params;
    return (
      <Container>
       {!equipamentos.loaded && <Loader />}
        <Header />
        <Content>
          <TopBar title="Edição de coletor" />
          <Panel>
            <div className="container_action">
              <h1>Editar equipamento</h1>
              <Link className="btn btn-success" to={`/equipamentos`}>
                <i className="fa fa fa-arrow-left" /> &nbsp; Voltar
              </Link>
            </div>
            <div className="panel-container">
              <Form>
              <Row>

<Form.Group
  as={Col}
  xs={12}
  md={6}
  controlId="formGridSigla"
>
  <Form.Label>Sigla</Form.Label>
  <Form.Control
    type="text"
    placeholder="Informe a sigla"
    name="Sigla"
    onChange={this.handleInputChange}
    value={this.state.Sigla}
  />
  {errors.data.Sigla && (
    <span className="inputError">{errors.data.Sigla}</span>
  )}
</Form.Group>

<Form.Group
  as={Col}
  xs={12}
  md={6}
  controlId="formGridNumSerie"
>
  <Form.Label>Número de Série</Form.Label>
  <Form.Control
    type="text"
    placeholder="Informe o número de série"
    name="NumSerie"
    value={this.state.NumSerie}
    onChange={this.handleInputChange}
  />
  {errors.data.NumSerie && (
    <span className="inputError">{errors.data.NumSerie}</span>
  )}
</Form.Group>
</Row>
<Row>
<Form.Group
    as={Col}
    xs={12}
    md={12}
    controlId="formGridDescricao"
  >
    <Form.Label>Descrição</Form.Label>
    <Form.Control
      type="text"
      placeholder="Informe a descrição"
      name="Descricao"
      value={this.state.Descricao}
      onChange={this.handleInputChange}
    />
    {errors.data.Descricao && (
      <span className="inputError">{errors.data.Descricao}</span>
    )}
  </Form.Group>
</Row>

<Row>
<Form.Group
  as={Col}
  xs={12}
  md={4}
  lg={4}
  controlId="formGridComunicacao"
>
  <Form.Label>Comunicacao</Form.Label>
  <Form.Control
    name="Comunicacao"
    as="select"
    value={this.state.Comunicacao}
    onChange={this.handleInputChange}
  >
    <option key="0" value="0">
      Nenhuma
    </option>
    <option key="1" value="1">RS485</option>
    <option key="2" value="2">ETHERNET</option>
    <option key="3" value="3">Wifi</option>
    <option key="4" value="4">LoRa Etc</option>
  </Form.Control>
  {errors.data.IdTipoCol && (
    <span className="inputError">
      {errors.data.IdTipoCol}
    </span>
  )}
</Form.Group>

<Form.Group
as={Col}
xs={12}
md={4}
lg={4}
controlId="formGridPassword"
>
<Form.Label>Tipo de equipamento</Form.Label>
<Form.Control
  name="IdTipoEquip"
  as="select"
  value={this.state.IdTipoEquip}
  onChange={this.handleInputChange}
>
  <option key="0" value="0">
    Selecione um tipo
  </option>
  {equipamentos.tipos.map(type => (
    <option key={type.IdTipoEquip} value={type.IdTipoEquip}>
      {type.Sigla}
    </option>
  ))}
</Form.Control>
{errors.data.IdTipoEquip && (
  <span className="inputError">
    {errors.data.IdTipoEquip}
  </span>
)}
</Form.Group>

<Form.Group
  as={Col}
  xs={12}
  md={4}
  controlId="formIdNaRede"
>
  <Form.Label>Id na Rede</Form.Label>
  <Form.Control
    type="text"
    placeholder="Informe o Id na Rede"
    name="IdnaRede"
    value={this.state.IdnaRede}
    onChange={this.handleInputChange}
  />
  {errors.data.IdnaRede && (
    <span className="inputError">{errors.data.IdnaRede}</span>
  )}
</Form.Group>
</Row>

<Row>
<Form.Group
  as={Col}
  xs={12}
  md={12}
  controlId="formGridLocalizacao"
>
  <Form.Label>Localização</Form.Label>
  <Form.Control
    type="text"
    placeholder="Localização"
    name="Localizacao"
    value={this.state.Localizacao}
    onChange={this.handleInputChange}
  />
  {errors.data.Localizacao && (
    <span className="inputError">{errors.data.Localizacao}</span>
  )}
</Form.Group>
</Row>

<Button
variant="primary"
type="submit"
onClick={this.handleSubmit}
>
Enviar
</Button>
              </Form>
            </div>
          </Panel>
        </Content>
      </Container>
    );
  }
}

const mapStateToProps = state => ({
  errors: state.errors,
  equipamentos: state.equipamentos
});

const mapDispatchToProps = dispatch =>
  bindActionCreators({ ...EquipamentosActions, ...ErrorsActions }, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(EquipamentoEdit);
