import { createReducer, createActions } from "reduxsauce";
import Immutable from "seamless-immutable";
const { Types, Creators } = createActions({
  getProjectsRequest: ["page", "filter"], //busca os projetos do usuário
  getProjectsSuccess: ["dados"], //retorno da busca dos projetos
  createProject: ["data"], //cadastra novo projeto
  getProject: ["id"], //busca o projeto
  loader: null, //ativa loader
  setProject: ["data"],
  updateProject: ["data"],
  deleteProject: ["id"],
  deleteProjectSuccess: ["projectData"],
  getProjectPoints: ["id"],
  getProjectsPointsSuccess: ["pointsData"],
  createPoint: ["data"],
  getPoint: ["id"],
  setPoint: ["data"],
  updatePoint: ["data"],
  deletePoint: ["id"],
  hideLoader: null,
  showModal: null,
  hideModal: null
});
export const ProjectTypes = Types;
export default Creators;
export const INITIAL_STATE = Immutable({
  loaded: false,
  data: [],
  projectInfo: {},
  currentProject: {},
  pointsData: [],
  currentPoint: {},
  modal: false
});

/*Retorno de projetos*/
export const success = (state, { dados }) => {
  const { data, ...projectInfo } = dados;
  return state.merge({
    ...state,
    data: data,
    projectInfo: projectInfo,
    loaded: true
  });
};
/*Carrega o loader*/
export const beforePaginate = (state, { page, filter }) => {
  return state.merge({ ...state, loaded: false });
};
/* Seta o projeto a ser editado */
export const getProject = (state, { data }) => {
  return state.merge({ ...state, currentProject: {} });
};
/* Seta o projeto a ser editado */
export const setProject = (state, { data }) => {
  return state.merge({ ...state, currentProject: data, loaded: true });
};

export const deleteProjectSuccess = (state, { projectData }) => {
  const id = projectData.IdCliente;
  const status = projectData.status;
  return state.merge({
    data: state.data.map(project =>
      project.IdCliente === id ? { ...project, status: status } : project
    ),
    modal: false
  });
};
export const pointsSuccess = (state, { pointsData }) => {
  return state.merge({ pointsData });
};
export const setPoint = (state, { data }) => {
  localStorage.setItem("@QED:currentPoint", JSON.stringify(data));
  return state.merge({ ...state, currentPoint: data });
};
/* Esconde o loader */
export const hideLoader = (state, { page, filter }) => {
  return state.merge({ ...state, loaded: true });
};

export const showModal = (state, { data }) => {
  return state.merge({
    ...state,
    modal: true
  });
};
export const hideModal = (state, { data }) => {
  return state.merge({
    ...state,
    modal: false
  });
};
export const reducer = createReducer(INITIAL_STATE, {
  [Types.LOADER]: beforePaginate,
  [Types.GET_PROJECTS_REQUEST]: beforePaginate,
  [Types.GET_PROJECTS_SUCCESS]: success,
  [Types.GET_PROJECT]: getProject,
  [Types.SET_PROJECT]: setProject,
  [Types.DELETE_PROJECT_SUCCESS]: deleteProjectSuccess,
  [Types.GET_PROJECTS_POINTS_SUCCESS]: pointsSuccess,
  [Types.SET_POINT]: setPoint,
  [Types.HIDE_LOADER]: hideLoader,
  [Types.SHOW_MODAL]: showModal,
  [Types.HIDE_MODAL]: hideModal
});
