import { createReducer, createActions } from "reduxsauce";
import Immutable from "seamless-immutable";
const { Types, Creators } = createActions({
  setError: ["data"],
  clearError: null
});
export const ErrorsTypes = Types;
export default Creators;
export const INITIAL_STATE = Immutable({
  data: []
});

export const success = (state, { data }) => state.merge({ data });
export const clearError = state => state.merge({ data: [] });
export const reducer = createReducer(INITIAL_STATE, {
  [Types.SET_ERROR]: success,
  [Types.CLEAR_ERROR]: clearError
});
