import { call, put } from "redux-saga/effects";
import { push } from "connected-react-router";
import { actions as toastrAction } from "react-redux-toastr";
import api from "../../services/api";
import DashboardActions from "../ducks/dashboard";

export function* getChatData({ data }) {
  try {
    const response = yield call(api.post, `dashboard/${data.id}`, data);
    yield put(DashboardActions.setChartData(response.data));
  } catch (err) {
    console.log(err);
  }
}

export function* getMaxMin({ data }) {
  try {
    const response = yield call(api.post, `dashboard/maxMin/${data.id}`, data);
    yield put(DashboardActions.setMaxMin(response.data));
  } catch (err) {
    console.log(err);
  }
}

export function* getTotal({ data }) {
  try {
    const response = yield call(api.post, `dashboard/total/${data.id}`, data);
    yield put(DashboardActions.setTotal(response.data));
  } catch (err) {
    console.log(err);
  }
}

export function* createFatura({ data }) {
  try {
    const response = yield call(api.post, `fatura/cadastro/${data.id}`, data);
    yield put(DashboardActions.setFatura(response.data));
    yield put(
      toastrAction.add({
        type: "success",
        title: "Tudo certo!",
        message: "Fatura gerada com sucesso!",
        closeOnToastrClick: true,
        position: "top-center",
        options: {
          showCloseButton: true,
          closeOnToastrClick: true,
          progressBar: true,
          transitionOut: "fadeOut"
        }
      })
    );
  } catch (err) {
    yield put(
      toastrAction.add({
        type: "error",
        title: "Ops...",
        message: "Não foi possível cadastrar a fatura, tente novamente",
        closeOnToastrClick: true,
        position: "top-center",
        options: {
          showCloseButton: true,
          closeOnToastrClick: true,
          progressBar: true,
          transitionOut: "fadeOut"
        }
      })
    );
  }
}
