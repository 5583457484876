import { createReducer, createActions } from "reduxsauce";
import Immutable from "seamless-immutable";
const { Types, Creators } = createActions({
  showLoader: null,
  hideLoader: null,
  getEquipamentos: ["page", "filter"],
  setEquipamentos: ["dados"],
  createEquipamento: ["data"],
  getEquipamento: ["id", "consumidor"],
  setEquipamento: ["data"],
  updateEquipamento: ["data"],
  deleteEquipamento: ["id"],
  deleteSucc: ["data"],
  getEquipTypes: ["showLoader"],
  setEquipTypes: ["types"],
});
export const EquipamentosTypes = Types;
export default Creators;
export const INITIAL_STATE = Immutable({
  loaded: true,
  data: [],
  equipamentosInfo: {},
  currentEquipamento: {},
  tipos: []
});

/*Carrega o loader*/
export const showLoader = (state, {page, filter }) => {
  return state.merge({ ...state, loaded: false });
};

/* Esconde o loader */
export const hideLoader = (state, { page, filter }) => {
  return state.merge({ ...state, loaded: true });
};

export const setEquipamentos = (state, { dados }) => {
  const { data, ...equipamentosInfo } = dados;
  return state.merge({
    ...state,
    data: data,
    equipamentosInfo: equipamentosInfo,
    loaded: true
  });
};
export const setEquipTypes = (state, { types }) => {
  return state.merge({
    ...state,
    tipos: types,
    loaded: true
  });
};
export const setEquipamento = (state, { data }) => {
  return state.merge({
    ...state,
    currentEquipamento: data,
    loaded: true
  });
};
export const deleteSuccess = (state, { data }) => {
  return state.merge({
    ...state,
    data: state.data.filter(consumidor => {
      return consumidor.IdEquip != data.id;
    }),
    loaded: true
  });
};

export const reducer = createReducer(INITIAL_STATE, {
  [Types.SHOW_LOADER]: showLoader,
  [Types.HIDE_LOADER]: hideLoader,
  [Types.GET_EQUIPAMENTOS]: showLoader,
  [Types.SET_EQUIPAMENTOS]: setEquipamentos,
  [Types.CREATE_EQUIPAMENTO]: showLoader,
  [Types.GET_EQUIPAMENTO]: showLoader,
  [Types.SET_EQUIPAMENTO]: setEquipamento,
  [Types.UPDATE_EQUIPAMENTO]: showLoader,
  [Types.DELETE_EQUIPAMENTO]: showLoader,
  [Types.DELETE_SUCC]: deleteSuccess,
  [Types.GET_EQUIP_TYPES]: showLoader,
  [Types.SET_EQUIP_TYPES]: setEquipTypes
});
