import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import PropTypes from "prop-types";
import AuthActions from "../../../store/ducks/auth";
import { Button, AnyButton } from "../../../styles/components/Button";
import { Container, SignForm } from "../auth_styles";
import styled from "styled-components";
import img from "../../../assets/images/background_qed.png";
import { Link } from "react-router-dom";

class SignIn extends Component {
  /*
  constructor() {
    super();
    //this.handleClick = this.handleClick.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
  }
  */

  state = {
    email: "",
    password: "",
    init: 1
  };

  static propTypes = {
    signInRequest: PropTypes.func.isRequired
  };

  handleSubmit = e => {
    e.preventDefault();
    
    console.log('submit ',e);

    const { email, password } = this.state;
    const { signInRequest } = this.props;
    signInRequest(email, password);
  };

  handleInputChange = e => {
    this.setState({ [e.target.name]: e.target.value });
  };

  handleClick = e => {
    //e.preventDefault();
    console.log('click ', e);
    //debugger;
  };

  render() {
    const { auth } = this.props;
    const { email, password } = this.state;

    return (
      <Container init={this.state.init}>
        <SignForm onSubmit={this.handleSubmit}>
          <h1>
            Q<span>&</span>D <br />
            Sistemas Eletrônicos LTDA
          </h1>
          <span>EMAIL</span>
          <input
            type="email"
            name="email"
            onChange={this.handleInputChange}
            value={email}
            maxlength="64"
            required
          />
          <span>SENHA</span>
          <input
            type="password"
            name="password"
            onChange={this.handleInputChange}
            value={password}
            maxlength="64"
            required
          />
          {auth.loader ? (
            <AnyButton size="big" color="danger" type="submit">
              <i className="fa fa-circle-o-notch fa-spin fa-1x fa-fw" />
            </AnyButton>
          ) : (
            <AnyButton size="big" color="danger" type="submit">
              Entrar
            </AnyButton>
          )}
          <Link to="/password">Esqueceu sua senha?</Link>
        </SignForm>
      </Container>
    );
  }
 /*
       //<div style={{ backgroundImage: `url(${img})` }}>
      //</div>
  render() {
    const { auth } = this.props;
    const { email, password } = this.state;

    return (
        <form onSubmit={this.handleSubmit}>
          <h1>
            Q<span>&</span>D <br />
            Sistemas Eletrônicos
          </h1>
          <span>EMAIL</span>
          <input
            type="email"
            name="email"
            onChange={this.handleInputChange}
            value={email}
          />

          <span>SENHA</span>
          <input
            type="password"
            name="password"
            onChange={this.handleInputChange}
            value={password}
          />
          <button size="big" color="danger" type="submit">
            Entrar
          </button>

          <Link to="/password">Esqueceu sua senha?</Link>
        </form>
    );
  }
*/
}
const mapStateToProps = state => ({
  auth: state.auth
});
const mapDispatchToProps = dispatch =>
  bindActionCreators(AuthActions, dispatch);
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SignIn);
