import { createReducer, createActions } from "reduxsauce";
import Immutable from "seamless-immutable";
const { Types, Creators } = createActions({
  showLoader: null,
  hideLoader: null,
  getPontos: ["id", "page", "filter"],
  setPontos: ["dados"],
  createPonto: ["data"],
  getPonto: ["id", "ponto"],
  setPonto: ["data"],
  updatePonto: ["data"],
  deletePonto: ["id"],
  deleteSucc: ["data"],
  getConsumidoresPoints: ["id"],
  getColetoresPoints: ["id"],
  setConsumidoresPoints: ["data"],
  setColetoresPoints: ["data"],
  getValores: null,
  setValores: ["data"],
  getUnidades: null,
  setUnidades: ["data"],

});
export const PontosTypes = Types;
export default Creators;
export const INITIAL_STATE = Immutable({
  loaded: true,
  data: [],
  pontosInfo: {},
  currentPonto: {},
  consumidoresLoaded: true,
  coletoresLoaded: true,
  valoresLoaded: true,
  unidadesLoaded: true,
  coletores: [],
  consumidores: [],
  unidades: [],
  valores:[]
});

/*Carrega o loader*/
export const showLoader = (state, { id, page, filter }) => {
  return state.merge({ ...state, loaded: false });
};

/* Esconde o loader */
export const hideLoader = (state, { page, filter }) => {
  return state.merge({ ...state, loaded: true });
};

export const setPontos = (state, { dados }) => {
  const { data, ...pontosInfo } = dados;
  return state.merge({
    ...state,
    data: data,
    pontosInfo: pontosInfo,
    loaded: true
  });
};
export const setPonto = (state, { data }) => {
  return state.merge({
    ...state,
    currentPonto: data,
    loaded: true
  });
};
export const deleteSuccess = (state, { data }) => {
  return state.merge({
    ...state,
    data: state.data.filter(ponto => {
      return ponto.IdPonto != data.id;
    }),
    loaded: true
  });
};

export const getConsumidores = (state, { id }) => {
  return state.merge({ ...state, consumidoresLoaded: false });
};

export const getValores = (state) => {
  return state.merge({ ...state, valoresLoaded: false });
};

export const getUnidades = (state) => {
  return state.merge({ ...state, unidadesLoaded: false });
};

export const getColetores = (state, { id }) => {
  return state.merge({ ...state, coletoresLoaded: false });
};

export const setConsumidoresPoints = (state, { data }) => {
  return state.merge({
    ...state,
    consumidores: data,
    consumidoresLoaded: true
  });
};

export const setColetoresPoints = (state, { data }) => {
  return state.merge({
    ...state,
    coletores: data,
    coletoresLoaded: true
  });
};

export const setValores = (state, { data }) => {
  return state.merge({
    ...state,
    valores: data,
    valoresLoaded: true
  });
};

export const setUnidades = (state, { data }) => {
  return state.merge({
    ...state,
    unidades: data,
    unidadesLoaded: true
  });
};

export const reducer = createReducer(INITIAL_STATE, {
  [Types.SHOW_LOADER]: showLoader,
  [Types.HIDE_LOADER]: hideLoader,
  [Types.GET_PONTOS]: showLoader,
  [Types.SET_PONTOS]: setPontos,
  [Types.CREATE_PONTO]: showLoader,
  [Types.GET_PONTO]: showLoader,
  [Types.SET_PONTO]: setPonto,
  [Types.UPDATE_PONTO]: showLoader,
  [Types.DELETE_PONTO]: showLoader,
  [Types.DELETE_SUCC]: deleteSuccess,
  [Types.GET_CONSUMIDORES_POINTS]: getConsumidores,
  [Types.GET_COLETORES_POINTS]: getColetores,
  [Types.SET_CONSUMIDORES_POINTS]: setConsumidoresPoints,
  [Types.SET_COLETORES_POINTS]: setColetoresPoints,
  [Types.SET_VALORES]: setValores,
  [Types.SET_UNIDADES]: setUnidades,
  [Types.GET_VALORES]: getValores,
  [Types.GET_UNIDADES]: getUnidades
});
