import React, { Component } from "react";
import { Link } from "react-router-dom";
import api from "../../services/api";

/* REDUX */
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import UsersActions from "../../store/ducks/users";
import ErrorsActions from "../../store/ducks/errors";

/*Styles*/
import { Container, Content, Panel } from "./styles";

/*Layout*/
import { Row, Col } from "react-grid-system";
import Select from "react-select";

/*Components*/
import Header from "../../components/Header";
import TopBar from "../../components/TopBar";
import { Button, Form } from "react-bootstrap";
import Loader from "../../components/Loader";

import Is from "../../components/Is";
class EditUser extends Component {
  state = {
    id: this.props.users.currentUser.id || "",
    name: this.props.users.currentUser.name || "",
    email: this.props.users.currentUser.email || "",
    password: "",
    roles: [],
    projects: [],
    selectRoles: [],
    selectClients: [],
    selectedRoles: [],
    selectedClients: []
  };

  async componentDidMount() {
    const { clearError, showLoader, hideLoader, getUser } = this.props;
    const { id } = this.props.match.params;
    this.setState({ id: id });
    getUser(id);
    clearError();
    showLoader();

    /* Busca todas roles */
    const response = await api.get("roles");
    this.setState({ selectRoles: response.data });
    /* Busca todos clientes ativos */
    const responseClients = await api.get("list-projects");
    this.setState({ selectClients: responseClients.data });

    /* Busca todas roles do usuario */
    const responseRoles = await api.get(`list-roles/${id}`);
    this.setState({
      selectedRoles: responseRoles.data,
      roles: responseRoles.data.map(element => element.id)
    });
    /* Busca todos clientes ativos */
    const responseClientsUser = await api.get(`list-projects/${id}`);
    this.setState({
      selectedClients: responseClientsUser.data,
      projects: responseClientsUser.data.map(element => element.id)
    });
    hideLoader();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.users.currentUser.id !== this.props.users.currentUser.id) {
      this.setState({
        id: this.props.users.currentUser.id || "",
        name: this.props.users.currentUser.name || "",
        email: this.props.users.currentUser.email || "",
        password: ""
      });
    }
  }

  handleRolesChange = e => {
    if (!e) {
      this.setState({
        roles: null,
        selectedRoles: e
      });
    } else {
      this.setState({
        roles: e.map(element => element.id),
        selectedRoles: e
      });
    }
  };

  handleClientChange = e => {
    if (!e) {
      this.setState({ projects: null, selectedClients: e });
    } else {
      this.setState({
        projects: e.map(element => element.IdCliente),
        selectedClients: e
      });
    }
  };

  handleInputChange = e => {
    this.setState({ [e.target.name]: e.target.value });
  };

  handleSubmit = e => {
    e.preventDefault();
    const data = {
      id: this.state.id,
      name: this.state.name,
      email: this.state.email,
      password: this.state.password,
      roles: this.state.roles,
      projects: this.state.projects
    };
    const { updateUser } = this.props;
    updateUser(data);
  };

  render() {
    const { users, errors } = this.props;
    const {
      selectRoles,
      selectClients,
      selectedRoles,
      selectedClients
    } = this.state;

    const customStyles = {
      option: (provided, state) => ({
        ...provided,
        borderBottom: "1px dotted #c3c3c3",
        color: "#030303",
        padding: 5,
        textTransform: "uppercase",
        fontSize: "13px",
        fontWeight: "700"
      }),

      singleValue: (provided, state) => {
        const opacity = state.isDisabled ? 0.5 : 1;
        const transition = "opacity 300ms";
        return { ...provided, opacity, transition };
      }
    };
    return (
      <Container>
        {(!users.loaded || !users.userLoaded) && <Loader />}
        <Header />
        <Content>
          <TopBar title="Editar usuário" />
          <Panel>
            <div className="container_action">
              <h1>Editar Usuário</h1>
              <Link className="btn btn-success" to="/usuarios">
                <i className="fa fa fa-arrow-left" /> &nbsp; Voltar
              </Link>
            </div>
            <div className="panel-container">
              <Form>
                <Row>
                  <Form.Group
                    as={Col}
                    xs={12}
                    md={4}
                    lg={4}
                    controlId="formGridEmail"
                  >
                    <Form.Label>Nome</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Informe o nome do usuário"
                      name="name"
                      onChange={this.handleInputChange}
                      value={this.state.name}
                    />
                    {errors.data.name && (
                      <span className="inputError">{errors.data.name}</span>
                    )}
                  </Form.Group>
                  <Form.Group
                    as={Col}
                    xs={12}
                    md={4}
                    lg={4}
                    controlId="formGridEmail"
                  >
                    <Form.Label>Email</Form.Label>
                    <Form.Control
                      type="email"
                      placeholder="Informe o email do usuário"
                      name="email"
                      onChange={this.handleInputChange}
                      value={this.state.email}
                    />
                    {errors.data.email && (
                      <span className="inputError">{errors.data.email}</span>
                    )}
                  </Form.Group>

                  <Form.Group
                    as={Col}
                    xs={12}
                    md={4}
                    lg={4}
                    controlId="formGridPassword"
                  >
                    <Form.Label>Senha</Form.Label>
                    <Form.Control
                      type="password"
                      placeholder="Alterar senha do usuário"
                      name="password"
                      onChange={this.handleInputChange}
                    />
                    {errors.data.password && (
                      <span className="inputError">{errors.data.password}</span>
                    )}
                  </Form.Group>
                </Row>

                <Is checkRole={"administrator"}>
                  <Row>
                    <Form.Group as={Col} controlId="formGridPassword">
                      <Form.Label>Grupo de Usuário</Form.Label>
                      <Select
                        isMulti
                        styles={customStyles}
                        options={selectRoles}
                        getOptionValue={role => role.id}
                        getOptionLabel={role => role.name}
                        placeholder={"Selecione o grupo"}
                        value={selectedRoles}
                        onChange={value => this.handleRolesChange(value)}
                      />
                    </Form.Group>
                  </Row>

                  <Row>
                    <Form.Group as={Col} controlId="formGridPassword">
                      <Form.Label>Projetos relacionados</Form.Label>
                      <Select
                        isMulti
                        styles={customStyles}
                        options={selectClients}
                        getOptionValue={client => client.IdCliente}
                        getOptionLabel={client => client.Nome}
                        onChange={value => this.handleClientChange(value)}
                        placeholder={"Selecione os clientes"}
                        value={selectedClients}
                      />
                    </Form.Group>
                  </Row>
                </Is>
                <Button
                  variant="primary"
                  type="submit"
                  onClick={this.handleSubmit}
                >
                  Enviar
                </Button>
              </Form>
            </div>
          </Panel>
        </Content>
      </Container>
    );
  }
}

const mapStateToProps = state => ({
  users: state.users,
  projects: state.project,
  errors: state.errors
});

const mapDispatchToProps = dispatch =>
  bindActionCreators({ ...UsersActions, ...ErrorsActions }, dispatch);
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EditUser);
